import React, {Fragment, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectProjectById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextTools, useContextToolsByProjectId} from '../../../Hooks/ToolHooks';
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import { CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK } from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import TileList from '../../Common/TileList';
import PageContainer from '../../Layout/Page/PageContainer';
import Sidebar from './Sidebar';
import EventTileDetail from './EventTileDetail';
import CircledIcon from "../../CompositeIcons/CircledIcon";
import NotesModal from "../../Common/NotesModal";
import {UPDATE_PROJECT_VALUE} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import PageHeader from "../../Layout/Page/PageHeader";
import {sortSampleEvents} from "../../../Utils/SortUtils";
import {
    selectImaginarySampleEventPhasesByProject,
    selectSampleEventProceduresByProject,
} from "../../../Redux/Selectors/hierarchySelectors";
import {selectSampleEventsWithProgressByProjectId} from "../../../Redux/Selectors/sampleEventProgressSelectors";

const ProjectDetailsPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK]);

    const dispatch = useDispatch();
    const params = useParams();
    
    const dropdownRef = useRef(null);

    const project = useSelector(state => selectProjectById(state, params.projectId));
    const sampleEvents = useSelector(state => selectSampleEventsWithProgressByProjectId(state, project?.projectId));
    
    useBreadcrumbs(project.name);
    useContextToolsByProjectId(project.projectId);
    
    const allPhases = useSelector(state => selectImaginarySampleEventPhasesByProject(state, project));
    const allProcedures = useSelector(state => selectSampleEventProceduresByProject(state, project));

    // const [allPhases, setAllPhases] = useState([]);
    const [phaseFilter, setPhaseFilter] = useState(null);
    const [procedureFilter, setProcedureFilter] = useState(null);
    const [showNotes, setShowNotes] = useState(false);
    
    const filteredPhases = allPhases.filter(phase => phaseFilter === null || String(phase.sampleEventPhaseId) === String(phaseFilter));

    const filteredSampleEvents = sampleEvents.filter(event => {
        if (procedureFilter && String(event.procedureId) !== String(procedureFilter)) {
            return false;
        }
        if (phaseFilter && String(event.sampleEventPhaseId) !== String(phaseFilter)) {
            return false;
        }
        return true;
    })
    
    const sampleEventTiles = filteredSampleEvents
        .map(event => ({
            ...event,
            title: <Fragment>
                <div>{event.formattedStartDate}</div>
                <div>{event.procedureName}</div>
            </Fragment>,
        }));
    
    const sortedSampleEventTiles = sortSampleEvents(sampleEventTiles);
    
    const sampleEventTilesByPhase = Object.fromEntries(
        filteredPhases.map( phase => [
            phase.hierarchyId,
            sortedSampleEventTiles.filter(
                sampleEventTile => String(sampleEventTile.parentHierarchyId) === String(phase.hierarchyId)
            )
        ]
    ));

    const hasFilters = () => {
        return !!procedureFilter || !!phaseFilter;
    };

    const renderFilter = () => {
        return (
            <Dropdown className="filter pr-3">
                <Dropdown.Toggle
                    variant="outline-secondary"
                    id="project-filter"
                    className={hasFilters() && 'active'}
                >
                    <FontAwesomeIcon icon={['fal', 'filter']} />
                    Filter
                </Dropdown.Toggle>

                <Dropdown.Menu ref={dropdownRef}>
                    {
                        hasFilters() &&
                        <Fragment>
                            <Dropdown.Item onClick={() => {
                                setProcedureFilter(null);
                                setPhaseFilter(null);
                            }}>
                                <FontAwesomeIcon icon={['fal', 'times']} />
                                Clear Filters
                            </Dropdown.Item>
                            <Dropdown.Divider />
                        </Fragment>
                    }
                    <Dropdown.Header>Procedures</Dropdown.Header>
                    {
                        allProcedures.map(procedure => (
                            <Dropdown.Item
                                key={procedure.procedureId}
                                className={procedure.procedureId === procedureFilter && 'active'}
                                onClick={() => setProcedureFilter(procedure.procedureId)}
                            >
                                {procedure.name}
                            </Dropdown.Item>
                        ))
                    }
                    <Dropdown.Divider />
                    <Dropdown.Header>Phases</Dropdown.Header>
                    {
                        allPhases.map(phase => (
                            <Dropdown.Item
                                key={phase.sampleEventPhaseId}
                                className={phase.sampleEventPhaseId === phaseFilter && 'active'}
                                onClick={() => {
                                    setPhaseFilter(phase.sampleEventPhaseId);
                                    dropdownRef.current.scrollTop = 0;
                                }}
                            >
                                {phase.name}
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    };

    const getNotes = () => {
        return [
            {
                label: 'Landowner Notification Required',
                value: project.landownerNotificationRequired,
                save: (value) => dispatch({
                    type: UPDATE_PROJECT_VALUE,
                    projectId: project.projectId,
                    field: 'landownerNotificationRequired',
                    value,
                })
            },
            {
                label: 'Special Equipment Needs',
                value: project.specialEquipment,
                save: (value) => dispatch({
                    type: UPDATE_PROJECT_VALUE,
                    projectId: project.projectId,
                    field: 'specialEquipment',
                    value,
                })
            },
            {
                label: 'Project',
                value: project.notes,
                save: (value) => dispatch({
                    type: UPDATE_PROJECT_VALUE,
                    projectId: project.projectId,
                    field: 'notes',
                    value,
                })
            },
            {
                label: 'Directions',
                value: project.directions,
                save: (value) => dispatch({
                    type: UPDATE_PROJECT_VALUE,
                    projectId: project.projectId,
                    field: 'directions',
                    value,
                })
            },
            {
                label: 'Access Stipulations / Parking',
                value: project.accessStipulations,
                save: (value) => dispatch({
                    type: UPDATE_PROJECT_VALUE,
                    projectId: project.projectId,
                    field: 'accessStipulations',
                    value,
                })
            },
        ]
    };

    return (
        <Fragment>
            <NotesModal
                title="Project Notes"
                notes={getNotes()}
                show={showNotes}
                onHide={() => setShowNotes(false)}
            />
            <PageContainer className="project-details-page" sidebar={<Sidebar item={project} showNotes={() => setShowNotes(true)} />}>
                <PageHeader>
                    <Title
                        title="Project Details"
                        subtitle={`${project?.name}`}
                        action={renderFilter()}
                    />
                    <Link to={{
                        pathname: `/event/create`,
                        search: `?projectId=${project.projectId}`,
                    }}>
                        <Button className="w-100 mb-2" variant="secondary">
                            <FontAwesomeIcon icon={["fal", "plus"]} />
                            Add Monitoring Event
                        </Button>
                    </Link>
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col>
                            {
                                filteredPhases.map(phase => {
                                    if (!sampleEventTilesByPhase[phase.hierarchyId].length) {
                                        return null;
                                    }

                                    return (
                                        <Fragment key={phase.sampleEventPhaseId}>
                                            <h2>{phase.name}</h2>
                                            <TileList
                                                className="tile-list-project-details"
                                                data={sampleEventTilesByPhase[phase.hierarchyId]}
                                                tileDetailComponent={EventTileDetail}
                                                primaryKey="sampleEventId"
                                                actions={(item) => (
                                                    <Fragment>
                                                        <Link to={`/event/${item.sampleEventId}/${item.procedureCode}`}>
                                                            <CircledIcon
                                                                icon={item.procedureIcon || ['fal', 'chevron-circle-right']}
                                                                circleSize="12"
                                                                iconTransform="shrink-2"
                                                            />
                                                        </Link>
                                                        <Link to={{
                                                            pathname: `/event/${item.sampleEventId}/edit`,
                                                        }}>
                                                            <CircledIcon
                                                                icon={['fal', 'pen']}
                                                                circleSize="12"
                                                                iconTransform="shrink-4"
                                                            />
                                                        </Link>
                                                        <Link to={{
                                                            pathname: `/event/${item.sampleEventId}/review`,
                                                        }}>
                                                            <CircledIcon
                                                                icon={[item.isComplete ? 'fas' : 'fal', 'check-circle']}
                                                                circleSize="12"
                                                                iconTransform="shrink-2"
                                                            />
                                                        </Link>
                                                    </Fragment>
                                                )}
                                                noIcon
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </PageContent>
            </PageContainer>
        </Fragment>
    );
};

export default ProjectDetailsPage;
