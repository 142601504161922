import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {Button, Col, Row} from 'react-bootstrap';
import {useContextTools, useContextToolsByProjectId} from '../../../Hooks/ToolHooks';
import {CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_PHOTO} from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import HydrozoneSpeciesList from "./HydrozoneSpeciesList";
import LengthCategoryUtils from "../../../Utils/LengthCategoryUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageFooter from "../../Layout/Page/PageFooter";
import SpeciesPickerModal from "../../Common/SpeciesPickerModal";
import NotesModal from "../../Common/NotesModal";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import {getQuantToolName, useHydrozoneName} from '../../../Hooks/LabelHooks';
import FooterNav from "../../Layout/Page/FooterNav";
import PageHeader from "../../Layout/Page/PageHeader";
import {
    selectTransect,
    selectTransectHydrozone,
    selectTransectHydrozoneFilteredPlotSpecies,
    selectTransectHydrozonePlot,
    selectTransectHydrozonePlotIndex,
} from "../../../Redux/Selectors/transectSelectors";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {UPSERT_SAMPLE_EVENT, UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import KeyboardComponent from "./KeyboardComponent";
import {
    OPEN_SPECIES_PICKER,
    SET_CURRENT_DATA,
    SET_SELECTED_SPECIES
} from "../../../Redux/Actions/Types/transectActionTypes";
import DuplicateSpeciesModal from "./DuplicateSpeciesModal";
import {updateNoPlants} from "../../../Redux/Actions/transectActions";
import {useBreadcrumbs, useHydrozoneContextTabs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {selectAllSpecies} from "../../../Redux/Selectors/speciesSelectors";
import {NoDataCheckBox} from "./NoDataCheckBox";
import {categoriesByType} from "../../../Constants/lengthCategories";

const HydrozoneSpeciesPage = (props) => {
    const { type, plotTypeId, hydrozoneTypeId, transectId } = useParams();
    const dispatch = useDispatch();
    const getHydrozoneName = useHydrozoneName();

    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);
    useQuantVegNav(transectId);

    const transect = useSelector(state => selectTransect(state, transectId));
    const sampleEvent = useSelector(state => selectSampleEventById(state, transect.sampleEventId));
    const species = useSelector(state => selectAllSpecies(state, transect.projectId));

    useBreadcrumbs(`Plot ${plotTypeId}`, transect.projectId, transect.sampleEventId, transect.transectId);
    useHydrozoneContextTabs(transectId, plotTypeId, hydrozoneTypeId);
    useContextToolsByProjectId(transect.projectId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    const [showNotes, setShowNotes] = useState(false);

    const hydrozone = useSelector(state => selectTransectHydrozone(state, transectId, hydrozoneTypeId));
    const hydrozonePlot = useSelector(state => selectTransectHydrozonePlot(state, transectId, hydrozoneTypeId, plotTypeId));
    const [hydrozonePlotIndexForThisPlotType, hydrozonePlotCountForThisPlotType] = useSelector(state => selectTransectHydrozonePlotIndex(state, transectId, plotTypeId, hydrozoneTypeId));
    
    const hydrozonePlotSpecies = useSelector(state => selectTransectHydrozoneFilteredPlotSpecies(state, transectId, plotTypeId, type, hydrozoneTypeId)); 
    
    const categories = categoriesByType?.[type] ?? [];
    const hydrozoneId = hydrozone?.hydrozoneId;
    const hydrozoneName = getHydrozoneName(hydrozoneTypeId);
    const hydrozoneTypeClass = `type-${hydrozoneName.toLowerCase()}`;

    useEffect(() => {
        dispatch({
            type: SET_CURRENT_DATA,
            currentTransect: transectId,
            currentHydrozone: hydrozoneId,
            currentPlot: plotTypeId,
            currentSpeciesPage: type,
        });
    }, [transectId, hydrozoneId, plotTypeId, type]);

    const noPlantsValue = LengthCategoryUtils.GetNoPlantsValue(hydrozone, type, plotTypeId);

    return (
        <Fragment>
            <SpeciesPickerModal
                projectId={transect.projectId}
                listType={LengthCategoryUtils.GetSpeciesListTypeByType(type)}
                type={type}
                hydrozoneSpecies={hydrozonePlotSpecies}
            />
            <DuplicateSpeciesModal />
            <NotesModal
                title="Notes"
                notes={[
                    {
                        label: 'Transect Notes',
                        value: transect.notes,
                        save: (value) => dispatch({
                            type: UPSERT_TRANSECT,
                            transect: {
                                ...transect,
                                notes: value,
                            }
                        })
                    },
                    {
                        label: 'Site Notes',
                        value: sampleEvent.siteNotes,
                        save: (value) => dispatch({
                            type: UPSERT_SAMPLE_EVENT,
                            sampleEvent: {
                                ...sampleEvent,
                                siteNotes: value,
                            }
                        })
                    }
                ]}
                show={showNotes}
                onHide={() => setShowNotes(false)}
                readOnly={finalized}
            />
            <PageContainer className="hydrozone-species-page">
                <PageHeader>
                    <KeyboardComponent type={type} readOnly={finalized} />
                    <Title
                        title={
                            <Fragment>
                                <span className="font-weight-bold">Plot {plotTypeId}: </span>
                                <span className="font-weight-normal">{getQuantToolName(type)}</span>
                            </Fragment>
                        }
                        subtitle={
                            <Fragment>
                                <span className={`subtitle-pill ${hydrozoneTypeClass}`}>
                                    {hydrozoneName}: {hydrozonePlot?.start} Ft - {hydrozonePlot?.end} Ft
                                </span>
                                <span className="subtitle-desc">({hydrozonePlotIndexForThisPlotType} of {hydrozonePlotCountForThisPlotType})</span>
                            </Fragment>
                        }
                        action={
                            <NoDataCheckBox
                                type={type}
                                value={noPlantsValue}
                                onChange={() => !finalized && dispatch(updateNoPlants(!noPlantsValue))}
                            />
                        }
                        readOnly={finalized}
                    />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={12}>
                            <HydrozoneSpeciesList
                                species={species}
                                hydrozoneSpecies={!noPlantsValue ? hydrozonePlotSpecies : []}
                                categories={categories}
                                type={type}
                                plotLength={hydrozonePlot.length}
                                disabled={finalized || noPlantsValue}
                                noPlants={noPlantsValue}
                            />
                            {
                                !finalized &&
                                    <Button
                                        className="w-100"
                                        variant="secondary"
                                        onClick={() => {
                                            dispatch({type: SET_SELECTED_SPECIES, speciesId: null});
                                            dispatch({type: OPEN_SPECIES_PICKER});
                                        }}
                                        disabled={noPlantsValue}
                                    >
                                        <FontAwesomeIcon icon={["fal", "plus"]} />
                                        Add Species
                                    </Button>
                            }
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    <FooterNav>
                        <Button variant="primary" onClick={() => setShowNotes(true)}>
                            <FontAwesomeIcon icon={['fal', 'sticky-note']} />
                            View Notes
                        </Button>
                    </FooterNav>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default HydrozoneSpeciesPage;
