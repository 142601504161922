import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, FormLabel, FormControl, FormGroup, Row, FormCheck} from "react-bootstrap";

import {sendCode, verifyCode} from "../../Redux/Actions/loginActions";
import {UPDATE_VERIFICATION_FIELD_VALUE, VERIFICATION_CANCEL} from "../../Redux/Actions/Types/loginActionTypes";
import {SB_ENTER_KEY_CODE} from "../../Constants";

const VerificationCodeForm = props => {
    const {fields, errors, timer} = useSelector(state => state.loginState);
    const user = useSelector(state => state.userState.user);
    const dispatch = useDispatch();

    const updateField = (field, value) => {
        dispatch({type: UPDATE_VERIFICATION_FIELD_VALUE, field, value})
    };

    return (
        <Fragment>
            <Row key={1} className="top10">
                <Col xs={12}>
                    <p>A 6-digit code has been sent to the phone number {fields.maskedNumber}. If this is not your phone number or you can't access this phone please contact your admin.</p>
                </Col>
            </Row>
            <Row key={2} className="top10 verification-code-field">
                <Col md={3}>
                    <span className="login-form-label">6-digit Code</span>
                </Col>
                <Col md={9}>
                    <FormGroup validationstate={errors.verify || errors.sendCode ? 'error' : null}>
                        <FormControl
                            type="text"
                            name="code"
                            autoFocus
                            inputmode="decimal"
                            pattern="[0-9]*"
                            autocomplete="one-time-code"
                            onChange={(e) => updateField('code', e.target.value)}
                            onKeyDown={(x) => KeyboardUtils.handleKeyPress(SB_ENTER_KEY_CODE, x, () => dispatch(verifyCode(props.OnSuccess)))}
                            value={fields.code}
                        />
                        {errors.verify && <FormLabel>{errors.verify}</FormLabel>}
                        {errors.sendCode && <FormLabel>{errors.sendCode}</FormLabel>}
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <Button onClick={() => dispatch(sendCode())}
                            className="monitoring-resend-btn"
                            disabled={timer > 0}
                    >
                        Resend {timer > 0 ? `(${timer}s)` : 'Code'}
                    </Button>
                </Col>
            </Row>
            {
                Boolean(user?.rememberDays) &&
                <Row key={3} className="top10 remember-me-form">
                    <Col md={3} />
                    <Col md={9}>
                        <FormGroup>
                            <FormCheck
                                id="remember-me-checkbox"
                                type="checkbox"
                                label={`Remember this device for ${user.rememberDays} days`}
                                onChange={(x) => {
                                    if(fields.rememberMe) {
                                        updateField('rememberMe', 0);
                                    } else {
                                        updateField('rememberMe', 1);
                                    }
                                }}
                                checked={fields.rememberMe}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            }
            {
                !props.HideButtons &&
                <Row key={4} className="top10 login-btn">
                    <Col md={3} />
                    <Col md={9}>
                        <Button onClick={() => dispatch(verifyCode(props.OnSuccess))}
                                variant="primary"
                                disabled={!fields.code}
                                className="submit-btn"
                        >
                            Submit
                        </Button>
                        <Button onClick={() => dispatch(sendCode())}
                                className="resend-btn"
                                disabled={timer > 0}
                        >
                            Resend {timer > 0 ? `(${timer}s)` : 'Code'}
                        </Button>
                    </Col>
                    <Col md={12} className="cancel-btn">
                        <a onClick={() => dispatch({type: VERIFICATION_CANCEL})}>Cancel</a>
                    </Col>
                </Row>
            }
        </Fragment>
    )
};

export default VerificationCodeForm;
