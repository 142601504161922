import React from 'react';
import {useDispatch} from "react-redux";
import {updateSurveyValue} from "../../../Redux/Actions/surveyActions";
import {RadioInput} from "./RadioInput";
import {useStandardizedOptions} from "../../../Hooks/SharedSurveyHooks";

const SurveyRadioInput = (props) => {
    const dispatch = useDispatch();
    
    const {
        answerId,
        children,
        code,
        id, // probably should get rid of this?
        options,
        readOnly,
        value: selectedValue
    } = props;
    
    const optionValues = useStandardizedOptions(options);
    
    const onChange = (value) => {
        if(!readOnly) {
            dispatch(updateSurveyValue(answerId, code, value));
        }
    }
    
    return <RadioInput
        id={id} // TODO: get rid of this
        code={code}
        options={optionValues}
        value={selectedValue}
        readOnly={readOnly}
        inline
        onChange={onChange}
    >
        {children}
    </RadioInput>;
};

export default SurveyRadioInput;
