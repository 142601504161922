import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {selectSampleEventById} from "../../Redux/Selectors/nodeSelectors";

import CircledIcon from "../CompositeIcons/CircledIcon";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {photoPointWasActiveDuringSampleEvent, usePreviousPhoto} from "../../Hooks/PhotoHooks";
import {selectVisiblePhotos} from "../../Redux/Selectors/photoSelectors";

const PhotoPointRow = ({photoPoint, eventId, cameraPoint, showPoint, transectPhotoPoint, readOnly}) => {
    const getPreviousPhoto = usePreviousPhoto();

    const photos = useSelector(state => selectVisiblePhotos(state));

    const event = useSelector(state => selectSampleEventById(state, eventId));
    const currentPhoto = photos.find(photo => photo.sampleEventId === event.sampleEventId && photo.photoPointId === photoPoint.photoPointId);
    const previousPhoto = getPreviousPhoto(event.startDate, photoPoint.photoPointId);
    
    const activeDuringThisEvent = photoPointWasActiveDuringSampleEvent(photoPoint, event);

    const renderCaption = (photo) => {
        let caption = photoPoint.activity;

        if (transectPhotoPoint && photo?.percentSolarRadiation ) {
            caption = `Solar Reading: ${photo?.percentSolarRadiation}% Sun, ${photo?.percentShade}% Shade`;
        }

        return (
            <div className={`caption ${!activeDuringThisEvent ? 'discontinued' : ''}`}>
                <div className="caption-group">
                    {
                        activeDuringThisEvent ?
                        <span>{photo?.sampleEventName}</span> :
                        <span>Point inactive during this event.</span>
                    }
                    {
                        photo?.syncConflict ?
                        <span className="ml-3">
                            <FontAwesomeIcon icon={['fas', 'exclamation-triangle']}/>
                            Conflict
                        </span> : null
                    }
                    {
                        String(photo?.notes ?? '').trim() &&
                        <span className="ml-3">
                            <FontAwesomeIcon icon={['fal', 'sticky-note']} />
                            Notes Available
                        </span>
                    }
                </div>
                <div className="caption-group">
                    <span>{caption}</span>
                </div>
            </div>
        )
    };

    return (
        <div className="photo-point-row">
            <div className="photo-point-title">
                {
                    transectPhotoPoint ?
                        <div>
                            <span>
                                {photoPoint.photoDirection} Photo
                            </span>
                        </div> :
                        <Fragment>
                            <div>
                                <span>
                                    Photo Point: <span className="font-weight-bold">{photoPoint.name}</span>
                                </span>
                                <span>
                                    Direction/Azimuth: <span className="font-weight-bold">{photoPoint.photoDirection} {photoPoint.compassBearing}&#176;</span>
                                </span>
                            </div>
                            <CircledIcon
                                icon={['fal', 'pen']}
                                circleSize="12"
                                iconTransform="shrink-2"
                                onClick={() => showPoint(photoPoint)}
                            />
                        </Fragment>
                }

            </div>
            <Row xs={12} className="photo-point-content">
                <Col xs={6}>
                    <div className="photo">
                        {
                            previousPhoto ?
                                <Link to={{
                                    pathname: `/photo/${previousPhoto.photoId}`,
                                    search: `?readOnly=1`,
                                }}>
                                    <img src={previousPhoto?.thumbnailSrc ?? previousPhoto?.src} />
                                    {renderCaption(previousPhoto)}
                                </Link> :
                                <div className="no-image">
                                    <FontAwesomeIcon icon={['fal', 'file-image']} />
                                    No Previous Photo
                                </div>
                        }
                    </div>
                </Col>
                <Col xs={6}>
                    <div className={`photo ${currentPhoto?.syncConflict ? 'conflict' : ''}`}>
                        {
                            currentPhoto ?
                                <Link to={`/photo/${currentPhoto.photoId}`}>
                                    <img src={currentPhoto?.thumbnailSrc ?? currentPhoto?.src} />
                                    {renderCaption(currentPhoto, true)}
                                </Link> :
                            (readOnly || !activeDuringThisEvent) ?
                                <div className="no-image">
                                    <FontAwesomeIcon icon={['fal', 'file-image']} />
                                    No Photo
                                    {renderCaption(currentPhoto, true)}
                                </div> :
                                <div className="no-image">
                                    <FontAwesomeIcon icon={['fal', 'camera']} />
                                    <Link to={{
                                        pathname: '/photo/tool',
                                        search: `?sampleEventId=${event.sampleEventId}&photoPointId=${photoPoint.photoPointId}` +
                                        (previousPhoto ? `&previousPhotoId=${previousPhoto.photoId}` : '')
                                    }}>
                                        <Button variant="action">
                                            Take Photo
                                        </Button>
                                    </Link>
                                    {renderCaption(currentPhoto, true)}
                                </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default PhotoPointRow;
