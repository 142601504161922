import React from 'react';
import { updateSurveyValues} from "../../../Redux/Actions/surveyActions";
import {useDispatch} from "react-redux";
import CheckboxList from "../CheckboxList";
import {useStandardizedOptions} from "../../../Hooks/SharedSurveyHooks";

const SurveyCheckboxListInput = props => {
    const dispatch = useDispatch();

    const {
        answers,
        answerId,
        children,
        options,
        readOnly,
        primaryKey = 'code',
        labelKey = 'label'
    } = props;

    
    const optionItems = useStandardizedOptions(options, primaryKey, labelKey)
    const selectedValues = optionItems.filter(option => !!(answers?.[option.code]) );

    const onChange = async (newValues, lastValue) => {
        const codeValuePairs = Object.fromEntries(
            optionItems.map( (option) => [
                option.code,
                newValues?.some(selectedOption => option.code === selectedOption.code)
            ])
        );
        await dispatch(updateSurveyValues(answerId, codeValuePairs));
    };

    return (
        <div className="survey-checkbox-list-input">
            <CheckboxList
                data={optionItems}
                primaryKey={primaryKey}
                checked={selectedValues}
                onChange={onChange}
                readOnly={readOnly}
                clearingOptions={['None', 'Other']}
            />
            {
                children
            }
        </div>
    )
};

export default SurveyCheckboxListInput;
