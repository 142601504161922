/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import { useEffect } from 'react';

const useDeviceOrientationLock = (orientation) => {
	useEffect(() => {
		const lockOrientation = async () => {
			try {
				await screen.orientation?.lock?.(orientation);
			} catch (err) {
				console.log('Failed to lock orientation:', err);
			}
		}
		
		void lockOrientation();
		
		return () => {
			screen.orientation?.unlock?.();
		}
	}, [orientation]);
};

export default useDeviceOrientationLock;
