import React, {Fragment, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffectPromise, useUpdateEffect} from "../../Hooks/EffectHooks";
import {SBM_VERSION} from "../../Constants";
import {getMinifiedStackTrace} from "../../Hooks/ErrorHooks";

const ErrorComponent = props => {
    const history = useHistory();

    useUpdateEffect(() => {
        location.reload();
    }, [location.pathname]);
    
    const {
        error,
        children = <span>Something went wrong :(</span>,
        suggestion = <div>If you continue to have problems, backup your data and then try reinstalling the app.</div>
    } = props;
    
    const [stack, setStack] = useState(undefined);
    const stackTraceOptions = {
        // "filter": (line) => { return !(line?.includes('vendor.js')); }
    };
    
    useEffectPromise(
        async () => (
            error?.stack ? await getMinifiedStackTrace(error?.stack, stackTraceOptions) : null
        ),
        (stack) => {
            setStack(stack);
        },
        [error?.stack],
        (error?.stack)
    )
    
    console.log(error, stack);

    return (
        <div className="error-container">
            {
                (stack !== undefined) ?
                    <Fragment>
                        <div className="error-text">{children}</div>
                        <div className="error-message">{
                            stack !== null ?
                                <ul>
                                    <li className="main-error" key={'error'}>{error?.message}</li>
                                    {
                                        stack?.map?.((line, index) => <li key={index}>{line}</li>)
                                    }
                                </ul> :
                                error?.stack
                        }</div>
                        <div>{suggestion}</div>
                        <div>Version: {SBM_VERSION}</div>
                        <div className="actions">
                            <Link to="/dashboard">
                                <Button variant="complete">Dashboard</Button>
                            </Link>
                            <Button variant="action" onClick={() => {
                                history.goBack();
                            }}>
                                <FontAwesomeIcon icon={['fas', 'redo']} transform="flip-h" />
                                Go Back
                            </Button>
                        </div>
                    </Fragment> : null
            }
        </div>
    )
};

export default ErrorComponent;
