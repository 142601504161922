/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React, {useState} from "react";
import CircledIcon from "../CompositeIcons/CircledIcon";
import GeoPositionUtils from "../../Utils/GeoPositionUtils";

const GeoLocationButton = (props) => {
	
	const { 
		icon= ['fal', 'location-arrow'],
		setLatitude = (lat) => {},
		setLongitude = (long) => {},
		setAccuracy = (accuracy) => {},
		className = "btn-location",
		disabled = false,
	} = props
	
	const [loadingLocation, setLoadingLocation] = useState(false);
	
	const gotLocation = (position) => {
		setLatitude(position.coords.latitude.toFixed(5));
		setLongitude(position.coords.longitude.toFixed(5));
		setAccuracy(position.coords.accuracy);
		setLoadingLocation(false);
	}
	
	const failure = (error) => {
		alert(GeoPositionUtils.getDefaultErrorMessage(error));
		setLoadingLocation(false);
	}
	
	const autoPopulateLocation = () => {
		if (loadingLocation) return;
		
		setLoadingLocation(true);
		GeoPositionUtils.getCurrentPosition(gotLocation, failure);
	};
	
	return <CircledIcon
		icon={icon}
		className={className}
		circleSize="12"
		iconTransform="shrink-2"
		onClick={() => !disabled ? autoPopulateLocation() : null}
		loading={loadingLocation}
		disabled={disabled}
	/>
}

export default GeoLocationButton;
