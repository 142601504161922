/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {FormCheck} from "react-bootstrap";
import {hasNoPlantsLabels} from "../../../Constants/lengthCategories";
import React from "react";

export function NoDataCheckBox(props) {
	const {
		type,
		label = null,
		value,
		onChange = () => {
		},
	} = props;
	return <div className="no-plants-field">
		<FormCheck
			id="no-plants-checkbox"
			label={label ?? "No " + hasNoPlantsLabels[type] + " to measure in this hydrozone"}
			type="checkbox"
			value={value}
			checked={value}
			onChange={onChange}
		/>
	</div>;
}
