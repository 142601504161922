/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

export const useProgressColor = () => {
    return (progress = null, isComplete = null) => {
        if (progress === 100 && isComplete) {
            return 'success';
        }

        if (progress > 0) {
            return 'primary';
        }

        return '';
    }
};
