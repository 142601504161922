import {
    SET_DOWNLOAD_PROGRESS,
    SET_STEP,
    SET_PROCESS,
    SET_UPLOAD_PROGRESS,
    RESET_PROGRESS,
    INCREMENT_PROGRESS,
    DECREASE_TOTAL_PROGRESS,
    ADD_SYNC_ERRORS,
    ADD_FAILED_PHOTO_UPLOAD,
    ADD_FAILED_PHOTO_DELETE,
    ABORT_SYNC,
    SHOW_SYNC_DIALOG,
    HIDE_SYNC_DIALOG,
    SET_CHECKING_FOR_DATA,
    SET_AUTO_UPLOAD_ID,
    UNSET_AUTO_UPLOAD_ID,
    ENABLE_NO_SLEEP,
    DISABLE_NO_SLEEP,
    SET_PHOTO_QUALITY
} from "../Actions/Types/syncStatusTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    show: false,
    checkingForData: false,
    step: null,
    process: null,
    uploadProgress: {
        completedProjects: 0,
        totalProjects: 0,
        completedEvents: 0,
        totalEvents: 0,
        completedPhotos: 0,
        totalPhotos: 0,
    },
    downloadProgress: {
        completedNodes: 0,
        totalNodes: 0,
        downloadedPhotos: 0,
        completedPhotos: 0,
        completedPhotosSize: 0,
        totalPhotos: 0,
        totalPhotosSize: 0,
    },
    completedProgress: 0,
    totalProgress: 5,
    syncErrors: [],
    failedPhotoUploads: 0,
    failedPhotoDeletes: 0,
    photoQuality: 'medium',
    autoUploadId: null,
    abort: false,
};

const syncReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SYNC_DIALOG:
            return {
                ...state,
                show: true,
            };
        case HIDE_SYNC_DIALOG:
            return {
                ...state,
                show: false,
            };
        case ENABLE_NO_SLEEP:
            return {
                ...state,
                enableNoSleep: true,
            };
        case DISABLE_NO_SLEEP:
            return {
                ...state,
                enableNoSleep: false,
            };
        case SET_CHECKING_FOR_DATA:
            return {
                ...state,
                checkingForData: action.checkingForData
            };
        case SET_STEP:
            return {
                ...state,
                step: action.step,
            };
        case SET_PROCESS:
            return {
                ...state,
                process: action.process,
            };
        case SET_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: {
                    ...state.uploadProgress,
                    [action.field]: action.value
                },
            };
        case SET_DOWNLOAD_PROGRESS:
            return {
                ...state,
                downloadProgress: {
                    ...state.downloadProgress,
                    [action.field]: action.value
                },
            };
        case INCREMENT_PROGRESS:
            return {
                ...state,
                completedProgress: state.completedProgress + 1,
            };
        case DECREASE_TOTAL_PROGRESS:
            const decreaseBy = action.num ? action.num : 1;

            return {
                ...state,
                totalProgress: state.totalProgress - decreaseBy,
            };
        case ADD_SYNC_ERRORS:
            return {
                ...state,
                syncErrors: [...state.syncErrors, ...action.syncErrors],
            };
        case ADD_FAILED_PHOTO_UPLOAD:
            return {
                ...state,
                failedPhotoUploads: state.failedPhotoUploads + 1,
            };
        case ADD_FAILED_PHOTO_DELETE:
            return {
                ...state,
                failedPhotoDeletes: state.failedPhotoDeletes + 1,
            };
        case SET_PHOTO_QUALITY:
            return {
                ...state,
                photoQuality: action.photoQuality,
            };
        case SET_AUTO_UPLOAD_ID:
            return {
                ...state,
                autoUploadId: action.autoUploadId,
            };
        case UNSET_AUTO_UPLOAD_ID:
            return {
                ...state,
                autoUploadId: null,
            };
        case ABORT_SYNC:
            return {
                ...state,
                abort: true,
            };
        case USER_LOGOUT:
            return initialState;
        case RESET_PROGRESS:
            return {
                ...initialState,
                photoQuality: state.photoQuality,
                autoUploadId: state.autoUploadId
            };
        case RESTORE_BACKUP:
            return action.data.syncState;
        default:
            return state;
    }
};

export default syncReducer;
