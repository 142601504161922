import React from 'react';
import {useDispatch} from "react-redux";
import { Col } from 'react-bootstrap';
import CircledIcon from '../../CompositeIcons/CircledIcon';
import {SET_HIDE_DASHBOARD_SIDEBAR} from "../../../Redux/Actions/Types/appStatusTypes";

const CollapsibleSidebar = props => {
    const { hide = false } = props;
    const dispatch = useDispatch();
    return (
        <Col xs={3} className={`streambank-sidebar collapsible-sidebar ${props.className || ''} ${hide ? 'hide' : 'show'}`}>
            <CircledIcon
                icon={['fal', 'angle-right']}
                circleSize="10"
                iconTransform="grow-2 right-1"
                className="btn-collapse"
                onClick={() => dispatch({type: SET_HIDE_DASHBOARD_SIDEBAR, hideDashboardSidebar: !hide})}
            />
            <div className="content">
                {props.children}
            </div>
        </Col>
    )
};

export default CollapsibleSidebar;
