import React, {useEffect, useState} from 'react';
import { ModalBody, ModalFooter, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from '../../../Common/Modal';
import FormInput from "../../../Common/FormInput";
import DateUtils from "../../../../Utils/DateUtils";
import {useDispatch} from "react-redux";
import {hasInvalidField} from "../../../../Hooks/FormHooks";
import {deactivatePhotoPoint} from "../../../../Redux/Actions/photoActions";

const DeactivatePhotoPointModal = props => {
    const dispatch = useDispatch();

    const {
        suggestedDate = DateUtils.GetCurrentDate(),
        point,
        show,
        onHide = () => {},
    } = props;
    
    const [date, setDate] = useState(suggestedDate);
    const [notes, setNotes] = useState('');
    
    useEffect(() => {
        if (show) {
            setNotes('');
        }
    }, [show]);
    
    useEffect(() => {
        setDate(suggestedDate);
    }, [suggestedDate]);

    const disableSave = () => {
        const requiredFields = [date];
        const validatedFields = [];

        return hasInvalidField(requiredFields, validatedFields);
    };

    const deactivate = () => {
        dispatch(deactivatePhotoPoint(point, date, notes));
	    onHide();
    };

    return (
        <Modal
            title={`Discontinue Photo Point ${point?.name}`}
            size="md"
            show={show}
            className="deactivate-photo-point-modal"
            onHide={() => onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <FormInput
                            label="Discontinue Date"
                            type="date"
                            value={DateUtils.FormatDate(date, 'yyyy-MM-dd')}
                            onChange={value => setDate(DateUtils.FormatDate(value, 'yyyy-MM-dd'))}
                            required
                        />
                    </Col>
                    <Col xs={12}>
                        <FormInput
                            label="Discontinue Notes"
                            type="textarea"
                            value={notes}
                            onChange={value => setNotes(value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex flex-column">
                    <Button variant="danger" onClick={() => deactivate()} disabled={disableSave()}>
                        <FontAwesomeIcon icon={['fal', 'calendar-times']} />
                        Confirm Deactivation
                    </Button>
                    <Button variant="link" className="mt-2" onClick={() => onHide()}>
                        Cancel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default DeactivatePhotoPointModal;

