import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {Col, OverlayTrigger, Row, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import SurveyRadioInput from "./SurveyRadioInput";
import SurveySelectInput from "./SurveySelectInput";
import CompetitionComponent from "./CompetitionComponent";
import SurveyTextInput from "./SurveyTextInput";
import SurveyCheckboxListInput from "./SurveyCheckboxListInput";
import SurveySecondaryInput from "./SurveySecondaryInput";
import {updateSurveyValue, updateSurveyValues} from "../../../Redux/Actions/surveyActions";

const useVisibleSecondaryFields = (options, answers, answer) => {
    
    // some questions have {} or [] as the Question.options
    // which can be turned into an Array instead of an Object
    // and will then have a prototype member function called
    // values which is not what we want.
    const optionItems = options?.hasOwnProperty('values') ? options.values : [];
    
    const visibleSecondaryFields = useMemo(() => {
            return optionItems.reduce((carry, value) => {
                    if (value.value !== undefined && value.value === answer) {
                        return carry.concat(value?.secondaryFields ?? []);
                    } else if (value.type === "checkbox" && !!answers?.[value.code]) {
                        return carry.concat(value?.secondaryFields ?? []);
                    }
                    return carry;
                }, []);
        },
    [JSON.stringify(answers), JSON.stringify(optionItems), answer]
    );
    
    return visibleSecondaryFields;
}

const updateCheckboxes = (answerId, values) => {
    for (const [code, isChecked] of Object.entries(values)) {
        dispatch(updateSurveyValue(answerId, code, isChecked));
    }
}

const SurveyField = props => {
    const dispatch = useDispatch();
    
    const {answers, question} = props;
    const {name, order, options, code, helpText, surveyQuestionId, surveyQuestionDataTypeId, isRequired} = question;
    
    const answerId = answers?.qualitativeVegetationMonitoringId;
    const answer = answers ? answers[code] : null;
    
    const secondaryFields = useVisibleSecondaryFields(options, answers, answer);

    const secondaryFieldComponents = secondaryFields.map((secondaryField, i) => {

        let data = null;
        if(secondaryField.type === 'monitoring-repeater') {
            data = props?.answers?.QualitativeVegetationMonitoringRepeaters ?? [];
        } else if(secondaryField.type === 'monitoring-recommendations') {
            data = props?.answers?.QualitativeVegetationMonitoringRecommendations ?? [];
        }

        return <SurveySecondaryInput
            fieldDefinition={secondaryField}
            value={answers?.[secondaryField.code] ?? ''}
            key={i}

            /* used by primary fields only */
            onChange={(value) => dispatch(updateSurveyValue(answerId, secondaryField.code, value))}

            /* used by species fields */
            sampleEventId={answers.sampleEventId}

            /* used by repeater and recommendation fields */
            data={data}
            answerId={answerId}
            readOnly={props.readOnly}
        />
    });

    const renderComponent = () => {
        const commonSurveyInputProps = {
            options: options,
            answerId: answerId,
            value: answer,
            children: secondaryFieldComponents
        };

        switch (surveyQuestionDataTypeId) {
            case 1:
                return <SurveyTextInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} readOnly={props.readOnly} />;
            case 13:
                return <SurveyRadioInput
                    id={surveyQuestionId}
                    code={code}
                    {...commonSurveyInputProps}
                    readOnly={props.readOnly} />;
            case 14:
                return <SurveySelectInput id={surveyQuestionId} code={code} {...commonSurveyInputProps} readOnly={props.readOnly} />;
            case 15:
                return <SurveyCheckboxListInput
                    id={surveyQuestionId}
                    code={code}
                    {...commonSurveyInputProps}
                    answers={answers}
                    readOnly={props.readOnly}
                    onChange={(values) => dispatch(updateSurveyValues(answerId, values))}
                />;
            case 17:
                switch (code) {
                    case 'competition':
                        return <CompetitionComponent id={surveyQuestionId} code={code} answers={answers} readOnly={props.readOnly} />
                }
        }
    };

    return (
        <div className="survey-field">
            <Row xs={12}>
                <Col xs={5}>
                    <div className={`survey-question ${(isRequired ?? true) ? 'required' : ''}`}>
                        {order}. {name}
                        {
                            helpText &&
                            <OverlayTrigger
                                trigger="click"
                                key="right"
                                placement="right"
                                overlay={
                                    <Popover>
                                        <Popover.Content>
                                            {helpText}
                                        </Popover.Content>
                                    </Popover>
                                }
                                rootClose
                            >
                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                            </OverlayTrigger>
                        }
                    </div>
                </Col>
                <Col xs={7}>
                    <div className="survey-answer">
                        {renderComponent()}
                    </div>
                </Col>
            </Row>
            <hr />
        </div>
    )
};

export default SurveyField;
