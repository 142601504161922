export const qualitativeVegetationMonitoringsSelector = state => state.offlineDataState.qualitativeVegetationMonitorings;

export const selectQualitativeVegetationMonitoringBySampleEventId = (state, sampleEventId) => {
	const qualitativeVegetationMonitorings = qualitativeVegetationMonitoringsSelector(state);
	return qualitativeVegetationMonitorings.find(
		qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.sampleEventId) === String(sampleEventId)
	);
}

export const selectQualitativeVegetationMonitoringById = (state, qualitativeVegetationMonitoringId) => {
	const qualitativeVegetationMonitorings = qualitativeVegetationMonitoringsSelector(state);
	return qualitativeVegetationMonitorings.find(
		qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.qualitativeVegetationMonitoringId) === String(qualitativeVegetationMonitoringId)
	);
}

export const selectQualitativeVegetationMonitoringByRepeaterId = (state, qualitativeVegetationMonitoringRepeaterId) => {
	const qualitativeVegetationMonitorings = qualitativeVegetationMonitoringsSelector(state);
	return qualitativeVegetationMonitorings.find(
		survey => (survey?.QualitativeVegetationMonitoringRepeaters ?? []).find(
			repeater => String(repeater.qualitativeVegetationMonitoringRepeaterId) === String(qualitativeVegetationMonitoringRepeaterId)
		)
	);
}

