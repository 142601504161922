import {useSelector} from "react-redux";
import DateUtils from "../Utils/DateUtils";
import {selectVisiblePhotos} from "../Redux/Selectors/photoSelectors";
import {useNextTempId} from "./TempIdHooks";
import {addPhotoContent} from "../Redux/Actions/photoActions";

export const usePreviousPhoto = () => {
    const photos = useSelector(state => selectVisiblePhotos(state));

    return (sampleEventStartDate, photoPointId) => {
        // photos that are not associated with a photoPoint (e.g. Qualitative Survey photos)
        // do not have a 'previous photo'
        if(photoPointId == null) {
            return null;
        }
        
        // Previous sample event sorted by sampleEventStartDate in descending order
        return photos.filter(photo => photo.photoPointId === photoPointId)
            .sort((a, b) => DateUtils.CompareDesc(a.sampleEventStartDate, b.sampleEventStartDate))
            .find(photo => DateUtils.IsBefore(photo.sampleEventStartDate, sampleEventStartDate));
    }
};

export const useNewPhotoPointHistory = () => {
    const {user} = useSelector(state => state.userState);
    const nextId = useNextTempId();
    
    return (photoPointId, sampleEventStartDate) => ({
        photoPointHistoryId: nextId(),
        photoPointId,
        activeFrom: sampleEventStartDate ? DateUtils.FormatToLocalDate(sampleEventStartDate) : DateUtils.GetCurrentDate(),
        activeTo: null,
        activateUserId: user.userId,
        deactivateUserId: null,
        notes: null,
    });
}

/**
 * Converts string to a date or null.
 * Null means continues to infinity.
 * @param {string} dateString
 * @returns {Date|null}
 */
const localDateFromPhotoPointHistoryString = (dateString) => {
    return dateString ? new Date(dateString) : null;
}

/**
 * Cleans up an array of PhotoPointHistory to ensure that
 * there are no overlapping periods. This is useful for
 * interpreting data from old versions of the software
 * that might not make sense, and we need to handle them
 * in some sort of sane way.
 * 
 * @param photoPointHistories
 * @returns {*[]}
 */
const removeOverlappingPhotoPointHistories = (photoPointHistories) => {
    
    let result = [];
    
    for (const current of photoPointHistories) {
        const currentActiveFrom = localDateFromPhotoPointHistoryString(current.activeFrom);
        const currentActiveTo = localDateFromPhotoPointHistoryString(current.activeTo);
        
        // remove or adjust any results that conflict
        // with the current
        result = result.map(existing => {
            const existingActiveFrom = localDateFromPhotoPointHistoryString(existing.activeFrom);
            const existingActiveTo = localDateFromPhotoPointHistoryString(existing.activeTo);
            
            // if the previous history entry overlaps with
            // the newer one, prefer the newer one
            // adjust the previous ActiveTo
            if(existingActiveTo > currentActiveFrom &&
                (!currentActiveTo || existingActiveTo >= currentActiveFrom)) {
                existing.activeTo = current.activeTo;
                
                if(existingActiveTo <= existingActiveFrom) {
                    // remove this history that is entirely contained
                    // in the more recent entry
                    return null;
                }
            }
            
            // if the previous starts during the current
            // adjust the previous activeFrom
            if(existingActiveFrom > currentActiveFrom &&
                (!currentActiveTo || existingActiveFrom < currentActiveTo) ) {
                // push it to the end of this one
                existing.activeFrom = current.activeTo;
            }
            
            // remove entries that are 0 length
            if(existing.activeFrom >= existing.activeTo || existing.activeFrom == null) {
                return null;
            }
            
            return existing;
        }).filter(Boolean); // filter out null values
        
        result.push(current);
    }
    
    return result;
}

export const photoPointWasActiveDuringSampleEvent = (photoPoint, sampleEvent) => {
    
    // for new photo points, always assume they are visible
    if(photoPoint?.PhotoPointHistory?.length === 0) {
        return true;
    }
    
    const histories = removeOverlappingPhotoPointHistories(photoPoint.PhotoPointHistory)

    return histories.some( 
        photoPointHistory => {
            const localStartDate = new Date(DateUtils.FormatToLocalDate(sampleEvent.startDate));
            const localActiveFrom = localDateFromPhotoPointHistoryString(photoPointHistory.activeFrom);
            const localActiveTo = localDateFromPhotoPointHistoryString(photoPointHistory.activeTo);
            
            return (localStartDate >= localActiveFrom) &&
                (localActiveTo === null || localStartDate < localActiveTo)
        }
    );
}

export const useStoreNewPhoto = () => {
    const nextId = useNextTempId()
    return async (photo) => {
        return addPhotoContent(nextId(), photo);
    }
}
