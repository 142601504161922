/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */



import React from "react";

const SpeciesTableDataCell = (props) => {
	
	const {
		children = undefined,
		className = 'type-normal',
		colSpan = undefined,
		rowSpan = undefined,
		leftBorderClass = true,
		rightBorderClass = true,
	} = props
	
	const classNames = [className];
	if(leftBorderClass) { classNames.push('left-border'); }
	if(rightBorderClass) { classNames.push('right-border'); }
	if(children == null) { classNames.push('no-data'); }
	if(String(children) === '0') { classNames.push('zero-data'); }
	
	return <td colSpan={colSpan} rowSpan={rowSpan} className={classNames.join(' ')}>{children}</td>;
}

export default SpeciesTableDataCell;
