import {createSelector} from 'reselect';
import HydrozoneUtils from "../../Utils/HydrozoneUtils";
import {TYPE_INVASIVE_COVER, TYPE_TREE_DBH} from "../../Constants/lengthCategories";
import {projectSelector} from "./nodeSelectors";

const transectSelector = state => state.offlineDataState.transects;

export const selectTransectByTransectId = createSelector(
    [transectSelector, ((_, transectId) => transectId)],
    (transects, transectId) => {
        return transects.find(
            transect => String(transect.transectId) === String(transectId)
        );
    }
);

/**
 * @type {(state: DefaultRootState, transectId) => (Object|undefined)}
 */
export const selectProjectByTransectId = createSelector(
    [selectTransectByTransectId, projectSelector],
    (transect, projects) => {
        return projects.find(project => String(project.projectId) === String(transect.projectId));
    }
);

/**
 * @type {(state: DefaultRootState, transectId) => (Array|undefined)}
 */
export const selectAllSpeciesByTransectId = createSelector(
    [selectProjectByTransectId],
    (project) => {
        return project?.Species;
    }
);

export const selectTransectsWithPlots = createSelector(
    [transectSelector],
    transects => transects.map(transect => {
        return {
            ...transect,
            Hydrozones: HydrozoneUtils.ComputeHydrozoneEnds(transect),
            HydrozonePlots: HydrozoneUtils.ComputeHydrozonePlots(transect),
        }
    })
);

/**
 * @type {(state: DefaultRootState, transectId) => Object}
 */
export const selectTransect = createSelector(
    [selectTransectsWithPlots, (_, transectId) => transectId],
    (transectsWithPlots, transectId) => {
        return transectsWithPlots.find(transect => String(transect.transectId) === String(transectId));
});

/**
 * @type {(state: DefaultRootState, transectId, plotTypeId) => Array}
 */
export const selectTransectHydrozonePlotsByPlotTypeId = createSelector(
    [selectTransect, (_, transectId) => transectId, (_, transectId, plotTypeId) => plotTypeId],
    (transect, transectId, plotTypeId) => {
        return transect.HydrozonePlots
            .filter(plot => String(plot.plotTypeId) === String(plotTypeId))
            .sort((a, b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? 1: -1);
    });

/**
 * @type {(state: DefaultRootState, transectId) => Array}
 */
export const selectTransectHydrozones = createSelector(
    [selectTransect],
    (transect) => transect.Hydrozones
);

/**
 * @type {(state: DefaultRootState, transectId, hydrozoneTypeId) => Object}
 */
export const selectTransectHydrozone = createSelector(
    [selectTransect, (_, transectId) => transectId, (_, transectId, hydrozoneTypeId) => hydrozoneTypeId],
    (transect, transectId, hydrozoneTypeId) => {
        return transect.Hydrozones.find(hydrozone => String(hydrozone.hydrozoneTypeId) === String(hydrozoneTypeId));
});

/**
 * @type {(state: DefaultRootState, transectId, hydrozoneTypeId, plotTypeId) => Array}
 */
export const selectTransectHydrozonePlot = createSelector(
    [selectTransect, (_, transectId) => transectId, (_, transectId, hydrozoneTypeId) => hydrozoneTypeId, (_, transectId, hydrozoneTypeId, plotTypeId) => plotTypeId],
    (transect, transectId, hydrozoneTypeId, plotTypeId) => {
        return transect.HydrozonePlots.find(plot => String(plot.plotTypeId) === String(plotTypeId) && String(plot.hydrozoneTypeId) === String(hydrozoneTypeId));
});

/**
 * @type {(state: DefaultRootState, transectId, plotTypeId, hydrozoneId) => Array}
 */
export const selectTransectHydrozonePlotIndex = createSelector(
    [selectTransectHydrozonePlotsByPlotTypeId, (_, transectId, plotTypeId, hydrozoneId) => hydrozoneId],
    (hydrozonePlotsForThisPlotType, hydrozoneId) => {
        return [
            hydrozonePlotsForThisPlotType.findIndex((item) => item.hydrozoneId === hydrozoneId) + 1,
            hydrozonePlotsForThisPlotType.length
        ];
});

/**
 * @type {(state: DefaultRootState, transectId, hydrozoneTypeId, plotTypeId, type) => Array}
 */
export const selectTransectHydrozonesWithFilteredPlotSpecies = createSelector(
    [
        selectTransectHydrozones,
        (state, transectId, plotTypeId) => plotTypeId,
        (state, transectId, plotTypeId, type) => type,
        selectAllSpeciesByTransectId,
    ],
    (hydrozones, plotTypeId, type, allSpecies) => {
        
        const lookupSpecies = (speciesId) => allSpecies.find(species => String(species.speciesId) === String(speciesId));
        const isValidDBHSpecies = (speciesId) => ['1', '5'].includes(String(lookupSpecies(speciesId)?.speciesTypeId));
        
        return hydrozones.map(hydrozone =>
            ({
                ...hydrozone,
                HydrozonePlotSpecies: hydrozone.HydrozonePlotSpecies.filter(hydrozoneSpecies =>
                    String(hydrozoneSpecies.plotTypeId) === String(plotTypeId) &&
                    // in tree dbh, also exclude non-tree growth types
                    (type !== TYPE_TREE_DBH || isValidDBHSpecies(hydrozoneSpecies.speciesId))
                )
            })
        );
    }
);

/**
 * @type {(state: DefaultRootState, transectId, plotTypeId, type, hydrozoneTypeId) => Object|undefined}
 */
export const selectTransectHydrozoneWithFilteredPlotSpecies = createSelector(
    [
        selectTransectHydrozonesWithFilteredPlotSpecies,
        (state, transectId, plotTypeId, type, hydrozoneTypeId) => hydrozoneTypeId,
    ],
    (hydrozones, hydrozoneTypeId) =>
        hydrozones.find(
            hydrozone => String(hydrozone.hydrozoneTypeId) === String(hydrozoneTypeId)
        )
);

/**
 * @type {(state: DefaultRootState, transectId, plotTypeId, type, hydrozoneTypeId) => Object|undefined}
 */
export const selectTransectHydrozoneFilteredPlotSpecies = createSelector(
    [selectTransectHydrozoneWithFilteredPlotSpecies],
    (hydrozone) => hydrozone?.HydrozonePlotSpecies
);


/**
 * @type {(state: DefaultRootState, transectId, hydrozoneTypeId, plotTypeId, type) => (Number|null)[]}
 */
export const selectSpeciesIdsForTransectHydrozonesWithFilteredPlotSpecies = createSelector(
    [
        selectTransectHydrozonesWithFilteredPlotSpecies,
        (state, transectId, plotTypeId, type) => type,
    ],
    (hydrozones, type) => {
        const hydrozoneSpeciesField = type === TYPE_INVASIVE_COVER ? 'HydrozoneGroundCoverSpecies' : 'HydrozonePlotSpecies';
        const uniqueSpeciesIds = new Set(
            hydrozones.flatMap(hydrozone => 
                hydrozone[hydrozoneSpeciesField].map(species => species.speciesId))
        );
        return [...uniqueSpeciesIds];
    }
);
