import {useDispatch, useSelector} from "react-redux";
import {useNextTempId} from "./TempIdHooks";
import {
    categoriesByType,
    TYPE_LENGTH_OF_COVER,
    TYPE_STEM_TALLY,
    TYPE_TREE_DBH
} from "../Constants/lengthCategories";
import {UPSERT_TRANSECT} from "../Redux/Actions/Types/offlineDataActionTypes";
import {transectPhotoPoints} from "../Constants/photos";
import EventUtils from "../Utils/EventUtils";
import DateUtils from "../Utils/DateUtils";
import {
    addRepeaterItem,
    upsertQualitativeVegetationMonitoring,
    updateRepeaterItem
} from "../Redux/Actions/surveyActions";
import {PLOT_TYPE_1, PLOT_TYPE_2} from "../Constants/hydrozones";
import {
    TRANSECT_REVIEW_CODE_CANOPY,
    TRANSECT_REVIEW_CODE_DBH,
    TRANSECT_REVIEW_CODE_INSTREAM,
    TRANSECT_REVIEW_CODE_INVASIVE_COVER,
    TRANSECT_REVIEW_CODE_NARRATIVE,
    TRANSECT_REVIEW_CODE_PHOTOS,
    TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../Constants/transectReview";
import LengthCategoryUtils from "../Utils/LengthCategoryUtils";
import {selectSpeciesList} from "../Redux/Selectors/speciesSelectors";
import {useNewPhotoPointHistory} from "./PhotoHooks";

/**
 * 'Most recent' means the most advanced Phase, followed by the most recent date
 */
export const useMostRecentSampleEvent = () => {
    const sampleEvents = useSelector(state => state.offlineDataState.sampleEvents);

    return (currentEvent) => {
        return EventUtils.getMostRecentSampleEvent(
            sampleEvents,
            currentEvent.projectId,
            currentEvent.procedureId,
            [currentEvent.sampleEventId]
        );
    }
};

export const useCopyEventData = (projectId) => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const emptyCanopyClosure = useEmptyCanopyClosure();
    const emptyTransectReview = useEmptyTransectReview();
    const emptyQualVegMonitoring = useEmptyQualVegMonitoring();
    const mostRecentSampleEvent = useMostRecentSampleEvent();

    const user = useSelector(state => state.userState.user);
    
    const hydrozonePlotDataTypes = [TYPE_STEM_TALLY, TYPE_LENGTH_OF_COVER, TYPE_TREE_DBH];
    const speciesListsByType = hydrozonePlotDataTypes.reduce((acc, hydrozonePlotDataType) => {
        acc[hydrozonePlotDataType] = useSelector(state => selectSpeciesList(state, projectId, LengthCategoryUtils.GetSpeciesListTypeByType(hydrozonePlotDataType), null));
        return acc;
    }, {});
    
    const {programs, projects, transects, qualitativeVegetationMonitorings} = useSelector(state => state.offlineDataState);

    return (sampleEvent) => {
        const recentSampleEvent = mostRecentSampleEvent(sampleEvent);

        const project = projects.find(project => String(project.projectId) === String(sampleEvent.projectId));
        const subProgram = programs.find(program => String(program.hierarchyId) === String(project.parentHierarchyId));
        const procedure = subProgram.Procedures.find(procedure => procedure.procedureId === sampleEvent.procedureId);

        switch (procedure.code) {
            case 'quant-veg':
                if(recentSampleEvent) {
                    const transectsToCopy = transects
                        .filter(transect => {
                            return String(transect.sampleEventId) === String(recentSampleEvent.sampleEventId)
                        })
                        .map(transect => {
                            const transectId = nextId();

                            const transectPlots = transect.TransectPlots.map(plot => ({
                                ...plot,
                                transectPlotId: nextId(),
                                transectId,
                            }));

                            const transectCanopyClosure = {
                                ...emptyCanopyClosure,
                                measurementLocation: transect.TransectCanopyClosure?.measurementLocation ?? calculateCanopyClosureMeasurementLocationFromTransect(transect)
                            };

                            return {
                                ...transect,
                                transectId,
                                sampleEventId: sampleEvent.sampleEventId,
                                Hydrozones: transect.Hydrozones.map(hydrozone => {
                                    const hydrozoneId = nextId();

                                    return {
                                        ...hydrozone,
                                        hydrozoneId,
                                        transectId,
                                        leftQuadratStart: null,
                                        rightQuadratStart: null,
                                        hasNoStemsPlot1: false,
                                        hasNoStemsPlot2: false,
                                        hasNoLengthOfCoverPlot1: false,
                                        hasNoLengthOfCoverPlot2: false,
                                        hasNoDBHPlot1: null,
                                        hasNoDBHPlot2: null,
                                        HydrozoneGroundCoverSpecies: [],
                                        HydrozonePlotSpecies: hydrozone.HydrozonePlotSpecies.map(species => {
                                            const speciesData = {
                                                hydrozoneId,
                                                plotTypeId: species.plotTypeId,
                                                speciesId: species.speciesId,
                                                speciesName: species.speciesName
                                            };

                                            hydrozonePlotDataTypes.forEach(hydrozonePlotDataType => {
                                                const lengthCategories = categoriesByType?.[hydrozonePlotDataType] ?? [];
                                                
                                                console.log(hydrozonePlotDataType, species.speciesId, speciesListsByType[hydrozonePlotDataType]);
                                                if(!speciesListsByType[hydrozonePlotDataType].find(s => String(s.speciesId) === String(species.speciesId))) {
                                                    console.log('skipped!');
                                                    return;
                                                }
                                                console.log('copied!');
                                                    
                                                lengthCategories.forEach(category => {
                                                    speciesData[category.code] = 0;
                                                });
                                            });

                                            return speciesData;
                                        })
                                    }
                                }),
                                TransectPlots: transectPlots,
                                TransectNarrative: null,
                                TransectCanopyClosure: transectCanopyClosure,
                                TransectReviews: emptyTransectReview,
                            }
                        });

                    transectsToCopy.forEach(transect => {
                        dispatch({type: UPSERT_TRANSECT, transect});
                    });
                }
                break;
            case 'qual-veg':
                const qualitativeVegetationMonitoring = qualitativeVegetationMonitorings.find(monitoring => String(monitoring.sampleEventId) === String(sampleEvent.sampleEventId));

                if (!qualitativeVegetationMonitoring) {
                    const qualitativeVegetationMonitoringId = nextId();

                    dispatch(upsertQualitativeVegetationMonitoring({
                        ...emptyQualVegMonitoring,
                        qualitativeVegetationMonitoringId,
                        sampleEventId: sampleEvent.sampleEventId,
                        createdBy: user.userId,
                    }));
                    
                    if(recentSampleEvent) {

                        // Copy competition question #4
                        const recentMonitoring = qualitativeVegetationMonitorings.find(monitoring => String(monitoring.sampleEventId) === String(recentSampleEvent?.sampleEventId));
                        const competitionRepeaters = recentMonitoring ? recentMonitoring.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === 'competition') : [];

                        competitionRepeaters.forEach(repeater => {
                            const repeaterId = nextId();
                            dispatch(
                                addRepeaterItem(
                                    qualitativeVegetationMonitoringId,
                                    'competition',
                                    user.userId,
                                    repeaterId,
                                    'competitionSpecies',
                                    repeater.competitionSpecies,
                                )
                            )
                            dispatch(
                                updateRepeaterItem(
                                    qualitativeVegetationMonitoringId,
                                    repeaterId,
                                    'competitionSpeciesId',
                                    repeater.competitionSpeciesId,
                                )
                            )
                        });
                    }
                }
                break;
        }
    }
};

export const useEmptyCanopyClosure = () => {
    return {
        'measurementLocation': '',
        'canopyClosure': '',
        'north': '',
        'south': '',
        'east': '',
        'west': '',
        'notes': ''
    };
};

export const calculateCanopyClosureMeasurementLocationFromTransect = (transect, hadPlotChanges = false) => {
    const existingValue = hadPlotChanges ? null : transect.TransectCanopyClosure?.measurementLocation;
    return existingValue ?? calculateCanopyClosureMeasurementLocation(transect.TransectPlots, transect.hasNoPlot2)
}

export const calculateCanopyClosureMeasurementLocation = (plots, noRoom) => {
    const plot1 = plots?.find(plot => plot.plotTypeId === PLOT_TYPE_1);
    const plot2 = plots?.find(plot => plot.plotTypeId === PLOT_TYPE_2);

    if (plot2 && !noRoom) {
        return plot2?.start;
    }

    if (!plot1) {
        return null;
    }

    return plot1.start + plot1.length;
};

export const useEmptyTransectReview = () => {
    return [
        TRANSECT_REVIEW_CODE_NARRATIVE,
        TRANSECT_REVIEW_CODE_PHOTOS,
        TRANSECT_REVIEW_CODE_STEM_LENGTH,
        TRANSECT_REVIEW_CODE_DBH,
        TRANSECT_REVIEW_CODE_INVASIVE_COVER,
        TRANSECT_REVIEW_CODE_CANOPY,
        TRANSECT_REVIEW_CODE_INSTREAM
    ].map(code => ({
        code,
        isConfirmed: false,
        confirmedBy: null,
    }));
};

export const useEmptyTransectPhotoPoints = () => {
    const nextId = useNextTempId();
    const newPhotoPointHistory = useNewPhotoPointHistory();

    return (sampleLocationId, sampleEventStartDate) => {
        return transectPhotoPoints
            .map(({name, photoDirection}) => {
                const photoPointId = nextId();
                return {
                    photoPointId,
                    sampleLocationId,
                    name,
                    photoDirection,
                    compassBearing: 0,
                    isActive: true,
                    PhotoPointHistory: [newPhotoPointHistory(photoPointId, sampleEventStartDate)]
                };
            });
    }
};

export const useEmptyQualVegMonitoring = () => {
    return {
        QualitativeVegetationMonitoringRecommendations: [],
        QualitativeVegetationMonitoringRepeaters: [],
        plotNumber: null,
        projectOnTrack: null,
        projectOnTrackDescription: null,
        healthyNativeShrubs5: null,
        healthyNativeTrees3: null,
        averageHeightOfPlantedTrees: null,
        averageCanopyClosure: null,
        averageHeightOfPlantedShrubs: null,
        nativeWoodyUnderstoryCover: null,
        riverCenterlineShade: null,
        extentOfRiverCenterlineShade: null,
        naturalRecruits: null,
        healthyPlantedSpecies: null,
        plantHealthComments: null,
        thirstyPlantedSpecies: null,
        worsePlantedSpecies: null,
        streamDistanceMortality: null,
        streamDistanceMortalityDescription: null,
        soilConditionsMortality: null,
        soilConditionsMortalityDescription: null,
        otherConditionsMortality: null,
        otherConditionsMortalityDescription: null,
        irrigationMethodOverhead: null,
        irrigationMethodDrip: null,
        irrigationMethodFlood: null,
        irrigationMethodNone: null,
        irrigationMethodOther: null,
        irrigationSystem: null,
        irrigationSystemDescription: null,
        waterMeterReading: null,
        waterMeterReadingYesNo: null,
        waterMeterReadingUnit: null,
        irrigationNotes: null,
        noxiousWoodyCover: null,
        noxiousHerbaceousCover: null,
        problematicSpeciesCover: null,
        wildlifeAreaDescription: null,
        impactingPlantHealth: null,
        browseProtectionCages: null,
        browseProtectionExclosures: null,
        browseProtectionRepellent: null,
        browseProtectionPerimeterFence: null,
        browseProtectionTrunkPaint: null,
        browseProtectionNone: null,
        browseOtherProtectionMethod: null,
        browseProtection: null,
        browseProtectionDescription: null,
        browseProtectionFrequency: null,
        browseProtectionFrequencyDescription: null,
        livestockTrespass: null,
        livestockTrespassDescription: null,
        fencingRepair: null,
        fencingRepairDescription: null,
        areasOfSoilCompaction: null,
        areasOfSoilCompactionIdentified: null,
        addressPotentialCauses: null,
        activeErosion: null,
        insectOrDiseaseYesNo: null,
        insectOrDiseasePercentPlantsImpacted: null,
        insectOrDiseaseAffecting: null,
        insectOrDiseaseImpactedPlantedSpecies: null,
        floodImpacted: null,
        fireImpacted: null,
        iceDamage: null,
        windDamage: null,
        vandalism: null,
        trespass: null,
        trespassType: null,
        otherTrespassType: null,
        driftImpacted: null,
        siteDisturbanceNotes: null,
        performanceRecommendations: null,
        plantHealthRecommendations: null,
        irrigationRecommendations: null,
        competitionRecommendations: null,
        wildlifeRecommendations: null,
        soilsRecommendations: null,
        insectsRecommendations: null,
        otherConditionRecommendations: null,
        evident: null,
        evidentDescription: null,
        fertilizerRequired: null,
        mulchEffective: null,
        mulchEffectiveDescription: null,
        weedTreatment: null,
        weedTreatmentDescription: null,
        managementTechniques: null,
        landownerConversations: null,
        narrativeDescription: null,
        offlineCreatedDate: DateUtils.GetCurrentDateTime(), // because there is no ADD_QUALITATIVE_VEG_MONITORING
    }
};
