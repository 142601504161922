/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {FormCheck} from "react-bootstrap";
import React from "react";

export const RadioInput = (props) => {
	const {
		children,
		className = "survey-radio-input",
		code,
		id,
		inline = undefined,
		options,
		readOnly,
		value: selectedValue,
		onChange = (value) => {},
		valueKey = 'value',
		labelKey = 'label',
	} = props;
	
	return (
		<div className={className}>
			{
				options.map(({[valueKey]:value,[labelKey]:label}, i) => (
					<FormCheck
						key={`${code}-${i}`}
						id={`${code}-${i}`}
						name={id ?? code}
						label={label}
						type="radio"
						value={value}
						checked={String(value) === String(selectedValue)}
						onChange={(event) => !readOnly && onChange(value)}
						inline={inline}
						disabled={!!readOnly}
					/>
				))
			}
			{
				children
			}
		</div>
	);
};
