export const SET_PROGRAMS = 'offlineData/SET_PROGRAMS';
export const SET_PROJECTS = 'offlineData/SET_PROJECTS';
export const SET_SAMPLE_EVENTS = 'offlineData/SET_SAMPLE_EVENTS';
export const SET_CAMERA_POINTS = 'offlineData/SET_CAMERA_POINTS';
export const SET_PHOTO_POINTS = 'offlineData/SET_PHOTO_POINTS';
export const SET_PHOTOS = 'offlineData/SET_PHOTOS';
export const SET_TRANSECTS = 'offlineData/SET_TRANSECTS';
export const SET_PHOTO_DEPENDENCIES = 'offlineData/SET_PHOTO_DEPENDENCIES';

export const UPSERT_PROGRAM = 'offlineData/UPSERT_PROGRAM';
export const UPSERT_PROJECT = 'offlineData/UPSERT_PROJECT';
export const UPSERT_SAMPLE_EVENT = 'offlineData/UPSERT_SAMPLE_EVENT';
export const UPSERT_CAMERA_POINT = 'offlineData/UPSERT_CAMERA_POINT';
export const UPSERT_PHOTO = 'offlineData/UPSERT_PHOTO';
export const UPSERT_TRANSECT = 'offlineData/UPSERT_TRANSECT';
export const UPSERT_SURVEY = 'offlineData/UPSERT_SURVEY';

export const ADD_PROJECT_SPECIES = 'offlineData/ADD_PROJECT_SPECIES';
export const ADD_PHOTO_POINT = 'offlineData/ADD_PHOTO_POINT';

export const UPDATE_PROJECT_VALUE = 'offlineData/UPDATE_PROJECT_VALUE';
export const UPDATE_PHOTO_POINT = 'offlineData/UPDATE_PHOTO_POINT';
export const UPDATE_TRANSECT_VALUE = 'offlineData/UPDATE_TRANSECT_VALUE';
export const UPSERT_QUAL_VEG_MONITORING = 'offlineData/UPSERT_QUAL_VEG_MONITORING';

export const DELETE_PHOTO = 'offlineData/DELETE_PHOTO';
export const DELETE_PROGRAMS = 'offlineData/DELETE_PROGRAMS';
export const DELETE_PROJECTS = 'offlineData/DELETE_PROJECTS';
export const DELETE_SAMPLE_EVENT_PHASES = 'offlineData/DELETE_SAMPLE_EVENT_PHASES';
export const DELETE_SAMPLE_EVENTS = 'offlineData/DELETE_SAMPLE_EVENTS';
export const DELETE_SAMPLE_EVENT_PHOTOS = 'offlineData/DELETE_SAMPLE_EVENT_PHOTOS';
export const DELETE_SAMPLE_EVENT_TRANSECTS = 'offlineData/DELETE_SAMPLE_EVENT_TRANSECTS';
export const DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS = 'offlineData/DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS';
export const DELETE_CAMERA_POINTS = 'offlineData/DELETE_CAMERA_POINTS';
export const DELETE_TRANSECTS = 'offlineData/DELETE_TRANSECTS';
export const DELETE_QUAL_VEG_MONITORING = 'offlineData/DELETE_QUAL_VEG_MONITORING';
export const DELETE_EVERYTHING = 'offlineData/DELETE_EVERYTHING';

export const ADD_SYNC_CONFLICT = 'sync/ADD_SYNC_CONFLICT';

export const RESTORE_BACKUP = 'offlineData/RESTORE_BACKUP';
