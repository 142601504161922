import React from 'react';
import ErrorComponent from "./ErrorComponent";

const ErrorPage = props => {
    const {
        children = <ErrorComponent {...props} />,
    } = props;
    
    return (
        <div className="error-page">
            {children}
        </div>
    )
};

export default ErrorPage;
