/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {hydrozoneGroundCoverSpeciesSum} from "../../../Redux/Selectors/transectProgressSelectors";

export const HydrozoneCoverPercentLabel = (props) => {
	const {
		hydrozoneSpecies,
		categoryCode,
		noPlants
	} = props;
	
	if(noPlants) {
		return null;
	}
	
	const percent = hydrozoneGroundCoverSpeciesSum(hydrozoneSpecies,categoryCode);
	
	const className = percent >= 100 ? "cover-percent-label" : "cover-percent-label validation-errors";
	
	return <b className={className}>{percent.toFixed(0)}%</b>;
} 
