import React, {Fragment} from 'react';
import {Button, Col, ModalBody, ModalFooter, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Modal from './Modal';

const ConfirmDeleteModal = props => {
    const {
        discard,
        delete: onDelete,
        children,
        show,
        itemMessage,
        onHide,
        title = null,
        confirm = null,
        verb: verbFromProps = null,
        footerContent = null,
    } = props;
    const verb = verbFromProps ?? (discard ? 'Discard' : 'Delete');

    return (
        <Modal
            title={title ?? `Confirm ${verb}`}
            size="md"
            show={show}
            onHide={() => onHide()}
        >
            <ModalBody>
                <Row xs={12}>
                    <Col xs={12}>
                        <span>
                            {
                                children ?
                                    children :
                                    itemMessage ?
                                        `Are you sure you want to ${verb.toLowerCase()} ${itemMessage}?` :
                                        `Are you sure you want to ${verb.toLowerCase()} the current item?`
                            }
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    footerContent ??
                    <Fragment>
                        <Button variant="link" className="w-25" onClick={() => onHide()}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                            Cancel
                        </Button>
                        <Button variant="danger" className="w-25" onClick={() => onDelete()}>
                            {
                                !discard &&
                                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                            }
                            {verb}
                        </Button>
                    </Fragment>
                }
            </ModalFooter>
        </Modal>
    )
};

export default ConfirmDeleteModal;
