import React from 'react';
import {FormControl, FormGroup, FormLabel, InputGroup} from 'react-bootstrap';

const CheckboxList = props => {
    const {
        data, // all the checkboxes
        checked, // the subset of `data` that is checked
        primaryKey = 'id',
        labelKey = 'label',
        clearingOptions = [],
        onChange,
        required,
        readOnly,
        label
    } = props;

    const isItemChecked = (checkedItem) => checked.find(check => check[primaryKey] === checkedItem[primaryKey]);
    
    const toggleCheckboxItem = (toggledItem) => {
        const toggleValue = toggledItem[primaryKey];
        
        if(readOnly) {
            return;
        }
        
        const newCheckedValues = data?.filter((thisItem) => {
            if ( clearingOptions && (
                (clearingOptions.includes(toggledItem[labelKey]) && thisItem[labelKey] !== toggledItem[labelKey]) ||
                (clearingOptions.includes(thisItem[labelKey]) && thisItem[labelKey] !== toggledItem[labelKey])
            )) {
                return false;
            }
            
            if(thisItem[primaryKey] === toggleValue) {
                return !isItemChecked(thisItem);
            }
            
            return isItemChecked(thisItem);
        });
        
        onChange([...newCheckedValues], toggleValue);
    };

    return (
        <FormGroup className={`checkbox-list ${required ? 'required' : ''}`}>
            <FormLabel>{label}</FormLabel>
            {
                data.map((item, i) => {
                    const id = item[primaryKey];
                    return (
                        <InputGroup key={id}>
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                    id={id + '-' + i}
                                    checked={isItemChecked(item) ?? false}
                                    onChange={() => toggleCheckboxItem(item)}
                                />
                            </InputGroup.Prepend>
                            <FormControl onClick={() => toggleCheckboxItem(item)} value={item[labelKey]} readOnly/>
                        </InputGroup>
                    );
                })
            }
        </FormGroup>
    );
};

export default CheckboxList;
