import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useParams, useLocation} from 'react-router-dom'
import {Col, Row} from "react-bootstrap";

import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {
    PHOTO_POINT_DOWNSTREAM,
    PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_TYPE_END,
    PHOTO_POINT_TYPE_ORIGIN,
    PHOTO_POINT_UPSTREAM
} from "../../../Constants/photos";

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import PageContainer from "../../Layout/Page/PageContainer";
import PhotoPointRow from "../../Common/PhotoPointRow";
import {useQuantVegNav} from "../../../Hooks/QuickNavHooks";
import FooterNav from "../../Layout/Page/FooterNav";
import PageFooter from "../../Layout/Page/PageFooter";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {NoDataCheckBox} from "./NoDataCheckBox";
import {UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";


const TransectPhotoPointsPage = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    useQuantVegNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));

    const type = location.pathname.split('/').pop(); // last segment of url
    const isInstreamShade = type === 'instream-shade';
    let photoPointTypes, subtitle;

    if (isInstreamShade) {
        subtitle = 'Instream Photos and Shade';
        photoPointTypes = [PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_UPSTREAM, PHOTO_POINT_DOWNSTREAM];
    } else {
        subtitle = 'Origin & End Photos';
        photoPointTypes = [PHOTO_POINT_TYPE_ORIGIN, PHOTO_POINT_TYPE_END];
    }
    const photoPoints = transect.PhotoPoints.filter(point => photoPointTypes.includes(point.name));

    useBreadcrumbs(subtitle, transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);

    const finalized = useFinalized(transect.sampleEventId);
    useReadOnly(finalized);

    return (
        <PageContainer className="photo-points-page">
            <PageHeader>
                <Title
                    title={`Transect ${transect.name}`}
                    subtitle={subtitle}
                    readOnly={finalized}
                    action={
                        isInstreamShade ?
                        <NoDataCheckBox
                            label="No instream photos collected at this transect"
                            value={transect.hasNoInstreamPhotos ?? false}
                            onChange={() => !finalized && dispatch({
                                type: UPSERT_TRANSECT,
                                transect: {
                                    ...transect,
                                    hasNoInstreamPhotos: !(transect.hasNoInstreamPhotos ?? false),
                                }
                            })}
                        /> : null
                    }
                />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col xs={12}>
                        {photoPoints?.map(point => {
                            return (
                                <PhotoPointRow
                                    key={point.photoPointId}
                                    photoPoint={point}
                                    eventId={transect.sampleEventId}
                                    transectPhotoPoint
                                    readOnly={finalized || (isInstreamShade && transect.hasNoInstreamPhotos)}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </PageContent>
            <PageFooter>
                <FooterNav />
            </PageFooter>
        </PageContainer>
    )
};

export default TransectPhotoPointsPage;
