/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {useMemo} from "react";

export const useStandardizedOptions = (questionOptions, primaryKey = 'value', labelKey = 'label') => {
	
	// some questions have {} or [] as the Question.options
	// which can be turned into an Array instead of an Object
	// and will then have a prototype member function called
	// values which is not what we want.
	let optionItems = questionOptions?.hasOwnProperty('values') ? (questionOptions.values ?? []) : [];
	
	if(!optionItems || optionItems?.length < 1) {
		optionItems = [
			{[primaryKey]: 1, [labelKey]: "Yes"},
			{[primaryKey]: 0, [labelKey]: "No"}
		]
	}
	
	// some questions defined {code:, name:} pairs
	// this attempts to standardize that behavior
	for (const optionItem of optionItems) {
		if (!optionItem.hasOwnProperty(primaryKey) && optionItem.hasOwnProperty('code')) {
			optionItem[primaryKey] = optionItem['code'];
			delete optionItem['code'];
		}
		
		if (!optionItem.hasOwnProperty(labelKey) && optionItem.hasOwnProperty('name')) {
			optionItem[labelKey] = optionItem['name'];
			delete optionItem['name'];
		}
	}
	
	return optionItems;
}

export const useOnlyWhen = () => {
	
	const isFieldVisible = (options, answers) => {
		const { onlyWhen = null } = options ?? {};
		
		if (onlyWhen) {
			// get the keys which affect the rerender
			const relevantAnswerKeys = Object.keys(onlyWhen)
			return relevantAnswerKeys.every(key => String(answers[key]) === String(onlyWhen[key]));
		}
		
		return true;
	}
	
	const getVisibleQuestions = (questions, friendlyAnswers = {}) => {
		if (!questions || !friendlyAnswers) {
			return [];
		}
		return questions
			.filter(question => isFieldVisible(question.options, friendlyAnswers))
			.sort((a, b) => Number(a.order) - Number(b.order));
	}
	
	return {
		isFieldVisible,
		getVisibleQuestions
	};
}

export const allSurveyQuestions = (survey) => (
	survey?.SurveySections?.reduce((carry, section) => carry.concat(section.SurveyQuestions), []) ?? []
)

export const useFriendlyAnswers = (answers, survey) => {
	
	const allQuestions = allSurveyQuestions(survey);
	
	return useMemo(() => {
			
			let result = Object.fromEntries(answers.map((answer) => {
				const question = allQuestions.find((question) => String(question.surveyQuestionId) === String(answer.surveyQuestionId))
				return question?.code ? [
					question.code, // key
					(answer.valueBool ?? answer.valueNumeric ?? answer.valueText ?? null) //value
				] : null
			}).filter(Boolean))
			console.log(result);
			return result;
		},
		[answers, allQuestions]
	);
}
