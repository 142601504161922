/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

let quotaExceeded = false;
export const storageErrorHandler = (error, ignoreUnrecognizedErrors = false) => {
	if (error.name === 'QuotaExceededError' || error.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
		if(!quotaExceeded) {
			quotaExceeded = true;
			alert('StreamBank Monitoring has exceeded the system\'s permitted storage quota.\nPlease backup your data.');
		}
		console.log(error);
	} else if (error?.indexOf?.("An internal error was encountered in the Indexed Database server") >= 0 ||
		error?.indexOf?.("Connection to Indexed Database server lost" >= 0)) {
		// Refresh the page to restore IndexedDb to a working state.
		alert("Connection to Indexed Database server lost.\nStreamBank Monitoring will restart, then please try again.")
		window.location.reload();
	} else if(!ignoreUnrecognizedErrors) {
		alert('Unhandled Indexed Database error:' + error?.message ?? error?.name ?? error);
		console.log('writeFailHandler', error);
	}
}
