export class KeyboardUtils {
    static handleKeyPress(keyCode, event, callback, preventDefault = true) {
        
        if(keyCode === undefined) {
            console.log('Undefined keyCode.');
            console.trace();
        }

        // see: https://stackoverflow.com/a/4471635/385979
        const charCode = event.which || event.keyCode || 0;

        if(charCode === keyCode){
            if(preventDefault) { event?.preventDefault?.() }
            
            if(typeof callback === "function") {
                callback(event);
            }
        }
    }
}
