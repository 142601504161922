import {createSelector} from "reselect";
import {selectAvailableSampleEvents} from "./nodeSelectors";
import {selectSurveysWithProgress} from "./qualitativeProgressSelectors";
import {selectCameraPointsWithProgress} from "./cameraPointProgressSelectors";
import {selectSampleEventTransectsWithProgress} from "./transectProgressSelectors";

const sampleEventWithProgress = (event, transectsWithProgress, cameraPointsWithProgress, surveysWithProgress) => {
	let progressNumerator, progressDenominator, dataWithProgress = [];
	
	switch (event?.procedureCode) {
		case 'quant-veg':
			// const eventTransects = transects.filter(transect => String(transect.sampleEventId) === String(event.sampleEventId));
			dataWithProgress = transectsWithProgress;
			break;
		case 'qual-veg':
			// const eventSurveys = surveys.filter(survey => String(survey.programId) === String(event.subProgramId));
			dataWithProgress = surveysWithProgress;
			break;
		case 'photo':
			// const eventCameraPoints = cameraPoints.filter(point => String(point.projectId) === String(event.projectId));
			dataWithProgress = cameraPointsWithProgress;
			break;
	}
	
	progressNumerator = dataWithProgress.reduce((acc, curr) => acc + curr.progressNumerator, 0);
	progressDenominator = dataWithProgress.reduce((acc, curr) => acc + curr.progressDenominator, 0);
	
	let progress = progressNumerator / progressDenominator * 100;
	
	if(event.isComplete) {
		progressNumerator = progressDenominator;
		progress = 100;
	}
	
	return {
		...event,
		progress,
		progressNumerator,
		progressDenominator,
		progressComplete: progress === 100,
		isCompleted: event.isComplete
	}
}

export const selectSampleEventsWithProgress = createSelector(
	[selectAvailableSampleEvents, selectSampleEventTransectsWithProgress, selectCameraPointsWithProgress, selectSurveysWithProgress],
	(sampleEvents, transectsWithProgress, cameraPointsWithProgress, surveysWithProgress) =>
		sampleEvents?.map( sampleEvent =>
			sampleEventWithProgress(
				sampleEvent,
				transectsWithProgress[sampleEvent.sampleEventId],
				cameraPointsWithProgress[sampleEvent.sampleEventId],
				surveysWithProgress[sampleEvent.sampleEventId]
			)
		) ?? []
);

export const selectSampleEventsWithProgressByProjectId = createSelector(
	[selectSampleEventsWithProgress, (state, projectId) => projectId],
	(sampleEventsWithProgress, projectId) =>
		sampleEventsWithProgress.filter(
			(sampleEvent) => String(sampleEvent.projectId) === String(projectId)
		) ?? []
)
