import {DELETE_PHOTO, UPSERT_QUAL_VEG_MONITORING} from "./Types/offlineDataActionTypes";
import {
    selectQualitativeVegetationMonitoringById,
    selectQualitativeVegetationMonitoringByRepeaterId
} from "../Selectors/qualitativeSelectors";
import {movePhotoContent} from "./photoActions";
import {incrementTempId} from "./tempIdActions";
import {selectPhotos} from "../Selectors/photoSelectors";
import {TEMP_ID_PREFIX} from "../../Constants/misc";

export const upsertQualitativeVegetationMonitoring = (qualitativeVegetationMonitoring) => async (dispatch, getState) => {
    dispatch({
        type: UPSERT_QUAL_VEG_MONITORING,
        qualitativeVegetationMonitoring
    });
    dispatch(duplicateQualitativeVegetationMonitoringPhotos(qualitativeVegetationMonitoring.qualitativeVegetationMonitoringId));
}

export const updateSurveyValues = (qualitativeVegetationMonitoring, fieldNameValuePairs) => async (dispatch, getState) => {
    for (const [code, value] of Object.entries(fieldNameValuePairs)) {
        dispatch(updateSurveyValue(qualitativeVegetationMonitoring, code, value));
    }
}

export const updateSurveyValue = (qualitativeVegetationMonitoringId, fieldName, value) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        [fieldName]: value,
    }));
};

// update the offlineModifiedDate of the QualitativeVegetationMonitoring record
// associated with a qualitativeVegetationMonitoringRepeaterId so that we save
// a new historical change set on next sync.
export const markQualitativeVegetationRepeaterAsModified = (qualitativeVegetationMonitoringRepeaterId) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringByRepeaterId(getState(), qualitativeVegetationMonitoringRepeaterId);
    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
    }));
}

// photos for Qualitative Vegetation Monitoring Repeaters
// should never be synced as 'deleted' since they are tied
// to historical QualitativeVegetationMonitoring records.
// Instead, we mark the QualitativeVegetationMonitoring as
// 'modified' (which will create a new historical record)
// and we remove the photo from the device.
export const deleteQualitativeVegetationRepeaterPhoto = (photo) => (dispatch, getState) => {
    const qualitativeVegetationMonitoringRepeaterId = photo.qualitativeVegetationMonitoringRepeaterId;
    
    // update the offlineModifiedDate of the QualitativeVegetationMonitoring record
    dispatch(markQualitativeVegetationRepeaterAsModified(qualitativeVegetationMonitoringRepeaterId));

    // remove the photo from our offlineModifiedData so that
    // it won't be attached to the new QualitativeVegetationMonitoring record
    dispatch({type: DELETE_PHOTO, photoId: photo.photoId});
}

export const addManagementRecommendation = (qualitativeVegetationMonitoringId, code, userId, id) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRecommendations: [
            ...qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRecommendations,
            {
                qualitativeVegetationMonitoringRecommendationId: id,
                code: code,
                recommendationNotes: '',
                createdBy: userId,
            }
        ]
    }));
};

export const updateManagementRecommendation = (qualitativeVegetationMonitoringId, id, value) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRecommendations: qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRecommendations.map(recommendation => {
            if (recommendation.qualitativeVegetationMonitoringRecommendationId === id) {
                return {...recommendation, recommendationNotes: value};
            }

            return recommendation;
        })
    }));
};

export const deleteManagementRecommendation = (qualitativeVegetationMonitoringId, id) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRecommendations: qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRecommendations
            .filter(field => field.qualitativeVegetationMonitoringRecommendationId !== id)
    }));
};

export const addRepeaterItem = (qualitativeVegetationMonitoringId, code, userId, id, fieldCode = null, value = null) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    let qualObject = {
        qualitativeVegetationMonitoringRepeaterId: id,
        qualitativeVegetationMonitoringId,
        code,
        locationId: null,
        competitionSpeciesIdentified: null,
        competitionSpecies: null,
        competitionSpeciesId: null,
        competitionEstimatedCover: null,
        competitionLifeStage: null,
        competitionNpSpecies: null,
        competitionNpSpeciesId: null,
        competitionNpEstimatedCover: null,
        competitionNpLifeStage: null,
        performanceSpecies: null,
        performanceSpeciesId: null,
        healthSpecies: null,
        healthSpeciesId: null,
        healthSpeciesCause: null,
        browseSpecies: null,
        browseSpeciesId: null,
        browseImpactedSpecies: null,
        browseAnimalNote: null,
        soilsAreaDescription: null,
        soilsReasonsNote: null,
        insectsImpactType: null,
        insectsImpactCausing: null,
        insectsSpecies: null,
        insectsSpeciesId: null,
        insectsOrDiseasesDescription: null,
        floodImpacted: null,
        floodAreaDescription: null,
        fireImpacted: null,
        fireAreaDescription: null,
        driftImpacted: null,
        driftAreaDescription: null,
        createdBy: userId,
    };

    if (fieldCode) {
        qualObject = {...qualObject, [fieldCode]: value};
    }

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRepeaters: [
            ...qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRepeaters,
            qualObject
        ]
    }));
};

export const updateRepeaterItem = (qualitativeVegetationMonitoringId, id, column, value) => (dispatch, getState) => {
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRepeaters: qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRepeaters.map(repeater => {
            if (repeater.qualitativeVegetationMonitoringRepeaterId === id) {
                return {...repeater, [column]: value};
            }

            return repeater;
        })
    }));
};

export const deleteRepeaterItem = (qualitativeVegetationMonitoringId, id) => (dispatch, getState) => {
    const {photos} = getState().offlineDataState;
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);

    dispatch(upsertQualitativeVegetationMonitoring({
        ...qualitativeVegetationMonitoring,
        QualitativeVegetationMonitoringRepeaters: qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRepeaters
            .filter(field => field.qualitativeVegetationMonitoringRepeaterId !== id)
    }));

    // Delete repeater photos
    photos.filter(photo => (
        String(photo.sampleEventId) === String(qualitativeVegetationMonitoring.sampleEventId) &&
        String(photo.qualitativeVegetationMonitoringRepeaterId === id)
    )).forEach(photo => {
        dispatch({type: DELETE_PHOTO, photoId: photo.photoId});
    });
};

// this method is called whenever the QVM is changed in order
// to ensure that we copy photos to the next QVM and keep a
// historical record.
export const duplicateQualitativeVegetationMonitoringPhotos = (qualitativeVegetationMonitoringId) => async (dispatch, getState) => {
    const photos = selectPhotos(getState());
    const qualitativeVegetationMonitoring = selectQualitativeVegetationMonitoringById(getState(), qualitativeVegetationMonitoringId);
    const sampleEventId = String(qualitativeVegetationMonitoring.sampleEventId);
    
    const qualitativeVegetationMonitoringRepeaterIds = 
        qualitativeVegetationMonitoring.QualitativeVegetationMonitoringRepeaters
        .filter(repeater => !repeater.deleted)
        .map( repeater => String(repeater.qualitativeVegetationMonitoringRepeaterId) );
    
    return await Promise.all(photos.map(async (photo) => {
        if( String(photo.sampleEventId) === sampleEventId &&
            qualitativeVegetationMonitoringRepeaterIds.includes(String(photo.qualitativeVegetationMonitoringRepeaterId))
        ) {
            if(!photo.deleted && !String(photo.photoId).startsWith(TEMP_ID_PREFIX)) {
                photo = await dispatch(movePhotoContent(photo, dispatch(incrementTempId())));
            }
        }
        return photo;
    }));
};
