import React from 'react';
import {useSelector} from "react-redux";
import {useParams, useHistory, Link} from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import TileList from '../../Common/TileList';
import PageContainer from '../../Layout/Page/PageContainer';

import PageHeader from "../../Layout/Page/PageHeader";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {selectSurveysWithProgressBySampleEventId} from "../../../Redux/Selectors/qualitativeProgressSelectors";

const QualVegToolPage = (props) => {
    const params = useParams();

    const event = useSelector(state => selectSampleEventById(state, params.eventId));
    const data = useSelector(state => selectSurveysWithProgressBySampleEventId(state, params.eventId));

    useBreadcrumbs(null, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);

    const getTileUrl = (item) => {
        const code = item.SurveySections[0].code;

        return `/event/${event.sampleEventId}/qual-veg/${item.surveyId}/${code}`
    };

    return (
        <PageContainer className="qual-veg-tool-page">
            <PageHeader>
                <Title title="Qualitative Monitoring Field Form" subtitle={event.name} />
            </PageHeader>
            <PageContent>
                {
                    !!data.length &&
                    <Row xs={12}>
                        <Col>
                            <TileList
                                className="tile-list-qual"
                                data={data}
                                primaryKey="surveyId"
                                tileUrl={getTileUrl}
                            />
                        </Col>
                    </Row>
                }
                {
                    !data.length &&
                    <div className="no-events-message">
                        <span>
                            No survey definitions were found for this project.<br/>
                            Please resync your on-device data or check with your Program Manager.
                        </span>
                        <Link to="/offline">
                            <Button variant="complete">Sync On-Device Data</Button>
                        </Link>
                    </div>
                }
            </PageContent>
            <PageFooter>
                <Row xs={3}>
                    <Col>
                        <Link to={`review`}>
                            <Button className="w-100" variant={event.isComplete ? "complete" : "primary"}>
                                {
                                    event.isComplete ?
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} /> :
                                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                }
                                Review & Finalize
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </PageFooter>
        </PageContainer>
    );
};

export default QualVegToolPage;
