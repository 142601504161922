import {useCallback, useEffect, useRef} from "react";

export const useUpdateEffect = (fn, dependency = []) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return fn();
        }
    }, dependency);
};

export const useDebouncedEffect = (fn, dependency, delay = 500) => {
    const callback = useCallback(fn, dependency);

    useUpdateEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

// This hook allows you to safely "useEffect" with async functions or promises
// and conditionally perform afterPromise only if the component is still
// mounted when the promise completes. This is useful for situations where
// you want to set the state of a component with the result from a promise
// and allows you to safely avoid the 'Can't perform a React state update on
// an unmounted component' error.
/**
 * @template T
 * @param {(function(): Promise<T>)|Promise<T>} promiseOrFunction
 * @param {function(T)} afterPromise
 * @param {*[]} deps
 * @param conditional
 */
export const useEffectPromise = (promiseOrFunction, afterPromise, deps, conditional = true) => {
    useEffect(() => {
        let stillMounted = true;
        
        if (conditional) {
            const promise = (typeof promiseOrFunction === 'function')
                ? promiseOrFunction()
                : promiseOrFunction;
            
            (async () => {
                try {
                    const result = await promise;
                    if (stillMounted) {
                        afterPromise?.(result);
                    }
                } catch (error) {
                    if (stillMounted) {
                        console.error(error);
                    }
                }
            })();
        }
        
        return () => {
            stillMounted = false;
        };
    }, deps);
};
