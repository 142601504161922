import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {Col, FormCheck, Row} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import SpeciesTable from "./SpeciesTable";
import {
    TYPE_INVASIVE_COVER,
    TYPE_LENGTH_OF_COVER,
    TYPE_STEM_TALLY,
    TYPE_TREE_DBH,
} from "../../../Constants/lengthCategories";
import {selectProjectById} from "../../../Redux/Selectors/nodeSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {PLOT_TYPE_1, PLOT_TYPE_2} from "../../../Constants/hydrozones";
import {
    TRANSECT_REVIEW_CODE_DBH,
    TRANSECT_REVIEW_CODE_INVASIVE_COVER,
    TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../../../Constants/transectReview";
import {TransectReviewConfirmButton} from "./TransectReviewConfirmButton";


const HydrozoneSpeciesReviewPage = props => {
    const { transectId, type } = useParams();
    
    useTransectReviewNav(transectId);

    const transect = useSelector(state => selectTransect(state, transectId));
    const project = useSelector(state => selectProjectById(state, transect.projectId));

    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);

    const [group, setGroup] = useState(false);
    const [subtitle, setSubtitle] = useState(null);
    

    useEffect(() => {
        switch (type) {
            case TRANSECT_REVIEW_CODE_STEM_LENGTH:
                setSubtitle('Plot 1 & Plot 2');
                setGroup(0);
                break;
            case TRANSECT_REVIEW_CODE_DBH:
                setSubtitle('Tree DBH');
                setGroup(1);
                break;
            case TRANSECT_REVIEW_CODE_INVASIVE_COVER:
                setSubtitle('Invasive + Ground Cover');
                setGroup(0);
                break;
        }
    }, [type]);

    const renderTable = () => {
        switch (type) {
            case 'stem-length':
                return (
                    <Fragment>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_1}
                                type={TYPE_STEM_TALLY}
                                group={group}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_1}
                                type={TYPE_LENGTH_OF_COVER}
                                group={group}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_2}
                                type={TYPE_STEM_TALLY}
                                group={group}
                                show={!transect.hasNoPlot2}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_2}
                                type={TYPE_LENGTH_OF_COVER}
                                group={group}
                                show={!transect.hasNoPlot2}
                            />
                        </Col>
                    </Fragment>
                );
            case 'dbh':
                return (
                    <Fragment>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_1}
                                type={TYPE_TREE_DBH}
                                group={group}
                            />
                        </Col>
                        <Col xs={12}>
                            <SpeciesTable
                                species={project.Species}
                                transectId={transect.transectId}
                                plotTypeId={PLOT_TYPE_2}
                                type={TYPE_TREE_DBH}
                                group={group}
                                show={!transect.hasNoPlot2}
                            />
                        </Col>
                    </Fragment>
                );
            default:
                return (
                    <Col xs={12}>
                        <SpeciesTable
                            species={project.Species}
                            transectId={transect.transectId}
                            type={TYPE_INVASIVE_COVER}
                            group={group}
                        />
                    </Col>
                );
        }
    };

    return (
        <PageContainer className="plot-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle={subtitle}
                    action={
                        <div className="group-by-hydrozone-field">
                            <FormCheck
                                id="group-by-hydrozone"
                                label="Group All Hydrozones"
                                type="checkbox"
                                value={String(group)}
                                checked={group}
                                onChange={() => setGroup(!group)}
                            />
                        </div>
                    }
                />
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    {renderTable()}
                </Row>
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <TransectReviewConfirmButton
                        transect={transect}
                        reviewCode={type}
                        disabled={finalized}
                    />
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default HydrozoneSpeciesReviewPage;
