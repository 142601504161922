import {
    HYDROZONE_TYPE_BANK,
    HYDROZONE_TYPE_OVERBANK,
    HYDROZONE_TYPE_TOE,
    HYDROZONE_TYPE_TRANSITION,
    HYDROZONE_TYPE_UPLAND
} from "../Constants/hydrozones";
import {
    hasNoPlantsLabels,
    TYPE_INVASIVE_COVER,
    TYPE_LENGTH_OF_COVER,
    TYPE_STEM_TALLY,
    TYPE_TREE_DBH
} from "../Constants/lengthCategories";
import {useSelector} from "react-redux";
import {selectTransectHydrozonePlotsByPlotTypeId} from "../Redux/Selectors/transectSelectors";
import LengthCategoryUtils from "../Utils/LengthCategoryUtils";
import React from "react";

export const useHydrozoneName = () => {
    return (hydrozoneId) => {
        switch (Number(hydrozoneId)) {
            case HYDROZONE_TYPE_UPLAND:
                return 'Upland';
            case HYDROZONE_TYPE_TRANSITION:
                return 'Transition';
            case HYDROZONE_TYPE_OVERBANK:
                return 'Overbank';
            case HYDROZONE_TYPE_BANK:
                return 'Bank';
            case HYDROZONE_TYPE_TOE:
                return 'Toe';
            default:
                return '';
        }
    }
};

export const getQuantToolName = (type) => {
    switch (type) {
        case TYPE_STEM_TALLY:
            return 'Stem Tally';
        case TYPE_LENGTH_OF_COVER:
            return 'Length of Cover';
        case TYPE_TREE_DBH:
            return 'Tree DBH';
        case TYPE_INVASIVE_COVER:
    }
}

export const useGetUserFacingSpeciesName = () => {
    return (species) => {
        if (!species) {
            return null;
        }

        const commonName = species?.alternativeCommonName ?? species.commonName;
        const latinName = species?.alternativeLatinName ?? species.latinName;

        if (commonName === latinName) {
            return species.commonName;
        }

        return `${commonName}${latinName ? ` (${latinName})` : ''}`;
    };
};

export const useGetSpeciesById = () => {
    return (speciesList, speciesId) => {
        if (!speciesList || !speciesId) {
            return null;
        }

        const species = speciesList.find(x => String(x.speciesId) === String(speciesId));
        return species;
    };
}

export const useGetUserFacingSpeciesNameById = () => {
    const getSpeciesById = useGetSpeciesById();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    return (speciesList, speciesId) => {
        const species = getSpeciesById(speciesList, speciesId);
        return getUserFacingSpeciesName(species);
    };
};

export const useSpeciesIdByUserFacingName = () => {
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    return (speciesList, speciesName) => {
        if (!speciesList || !speciesName) {
            return null;
        }
        
        const species = speciesList.find(x => String(getUserFacingSpeciesName(x)) === speciesName);
        return species?.speciesId;
    };
};

export const formatNotesLabel = (notesValue) => {
    return ((notesValue ?? '') === '') ? 'N/A' : notesValue;
}

export const getHydrozonePlotTitle = (type, plotTypeId) => {
    if(type === TYPE_INVASIVE_COVER) {
        return null;
    }
    return `${getQuantToolName(type)} - Plot ${plotTypeId}`;
}

export const getHydrozoneSpeciesFieldName = (type) => {
    return type === TYPE_INVASIVE_COVER ? 'HydrozoneGroundCoverSpecies' : 'HydrozonePlotSpecies'
}

export const useGetHydrozonePlotEmptyMessage = (transectId, type, plotTypeId) => {
    const hydrozoneSpeciesType = getHydrozoneSpeciesFieldName(type);
    const hydrozonePlots = useSelector(state => selectTransectHydrozonePlotsByPlotTypeId(state, transectId, plotTypeId));
    
    return (hydrozone) => {
        const hydrozoneSpeciesCount = hydrozone[hydrozoneSpeciesType].length;
        const hydrozonePlotExists = hydrozonePlots.some(hydrozonePlot => String(hydrozonePlot.hydrozoneTypeId) === String(hydrozone.hydrozoneTypeId));
        const noPlantsValue = LengthCategoryUtils.GetNoPlantsValue(hydrozone, type, plotTypeId);
        
        if (noPlantsValue) {
            return "No " + hasNoPlantsLabels[type] + " to measure in this hydrozone";
        } else if (plotTypeId != null && !hydrozonePlotExists) {
            return "Plot does not intersect this hydrozone.";
        } else if (hydrozoneSpeciesCount === 0) {
            return <span className="no-data-message">No Data</span>;
        }
        return null;
    }
}

export const useGetTransectEmptyMessage = (transectId, type, plotTypeId) => {
    const hydrozoneSpeciesType = getHydrozoneSpeciesFieldName(type);
    const hydrozonePlots = useSelector(state => selectTransectHydrozonePlotsByPlotTypeId(state, transectId, plotTypeId));
    
    return (hydrozones) => {
        
        const validHydrozones = hydrozones.filter(hydrozone => 
            hydrozonePlots.some(hydrozonePlot => String(hydrozonePlot.hydrozoneTypeId) === String(hydrozone.hydrozoneTypeId))
        );
        
        const speciesCount = validHydrozones.reduce( (acc, hydrozone) => acc + (hydrozone?.[hydrozoneSpeciesType]?.length ?? 0), 0);
        const noPlantsValue = validHydrozones.every(hydrozone => LengthCategoryUtils.GetNoPlantsValue(hydrozone, type, plotTypeId))
        
        if (noPlantsValue) {
            return "No " + hasNoPlantsLabels[type] + " to measure in this transect";
        } else if (speciesCount === 0) {
            return <span className="no-data-message">No Data</span>;
        }
        return null;
    }
}

export const useGetHydrozonePlotLink = (transectId, type, plotTypeId) => {
    const hydrozonePlots = useSelector(state => selectTransectHydrozonePlotsByPlotTypeId(state, transectId, plotTypeId));
    
    return (hydrozoneTypeId) => {
        const hydrozonePlotExists = hydrozonePlots.some(hydrozonePlot => String(hydrozonePlot.hydrozoneTypeId) === String(hydrozoneTypeId));
        if (!transectId || !type || !hydrozoneTypeId || !hydrozonePlotExists) {
            return null;
        }
        
        const plotSegment = plotTypeId ? `plot${plotTypeId}/` : '';
        return `/transect/${transectId}/${plotSegment}${type}/${hydrozoneTypeId}`
    }
};
