/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React from "react";

export const useForceReload = () => {
	return (wait = 900) => {
		setTimeout(() => {
			window.location.reload();
		}, wait);
	}
}

export const useSkipWaiting = () => {
	return () => {
		return navigator?.serviceWorker?.getRegistration()
			.then( registration => {
				return registration.waiting?.postMessage({type: 'SKIP_WAITING'});
			});
	};
}
