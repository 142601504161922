import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import {UPSERT_PHOTO} from "../../../Redux/Actions/Types/offlineDataActionTypes";

import PageContent from '../../Layout/Page/PageContent';
import PageContainer from '../../Layout/Page/PageContainer';
import ToolSidebar from "./ToolSidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfirmDeleteModal from "../../Common/ConfirmDeleteModal";
import {useStoreNewPhoto} from "../../../Hooks/PhotoHooks";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {
    selectSampleLocationAndPhotoPointByPhotoPointId,
    selectVisiblePhotoById
} from "../../../Redux/Selectors/photoSelectors";
import {useQueryParams} from "../../../Hooks/RouteHooks";
import useDeviceOrientationLock from "../../../Hooks/DeviceOrientationHooks";

const PhotoToolPage = (props) => {
    const captureRef = useRef(null);
    const fileRef = useRef(null);
    const videoRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const storeNewPhoto = useStoreNewPhoto();
    useDeviceOrientationLock('landscape');
    
    const {sampleEventId, photoPointId, previousPhotoId} = useQueryParams();
    const event = useSelector(state => selectSampleEventById(state, sampleEventId));
    const {transect, photoPoint} = useSelector(state => selectSampleLocationAndPhotoPointByPhotoPointId(state, photoPointId, sampleEventId));
    const previousPhoto = useSelector(state => selectVisiblePhotoById(state, previousPhotoId));

    // these values based on the default photo resolution
    // on an iPad (Gen 8)
    const defaultPhotoWidth = 3264; // previously: 2176;
    const defaultPhotoHeight = 2448; // previously: 1632;

    const user = useSelector(state => state.userState.user);

    const [cameraStream, setCameraStream] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [showGrid, setShowGrid] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [cameraMetadata, setCameraMetadata] = useState(null);
    const [cameraCaptureErrorMessage, setCameraCaptureErrorMessage] = useState((navigator?.mediaDevices?.getUserMedia == null) ? "Inline camera not supported." : null);
    const [takingPhoto, setTakingPhoto] = useState(false);
    const [flash, setFlash] = useState(false);
    
    const deactivateCamera = () => {
        cameraStream?.getTracks?.()?.forEach(track => {
            track.stop();
        });
    };
    
    useEffect(() => {
        if(!cameraStream) {
            void activateCamera();
        }
        return deactivateCamera;
    }, [cameraStream]);
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    useEffect(() => {
        if (flash) {
            setTimeout(() => {
                setFlash(false);
            }, 1000);
        }
    }, [flash]);

    const activateCamera = async () => {
        try {
            const video = videoRef.current;
            const stream = await (navigator?.mediaDevices?.getUserMedia({
                        audio: false,
                        video: {
                            width: defaultPhotoWidth,
                            height: defaultPhotoHeight,
                            facingMode: 'environment',
                        },
                    }));
            if (video && stream) {
                video.srcObject = stream;
                video.onloadedmetadata = async (event) => {
                    try {
                        handleResize(event.target);
                        await event.target?.play();
                        setCameraStream(stream);
                        setCameraCaptureErrorMessage(null);
                    } catch (error) {
                        console.log(error);
                        if (error.name === "AbortError") {
                            setCameraCaptureErrorMessage('Camera unavailable. Restart the iPad if this error persists.');
                        } else {
                            setCameraCaptureErrorMessage(error?.message ?? 'Unknown camera error. Please restart the iPad.');
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (error.name === "NotFoundError" ||
                error.name === "DevicesNotFoundError") {
                //required track is missing
                setCameraCaptureErrorMessage("Camera not available.");
            } else if (error.name === "NotReadableError" ||
                error.name === "TrackStartError") {
                //webcam or mic are already in use
                setCameraCaptureErrorMessage("Camera already in use.");
            } else if (error.name === "OverconstrainedError" ||
                error.name === "ConstraintNotSatisfiedError") {
                //constraints can not be satisfied by avb. devices
                setCameraCaptureErrorMessage("Insufficient camera capabilities.");
            } else if (error.name === "NotAllowedError" ||
                error.name === "PermissionDeniedError" ||
                error.name === "SecurityError") {
                //permission denied in browser
                setCameraCaptureErrorMessage("Camera permission was denied.");
            } else if (error.name === "TypeError") {
                //empty constraints object
                setCameraCaptureErrorMessage("Empty camera constraints.");
            } else if (error.name === "AbortError") {
                //empty constraints object
                setCameraCaptureErrorMessage("A problem occurred which prevented the camera from being used.");
            } else {
                //other errors
                setCameraCaptureErrorMessage("Inline camera not available.");
            }
        } finally {
            deactivateCamera();
        }
    }

    // called after the video stream begins
    // or after the screen size changes
    const handleResize = (videoArgument) => {
        const video =  videoArgument ?? videoRef.current;
        setCameraMetadata({
            videoHeight: video?.videoHeight ?? null,
            videoWidth: video?.videoWidth ?? null,
        });
    };
     
    const releaseCanvas = (canvas) => {
        canvas.width = 1;
        canvas.height = 1;
        canvas.getContext('2d')?.clearRect(0, 0, 1, 1);
    }

    const takePhoto = () => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        if (takingPhoto || !canvas || !video) return;
        
        setTakingPhoto(true);
        setFlash(true);
        
        const context = canvas.getContext('2d');
        video.pause();
        
        const width = (cameraMetadata?.videoWidth ?? defaultPhotoWidth);
        const height = (cameraMetadata?.videoHeight ?? defaultPhotoHeight);
        //const pixelRatio = (window.devicePixelRatio ?? 1);
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
            setPhoto(blob);
        }, 'image/jpeg')
        releaseCanvas(canvas)
        setTakingPhoto(false);
    };

    const retakePhoto = () => {
        void activateCamera();
        setShowDiscard(false);
        setPhoto(null);
    };

    const iOSTakePhoto = (e) => {
        if(e?.target?.files?.[0] instanceof Blob) {
            setPhoto(e.target.files[0]);
        }
    };

    const save = async () => {
        const newPhoto = {
            ...(await storeNewPhoto(photo)),
            organizationId: user.organizationId,
            photoPointId: photoPoint.photoPointId,
            sampleEventId: event.sampleEventId,
            sampleEventName: event.sampleEventPhaseName,
            sampleEventStartDate: event.startDate,
            notes: null,
            description: null,
        };

        dispatch({type: UPSERT_PHOTO, photo: newPhoto});
        history.goBack();
    };

    const renderGrid = () => {
        return (
            <table className="table-grid">
                <tbody>
                    {[...Array(4)].map((_, y) => (
                        <tr key={y}>
                            {[...Array(4)].map((_, x) => (
                                <td key={x}></td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    };

    const renderCameraCapturePhotoElement = () => {
        if (!photo && cameraCaptureErrorMessage) {
            return <div className="photo no-photo">
                <div className="no-photo-message">
                    {cameraCaptureErrorMessage}<br/><br/>
                    <FontAwesomeIcon icon={['fal', 'camera']} />
                    <Button className="btn-photo" onClick={() => fileRef.current.click()}>
                        Choose Photo
                    </Button>
                </div>
            </div>
        }
        
        return <div className={`photo ${showGrid ? 'grid-on' : ''} ${(!photo && !cameraCaptureErrorMessage && !takingPhoto) ? 'no-photo' : '' }`}>
            {photo && flash && <div className="flash-area" />}
            {showGrid && renderGrid()}
            { photo ? <img id="photo" src={URL.createObjectURL(photo)}/> : null}
            <video ref={videoRef} id="video" muted />
            <canvas id="canvas" className="d-none" />
        </div>;
    };

    return (
        <Fragment>
            <ConfirmDeleteModal
                show={showDiscard}
                onHide={() => setShowDiscard(false)}
                delete={() => retakePhoto()}
                itemMessage="the current photo"
                discard
            />
            <PageContainer
                className="photo-tool-page"
                sidebar={
                    <ToolSidebar
                        takePhoto={() => takePhoto()}
                        retakePhoto={() => {
                            if (takingPhoto) return;
                            setShowDiscard(true);
                        }}
                        iOSTakePhoto={(e) => iOSTakePhoto(e)}
                        photo={!!photo}
                        toggleGrid={() => setShowGrid(!showGrid)}
                        showGrid={showGrid}
                        captureRef={captureRef}
                        fileRef={fileRef}
                    />
                }
                noHeader
            >
                <PageContent>
                    <div className="content">
                        <Row xs={12}>
                            <Col xs={12} className="header">
                                {
                                    transect ?
                                        <Fragment>
                                            <span>
                                                <FontAwesomeIcon icon={['fal', 'camera']} />
                                                {photoPoint.photoDirection}
                                            </span>
                                        </Fragment> :
                                        <Fragment>
                                            <span>
                                                <FontAwesomeIcon icon={['fal', 'camera']} />
                                                {photoPoint.name}
                                            </span>
                                            <span className="ml-4">
                                                <FontAwesomeIcon icon={['fal', 'camera']} />
                                                {photoPoint.photoDirection} {photoPoint.compassBearing}&#176;
                                            </span>
                                        </Fragment>
                                }
                            </Col>
                            <Col xs={6}>
                                <div className="photo-container reference-photo">
                                    {
                                        (previousPhoto?.src ?? previousPhoto?.thumbnailSrc) ?
                                            <Fragment>
                                                <span className="photo-title">{previousPhoto?.sampleEventName}</span>
                                                <div className={`photo ${showGrid ? 'grid-on' : ''}`}>
                                                    {showGrid && renderGrid()}
                                                    <img src={previousPhoto.src ?? previousPhoto?.thumbnailSrc} />
                                                </div>
                                            </Fragment> :
                                            <Fragment>
                                                <span className="photo-title" />
                                                <div className="photo no-photo">
                                                    <div className="no-photo-message">
                                                        <FontAwesomeIcon icon={['fal', 'file-image']} />
                                                        No Previous Photo
                                                    </div>
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="photo-container main">
                                    <span className="photo-title">{event.name}</span>
                                    {renderCameraCapturePhotoElement()}

                                    {
                                        photo &&
                                        <div className="photo-actions">
                                            <Button variant="complete" onClick={() => save()}>
                                                <FontAwesomeIcon icon={['fal', 'save']} />
                                                Save Photo
                                            </Button>
                                            <Button variant="action" onClick={() => setShowDiscard(true)}>
                                                <FontAwesomeIcon icon={['fas', 'redo']} transform="flip-h" />
                                                Retake
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </PageContent>
            </PageContainer>
        </Fragment>
    );
};

export default PhotoToolPage;
