export default class GeoPositionUtils {
    static defaultErrorHandler(error) { 
        console.log(this.getDefaultErrorMessage(error));
    }
    
    static getDefaultErrorMessage(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                return 'This feature requires the user to grant location permission(s).';
                
            case error.POSITION_UNAVAILABLE:
                return "The current geolocation is unavailable.";
            
            case error.TIMEOUT:
                return "The geolocation request timed out. Please try again.";
            default:
                return "An unknown error occurred while retrieving the geolocation.";
        }
    }
    
    static getCurrentPosition(success, failure = null, options = {}) {
        options = {
            maximumAge: Infinity,
            enableHighAccuracy: true,
            timeout: 5000,
            ...options
        }
        
        failure = failure ?? this.defaultErrorHandler;

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success, failure, options);
        } else {
            alert('Location services are unavailable on this device')
        }
    }
}
