export const SET_CONNECTION = 'appStatus/SET_CONNECTION';
export const SET_OFFLINE_MODE = 'appStatus/SET_OFFLINE_MODE';
export const SET_PARTIAL_LOGIN = 'appStatus/SET_PARTIAL_LOGIN';
export const ACCEPT_TERMS = 'appStatus/ACCEPT_TERMS';
export const INCREMENT_TEMP_ID = 'appStatus/INCREMENT_TEMP_ID';
export const TOGGLE_ACCOUNT_MENU = "appStatus/TOGGLE_ACCOUNT_MENU";
export const CLOSE_ACCOUNT_MENU = "appStatus/CLOSE_ACCOUNT_MENU";
export const SET_QUICK_NAV_LINKS = "appStatus/SET_QUICK_NAV_LINKS";
export const TOGGLE_QUICK_NAV = "appStatus/TOGGLE_QUICK_NAV";
export const CLOSE_QUICK_NAV = "appStatus/CLOSE_QUICK_NAV";
export const OPEN_TERMS = "appStatus/OPEN_TERMS";
export const CLOSE_TERMS = "appStatus/CLOSE_TERMS";
export const OPEN_BACKUP = "appStatus/OPEN_BACKUP";
export const CLOSE_BACKUP = "appStatus/CLOSE_BACKUP";
export const OPEN_UPDATE_MODAL = "appStatus/OPEN_UPDATE_MODAL";
export const CLOSE_UPDATE_MODAL = "appStatus/CLOSE_UPDATE_MODAL";
export const SET_BREADCRUMBS = "appStatus/SET_BREADCRUMBS";
export const SET_CONTEXT_TABS = "appStatus/SET_CONTEXT_TABS";
export const SET_PROCEDURES = "appStatus/SET_PROCEDURES";
export const SET_PROJECT_MAP = "appStatus/SET_PROJECT_MAP";
export const SET_RETURN_PATH = "appStatus/SET_RETURN_PATH";
export const SET_HIDE_DASHBOARD_SIDEBAR = "appStatus/SET_HIDE_DASHBOARD_SIDEBAR";
export const CLEAR_RETURN_PATH = "appStatus/CLEAR_RETURN_PATH";

