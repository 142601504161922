/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {quantTools} from "../../Constants/tools";
import {
	TRANSECT_REVIEW_CODE_CANOPY,
	TRANSECT_REVIEW_CODE_DBH,
	TRANSECT_REVIEW_CODE_INSTREAM,
	TRANSECT_REVIEW_CODE_INVASIVE_COVER,
	TRANSECT_REVIEW_CODE_NARRATIVE,
	TRANSECT_REVIEW_CODE_PHOTOS,
	TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../../Constants/transectReview";
import {
	PHOTO_POINT_DOWNSTREAM,
	PHOTO_POINT_INSTREAM_SHADE,
	PHOTO_POINT_TYPE_END,
	PHOTO_POINT_TYPE_ORIGIN,
	PHOTO_POINT_UPSTREAM
} from "../../Constants/photos";
import {
	categoriesByType,
	hasNoPlantsFieldNames,
	lengthOfCoverCategories,
	stemTallyCategories,
	treeDBHCategories,
	TYPE_LENGTH_OF_COVER,
	TYPE_STEM_TALLY, 
	TYPE_TREE_DBH
} from "../../Constants/lengthCategories";
import {createSelector} from "reselect";
import {selectAvailableSampleEvents} from "./nodeSelectors";
import {selectTransectsWithPlots} from "./transectSelectors";
import {selectPhotos} from "./photoSelectors";

const toolIsComplete = (transect, code) => transect?.TransectReviews?.find(review => review.code === code)?.isConfirmed ?? false;

const getTransectPhotoProgress = (transect, photos, photoPointTypes) => {
	const photoPointIds = transect.PhotoPoints
		.filter(point => photoPointTypes.includes(point.name))
		.map(point => point.photoPointId);
	
	const progressNumerator = photos.filter(photo => (
		photoPointIds.includes(photo.photoPointId) && String(photo.sampleEventId) === String(transect.sampleEventId)
	)).length;
	const progressDenominator = photoPointIds.length;
	
	return [
		progressNumerator,
		progressDenominator
	]
}

const getObjectProgressByFields = (obj, fields, allowEmptyString = false) => {
	let progressNumerator = 0;
	
	if (fields.length) {
		for (let field of fields) {
			const data = obj[field];
			
			// one point for each non-null, non-empty value
			if (data?.length || (data !== '' && data !== null && data !== undefined)) {
				progressNumerator++;
			}
			
			// TransectNarrative counts empty strings as a point
			if (allowEmptyString && data === '') {
				progressNumerator++;
			}
		}
	}
	return [progressNumerator, fields.length];
}

const getTransectNarrativeProgress = (transect) => {
	
	if (!transect?.TransectNarrative) {
		return [0, 1];
	}
	
	const transectNarrativeFields = [
		'speciesProtected',
		'competingVegetationNotes',
		'wildlifeSightings',
		'managementRecommendations',
		'TransectNarrativeIrrigation',
		'TransectNarrativeBrowse',
		'TransectNarrativeVigor'
	]
	
	let [progressNumerator, progressDenominator] = getObjectProgressByFields(
		transect?.TransectNarrative ?? {},
		transectNarrativeFields,
		true
	);
	
	return [progressNumerator, progressDenominator];
}


const getTransectCanopyClosureProgress = (transect) => {
	const canopyClosureFields = [
		'canopyClosure',
		'north',
		'east',
		'south',
		'west',
	];
	
	if (!transect?.TransectCanopyClosure) {
		return [0, 1];
	}
	
	let [progressNumerator, progressDenominator] = getObjectProgressByFields(transect.TransectCanopyClosure ?? {}, canopyClosureFields)
	
	// conditionally count the measurement location since it is auto-populated
	// but could also be set to null manually.
	if((transect.TransectCanopyClosure?.measurementLocation ?? '') !== '' && progressNumerator > 0) {
		progressNumerator++;
	}
	
	return [progressNumerator, progressDenominator + 1];
};

export const getTransectToolProgress = (transect, tool, photos) => {
	let progressNumerator = 0,
		progressDenominator = 0,
		isCompleted = false;
	
	if(transect.isActive) {
		switch (tool.code) {
			case 'transect-narrative':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_NARRATIVE);
				[progressNumerator, progressDenominator] = getTransectNarrativeProgress(transect);
				break;
			case 'plot1':
			case 'plot2':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_STEM_LENGTH) && toolIsComplete(transect, TRANSECT_REVIEW_CODE_DBH);
				[progressNumerator, progressDenominator] = getTransectPlotProgress(
					transect,
					tool.code === 'plot1' ? 1 : 2
				);
				break;
			case 'invasive-cover':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_INVASIVE_COVER);
				[progressNumerator, progressDenominator] = getTransectInvasiveCoverProgress(transect);
				break;
			case 'canopy':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_CANOPY);
				[progressNumerator, progressDenominator] = getTransectCanopyClosureProgress(transect);
				break;
			case 'origin-end-photo':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_PHOTOS);
				[progressNumerator, progressDenominator] = getTransectPhotoProgress(
					transect,
					photos,
					[PHOTO_POINT_TYPE_ORIGIN, PHOTO_POINT_TYPE_END]
				);
				break;
			case 'instream-shade':
				isCompleted = toolIsComplete(transect, TRANSECT_REVIEW_CODE_INSTREAM);
				[progressNumerator, progressDenominator] = getTransectPhotoProgress(
					transect,
					photos,
					[PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_UPSTREAM, PHOTO_POINT_DOWNSTREAM]
				);
				break;
		}
	}
	
	let progress = progressNumerator / progressDenominator * 100;
	
	if (isCompleted) {
		progressNumerator = progressDenominator;
		progress = 100;
	}
	
	return {
		...tool,
		progress,
		progressNumerator,
		progressDenominator,
		progressComplete: progress === 100,
		isCompleted,
	}
}

export const transectWithProgress = (transect, photos) => {
	// TODO: replace this with selectTransectWithProgress
	const toolProgress = getTransectToolsProgress(transect, photos);
	const progressNumerator = toolProgress.reduce((acc, curr) => acc + curr.progressNumerator, 0);
	const progressDenominator = toolProgress.reduce((acc, curr) => acc + curr.progressDenominator, 0);
	const isCompleted = toolProgress.every(progress => progress.isCompleted);
	
	return {
		...transect,
		toolProgress: toolProgress,
		progress: progressNumerator / progressDenominator * 100,
		progressNumerator,
		progressDenominator,
		isCompleted
	}
}

const transectsWithProgress = (transects, photos) => {
	return transects?.map(transect => transectWithProgress(transect, photos)) ?? []
}

export const selectSampleEventTransectsWithProgress = createSelector(
	[selectAvailableSampleEvents, selectTransectsWithPlots, selectPhotos],
	(sampleEvents, transects, photos) => {
		
		return Object.fromEntries(
			sampleEvents?.map((sampleEvent) => {
				const sampleEventTransects = transects.filter(transect => String(transect.sampleEventId) === String(sampleEvent.sampleEventId));
				return [
					sampleEvent.sampleEventId,
					transectsWithProgress(
						sampleEventTransects,
						photos
					)
				];
			}) ?? []
		);
	}
);

export const selectTransectsWithProgressBySampleEventId = createSelector(
	[selectTransectsWithPlots, selectPhotos, (state, sampleEventId) => sampleEventId],
	(transects, photos, sampleEventId) => {
		return transectsWithProgress(transects, photos)
			.filter(transect => 
				String(transect.sampleEventId) === String(sampleEventId)
			)
	}
);

export const selectTransectsWithProgress = createSelector(
	[selectTransectsWithPlots, selectPhotos],
	(transects, photos) => {
		return transectsWithProgress(transects, photos)
	}
);

export const selectTransectWithProgressByTransectId = createSelector(
	[selectTransectsWithProgress, ((_, transectId) => transectId)],
	(transectsWithProgress, transectId) => {
		return transectsWithProgress.find(
			transect => String(transect.transectId) === String(transectId)
		);
	}
);

const getHydrozonePlotSpeciesProgressByCategories = (hydrozonePlotSpecies, categories) => {
	
	const hasSomeNonZeroData = categories.some(category => (
		Number(hydrozonePlotSpecies?.[category.code]) > 0
	));
	
	const hasSomeEmptyData = categories.some(category => (
		(hydrozonePlotSpecies?.[category.code] ?? '') === ''
	))
	
	return [(hasSomeNonZeroData && !hasSomeEmptyData), 1];
}

const progressFromItems = (items) => {
	return [
		items.reduce((acc, curr) => acc + (curr ? 1 : 0), 0),
		items.length,
	]
}

const getTransectHydrozoneGroundCoverProgress = (hydrozone) => {
	return progressFromItems([
		((hydrozone.leftQuadratStart ?? '') !== '') && ((hydrozone.rightQuadratStart ?? '') !== ''),
		hydrozoneGroundCoverSpeciesSum(hydrozone.HydrozoneGroundCoverSpecies, 'leftCover') >= 100,
		hydrozoneGroundCoverSpeciesSum(hydrozone.HydrozoneGroundCoverSpecies, 'rightCover') >= 100,
	]);
}

const getTransectInvasiveCoverProgress = (transect) => {
	return transect.Hydrozones.reduce(([carryNumerator, carryDenominator], hydrozone) => {
		const [itemNumerator, itemDenominator] = getTransectHydrozoneGroundCoverProgress(hydrozone);
		return [carryNumerator + itemNumerator, carryDenominator + itemDenominator];
	}, [0,0])
}

export const hydrozoneGroundCoverSpeciesSum = (hydrozoneGroundCoverSpecies, categoryCode) => {
	return hydrozoneGroundCoverSpecies.reduce((acc, hydrozoneSpecies) => (
		acc + Number(hydrozoneSpecies?.[categoryCode] ?? 0)
	), 0);
};

/**
 *
 * @param transect
 * @param photos
 * @returns {{[p: string]: *&{progressDenominator: number, progress: number, progressNumerator: number, progressComplete: boolean, isCompleted: boolean}}}
 */
export const getTransectToolsProgress = (transect, photos) => {
	return quantTools.map(tool => getTransectToolProgress(transect, tool, photos));
};

function getTransectPlotProgress(transect, plotTypeId) {
	
	const hydrozonePlots = transect.HydrozonePlots.filter(hydrozonePlot =>
		Number(hydrozonePlot.plotTypeId) === plotTypeId
	);
	
	// not all hydrozones have plots, and we should only
	// count the ones with plots in the number
	const hydrozonesWithPlots = transect.Hydrozones.filter(hydrozone =>
		hydrozonePlots.some(hydrozonePlot => hydrozonePlot.hydrozoneId === hydrozone.hydrozoneId)
	);
	
	return hydrozonesWithPlots.reduce(
		([carryNumerator, carryDenominator], hydrozone) => {
			const [itemNumerator, itemDenominator] = getTransectHydrozonePlotProgress(hydrozone, plotTypeId)
			return [carryNumerator + itemNumerator, carryDenominator + itemDenominator];
		},
		[0,0]
	);
}

const getTransectHydrozonePlotProgress = (hydrozone, plotTypeId) => {
	
	const progressableTypes = [
		TYPE_STEM_TALLY,
		TYPE_LENGTH_OF_COVER,
		// TYPE_TREE_DBH, // DBH is optional so we don't count it towards progress
	]
	
	return progressableTypes.reduce(
		([carryNumerator, carryDenominator], type) => {
			const [itemNumerator, itemDenominator] = getHydrozoneScreenProgress(hydrozone, plotTypeId, type)
			return [carryNumerator + itemNumerator, carryDenominator + itemDenominator];
		},
		[0,0]
	);
}

const getHydrozoneScreenProgress = (hydrozone, plotTypeId, type) => {
	
	// filter species for only the ones that exist in this HydrozonePlot + Type
	const filteredHydrozonePlotSpecies = hydrozone.HydrozonePlotSpecies.filter(
		hps => String(hps.plotTypeId) === String(plotTypeId)
	);
	
	// if "no plants to count" is checked, treat it as done
	const noPlantsFieldName = hasNoPlantsFieldNames[type] + String(plotTypeId ?? '');
	if (!!(hydrozone?.[noPlantsFieldName])) {
		// keep the same denominator so the checkbox doesn't alter our progress bar too much
		return [filteredHydrozonePlotSpecies.length, filteredHydrozonePlotSpecies.length];
	}
	
	const [progressNumerator, progressDenominator] = filteredHydrozonePlotSpecies.reduce(
		([carryNumerator, carryDenominator], hydrozonePlotSpecies) => {
			const [itemNumerator, itemDenominator] = getHydrozonePlotSpeciesProgressByCategories(
				{...hydrozonePlotSpecies, transectId: hydrozone.transectId},
				categoriesByType[type]
			);
			return [carryNumerator + itemNumerator, carryDenominator + itemDenominator];
		},
		[0,0]
	);
	return [progressNumerator, progressDenominator];
}

