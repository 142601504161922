import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextTools, useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';
import {CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE} from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import TileList from '../../Common/TileList';
import PageContainer from '../../Layout/Page/PageContainer';

import CameraPointRow from "./CameraPointRow";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import PageHeader from "../../Layout/Page/PageHeader";
import {UPSERT_CAMERA_POINT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import NotesModal from "../../Common/NotesModal";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {selectCameraPointsWithProgressBySampleEventId} from "../../../Redux/Selectors/cameraPointProgressSelectors";

const CameraPointsPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);

    const dispatch = useDispatch();
    const params = useParams();

    const event = useSelector(state => selectSampleEventById(state, params.eventId));
    const points = useSelector(state => selectCameraPointsWithProgressBySampleEventId(state, event.sampleEventId));
    
    const cameraPointTile = (cameraPoint) => ({
        ...cameraPoint,
        title: `Camera Point ${cameraPoint.name}`,
        subtitle: cameraPoint.syncConflict ? <span className="danger">- Conflict -</span> : cameraPoint?.subtitle,
        caption: cameraPoint.syncConflict ? <span className="font-italic">{cameraPoint?.syncConflict?.message}</span> : cameraPoint?.caption,
    });
    
    const cameraPointTiles = useMemo(() => (
            points
                ?.map(cameraPointTile)
                ?.sort((a, b) => Number(a.name) > Number(b.name) ? 1 : -1)
        ),
        [points]
    )

    useBreadcrumbs(null, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);

    const finalized = useFinalized(event.sampleEventId);
    useReadOnly(finalized);

    const [notes, setNotes] = useState([]);
    const [showNotes, setShowNotes] = useState(false);

    const showNotesForCameraPoint = (cameraPoint) => {
        setNotes([
            {
                label: 'Camera Point Notes',
                value: cameraPoint.notes,
                save: (value) => dispatch({type: UPSERT_CAMERA_POINT, cameraPoint: {...cameraPoint, notes: value}})
            },
        ]);

        setShowNotes(true);
    };

    return (
        <PageContainer className="camera-points-page">
            <NotesModal
                title="Notes"
                notes={notes}
                show={showNotes}
                onHide={() => {
                    setShowNotes(false);
                    setNotes([]);
                }}
                readOnly={finalized}
            />
            <PageHeader>
                <Title
                    title="Camera Points"
                    subtitle={`${event?.projectName} > ${event?.sampleEventPhaseName}`}
                    action={
                        <Link to={`/event/${event?.sampleEventId}/review`}>
                            <Button variant={event.isComplete ? "complete" : "primary"}>
                                {
                                    event.isComplete ?
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} /> :
                                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                }
                                Review & Finalize
                            </Button>
                        </Link>
                    }
                    readOnly={finalized}
                />
                <Link to={`/event/${event.sampleEventId}/photo/create`}>
                    <Button className="w-100" variant="secondary">
                        <FontAwesomeIcon icon={["fal", "plus"]} />
                        Add Camera Point
                    </Button>
                </Link>
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col>
                        <TileList
                            className="tile-list-camera-points"
                            colSize={3}
                            data={cameraPointTiles}
                            tileDetailComponent={CameraPointRow}
                            primaryKey="sampleLocationId"
                            actions={(item) => (
                                <Fragment>
                                    <Link to={`/event/${event.sampleEventId}/photo/${item.sampleLocationId}/point`}>
                                        <CircledIcon
                                            icon={['fal', 'camera']}
                                            circleSize="12"
                                            iconTransform="shrink-2"
                                        />
                                    </Link>
                                    <Link to={`/event/${event.sampleEventId}/photo/${item.sampleLocationId}`}>
                                        <CircledIcon
                                            icon={['fal', 'pen']}
                                            circleSize="12"
                                            iconTransform="shrink-4"
                                        />
                                    </Link>
                                    <CircledIcon
                                        icon={['fal', 'sticky-note']}
                                        circleSize="12"
                                        iconTransform="shrink-4 right-1"
                                        onClick={() => showNotesForCameraPoint(item)}
                                    />
                                </Fragment>
                            )}
                            noIcon
                        />
                    </Col>
                </Row>
            </PageContent>
        </PageContainer>
    );
};

export default CameraPointsPage;
