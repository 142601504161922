import React from 'react';
import {Button, Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PLOT_TYPE_1, PLOT_TYPE_2} from "../../../Constants/hydrozones";
import {useHydrozoneName, useGetUserFacingSpeciesNameById} from "../../../Hooks/LabelHooks";
import {PHOTO_POINT_TYPE_END, PHOTO_POINT_TYPE_ORIGIN} from "../../../Constants/photos";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {selectVisiblePhotos} from "../../../Redux/Selectors/photoSelectors";
import {usePreviousPhoto} from "../../../Hooks/PhotoHooks";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {selectAllSpecies} from "../../../Redux/Selectors/speciesSelectors";

const TransectRow = ({item}) => {
    const history = useHistory();
    const getPreviousPhoto = usePreviousPhoto();
    const getUserFacingSpeciesNameById = useGetUserFacingSpeciesNameById();

    const photos = useSelector(state => selectVisiblePhotos(state));
    const sampleEvent = useSelector(state => selectSampleEventById(state, item.sampleEventId));
    const species = useSelector(state => selectAllSpecies(state, sampleEvent.projectId));

    const getHydrozoneName = useHydrozoneName();

    const getPlotLocationByType = (type) => {
        const plot = item.TransectPlots.find(plot => (plot.plotTypeId === type));

        if (!plot) {
            return 'N/A';
        }

        return plot.start;
    };

    const getSortedHydrozones = () => {
        return item.Hydrozones.sort((a, b) => a.hydrozoneTypeId > b.hydrozoneTypeId ? 1 : -1);
    };

    const getSpeciesName = (type) => {
        if (item[`nearest${type}Species`]) {
            return item[`nearest${type}Species`];
        }

        if (item[`nearest${type}SpeciesId`]) {
            return getUserFacingSpeciesNameById(species, item[`nearest${type}SpeciesId`]) ?? 'N/A';
        }

        return 'N/A';
    };

    const renderPhotoButton = (name) => {
        let photo, photoPoint;
        let isPrevious = false;

        switch (name) {
            case 'Origin':
                photoPoint = item.PhotoPoints.find(point => point.name === PHOTO_POINT_TYPE_ORIGIN);
                break;
            case 'End':
                photoPoint = item.PhotoPoints.find(point => point.name === PHOTO_POINT_TYPE_END);
                break;
        }

        photo = photoPoint ? photos.find(photo => photo.sampleEventId === item.sampleEventId && photo.photoPointId === photoPoint.photoPointId) : null;

        if (!photo) {
            photo = getPreviousPhoto(sampleEvent.startDate, photoPoint.photoPointId);
            isPrevious = true;
        }

        return (
            <Button className="sb-allow-when-finalized" variant="action" disabled={!photo || !item.isActive} onClick={() => {
                history.push(`/photo/${photo?.photoId}${isPrevious ? '?isPrevious=1&readOnly=1' : ''}`);
            }}>
                <FontAwesomeIcon icon={['fal', 'camera']} />
                {name}
            </Button>
        )
    };

    return (
        <div className="transect-details">
            <Row xs={12}>
                <Col xs={3}>
                    <div>
                        <FormGroup>
                            <FormLabel>Transect Length</FormLabel>
                            <div>{item.length}</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Latitude</FormLabel>
                            <div>{item.latitude}&#176; N</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Longitude</FormLabel>
                            <div>{item.longitude}&#176; E</div>
                        </FormGroup>
                    </div>
                </Col>
                <Col xs={3}>
                    <div>
                        <FormGroup>
                            <FormLabel>Azimuth</FormLabel>
                            <div>{item.azimuth}</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Nearest Upstream</FormLabel>
                            <div>{getSpeciesName('Upstream')}</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Nearest Downstream</FormLabel>
                            <div>{getSpeciesName('Downstream')}</div>
                        </FormGroup>
                    </div>
                </Col>
                <Col xs={3}>
                    <div>
                        <FormGroup>
                            <FormLabel>Plot 1 Location</FormLabel>
                            <div>{getPlotLocationByType(PLOT_TYPE_1)}</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Plot 2 Location</FormLabel>
                            <div>{getPlotLocationByType(PLOT_TYPE_2)}</div>
                        </FormGroup>
                    </div>
                    <div>
                        <FormGroup>
                            <FormLabel>Notes</FormLabel>
                            <div className="transect-notes">{item.notes || 'N/A'}</div>
                        </FormGroup>
                    </div>
                </Col>
                <Col xs={3}>
                    <Row xs={12} className="hydrozones">
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Hydrozones</FormLabel>
                                {
                                    getSortedHydrozones().map(hydrozone => (
                                        <div key={hydrozone.hydrozoneTypeId} className="hydrozone-size">
                                            {getHydrozoneName(hydrozone.hydrozoneTypeId)}
                                        </div>
                                    ))
                                }
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Start / End</FormLabel>
                                {
                                    getSortedHydrozones().map(hydrozone => (
                                        <div key={hydrozone.hydrozoneTypeId} className="hydrozone-size">
                                            {hydrozone.start} / {hydrozone.end}
                                        </div>
                                    ))
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="photo-buttons">
                        {renderPhotoButton('Origin')}
                        {renderPhotoButton('End')}
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default TransectRow;
