import {TEMP_ID_PREFIX} from "../Constants/misc";
import {sortSampleEventsDesc} from "./SortUtils";

export default class EventUtils {

    /**
     * Fetches the most recent sampleEvent from the provided array of sampleEvents
     *
     * @param Object[] sampleEvents
     * @param Number|null projectId if not-null sampleEvents is filtered to only include events for this projectId
     * @param Number|null procedureId if not-null sampleEvents is filtered to only include events for this procedureId
     * @param Number[]|null excludingSampleEventIds if not-null sampleEvents is filtered to only include events for this procedureId
     * @returns {*}
     */
    static getMostRecentSampleEvent(sampleEvents, projectId = null, procedureId = null, excludingSampleEventIds = null) {
        const filteredEvents = sampleEvents
            .filter(event => (
                (projectId === null || event.projectId === projectId) &&
                (procedureId === null || event.procedureId === procedureId) &&
                (excludingSampleEventIds === null || !excludingSampleEventIds.includes(event.sampleEventId))
            ));

        const sortedSampleEvents = sortSampleEventsDesc(filteredEvents)
        console.log(sortedSampleEvents)
        return sortedSampleEvents.find(Boolean);
    }

    static isMostRecentSampleEvent(sampleEvents, sampleEvent) {
        const mostRecentSampleEvent = EventUtils.getMostRecentSampleEvent(
            sampleEvents,
            sampleEvent.projectId,
            sampleEvent.procedureId
        );
        return mostRecentSampleEvent && (sampleEvent.sampleEventId === mostRecentSampleEvent.sampleEventId);
    }
    
    // WARNING: this method only works for projects, projectPhases and sampleEvents
    // but that is all that gets uploaded
    static getParentProjectHierarchyIdByHierarchyId(sampleEvents, hierarchyId) {
        
        // NOTE: Projects can't be created and phases don't get a tmp id
        // so this only happens for SampleEvents
        if(hierarchyId.startsWith(TEMP_ID_PREFIX)) {
            const sampleEvent = sampleEvents.find(sampleEvent => sampleEvent.hierarchyId === hierarchyId);
            return String(sampleEvent.projectContextNodeId);
        }
        
        // all other cases are easy
        return String(hierarchyId).split('/').shift();
    }
}
