/*
 * StreamBank Monitoring v2.0.21
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useHistory, useParams} from 'react-router-dom'
import {Row, Col, Button, FormCheck, OverlayTrigger, Popover, FormControl} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useNextTempId} from "../../../Hooks/TempIdHooks";
import {
    hasInvalidField,
    useReadOnly,
    validateBetween,
    validateGreaterThan,
    validateGreaterThanOrEquals,
    validateLatitude,
    validateLessThan,
    validateLessThanOrEquals,
    validateLongitude,
    validateInteger,
    validateEqualTo,
} from "../../../Hooks/FormHooks";
import {UPSERT_TRANSECT} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import {
    HYDROZONE_TYPE_BANK,
    HYDROZONE_TYPE_OVERBANK, HYDROZONE_TYPE_TOE,
    HYDROZONE_TYPE_TRANSITION,
    HYDROZONE_TYPE_UPLAND, PLOT_TYPE_1, PLOT_TYPE_2
} from "../../../Constants/hydrozones";

import GeoPositionUtils from "../../../Utils/GeoPositionUtils";

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import FormInput from "../../Common/FormInput";
import HydrozoneRow from "./HydrozoneRow";
import PageHeader from "../../Layout/Page/PageHeader";
import CircledIcon from "../../CompositeIcons/CircledIcon";

import {
    useEmptyCanopyClosure,
    calculateCanopyClosureMeasurementLocation,
    useEmptyTransectPhotoPoints,
    useEmptyTransectReview,
    calculateCanopyClosureMeasurementLocationFromTransect
} from "../../../Hooks/DataCopyHooks";
import {
    useGetUserFacingSpeciesName,
    useSpeciesIdByUserFacingName, useGetSpeciesById
} from "../../../Hooks/LabelHooks";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import {useUpdateEffect} from "../../../Hooks/EffectHooks";

import SuggestedNoteButton from "../../Common/SuggestedNoteButton";
import SpeciesPickerModal from "../../Common/SpeciesPickerModal";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {OPEN_SPECIES_PICKER} from "../../../Redux/Actions/Types/transectActionTypes";
import RouteLeavingGuard from "../../Common/RouteLeavingGuard";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import HydrozoneUtils from "../../../Utils/HydrozoneUtils";
import ConfirmDeleteModal from "../../Common/ConfirmDeleteModal";
import {lengthOfCoverCategories, stemTallyCategories, treeDBHCategories} from "../../../Constants/lengthCategories";
import {LIST_NON_INVASIVE_WOODY} from "../../../Constants/species";
import SaveButtonWithHelpIcon from "../../Common/SaveButtonWithHelpIcon";
import DeactivateTransectModal from "./DeactivateTransectModal";
import ReactivateIcon from "../../CompositeIcons/ReactivateIcon";
import {
    TRANSECT_REVIEW_CODE_CANOPY,
    TRANSECT_REVIEW_CODE_DBH,
    TRANSECT_REVIEW_CODE_INVASIVE_COVER,
    TRANSECT_REVIEW_CODE_STEM_LENGTH
} from "../../../Constants/transectReview";
import {selectAllSpecies} from "../../../Redux/Selectors/speciesSelectors";
import GeoLocationButton from "../../Common/GeoLocationButton";


const TransectDetailPage = (props) => {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    const getSpeciesById = useGetSpeciesById();
    const getSpeciesIdByUserFacingName = useSpeciesIdByUserFacingName();
    const emptyCanopyClosure = useEmptyCanopyClosure();
    const emptyTransectReview = useEmptyTransectReview();
    const emptyPhotoPoints = useEmptyTransectPhotoPoints();
    
    const transectNotesTextArea = useRef()

    const user = useSelector(state => state.userState.user);
    const transect = params.transectId ? useSelector(state => selectTransect(state, params.transectId)) : null;
    const sampleEventId = transect?.sampleEventId ?? params.eventId;
    const sampleEvent = useSelector(state => selectSampleEventById(state, sampleEventId));
    const projectId = transect?.projectId ?? sampleEvent?.projectId;
    const breadcrumbTitle = transect ? 'Edit Transect' : 'New Transect';
    useBreadcrumbs(breadcrumbTitle, projectId, sampleEvent?.sampleEventId);
    useContextToolsBySampleEventId(sampleEvent?.sampleEventId ?? transect?.sampleEventId);

    const finalized = useFinalized(transect?.sampleEventId);
    useReadOnly(finalized);

    const [speciesPickerAction, setSpeciesPickerAction] = useState(null);
    const [name, setName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [accuracy, setAccuracy] = useState('');
    const [azimuth, setAzimuth] = useState('');
    const [nearestUpstreamSpecies, setNearestUpstreamSpecies] = useState(null);
    const [nearestDownstreamSpecies, setNearestDownstreamSpecies] = useState(null);
    
    const nearestUpstreamSpeciesName = getUserFacingSpeciesName(nearestUpstreamSpecies) ?? '';
    const nearestDownstreamSpeciesName = getUserFacingSpeciesName(nearestDownstreamSpecies) ?? '';

    const [originNotes, setOriginNotes] = useState('');
    const [safetyNotes, setSafetyNotes] = useState('');
    const [transectNotes, setTransectNotes] = useState('');

    const [isActive, setIsActive] = useState(true);
    const [createdBy, setCreatedBy] = useState(user.userId);

    const [uplandStart, setUplandStart] = useState(0);
    const [transitionStart, setTransitionStart] = useState(0);
    const [overbankStart, setOverbankStart] = useState(0);
    const [bankStart, setBankStart] = useState(0);
    const [toeStart, setToeStart] = useState(0);
    const [uplandActive, setUplandActive] = useState(false);
    const [transitionActive, setTransitionActive] = useState(false);
    const [overbankActive, setOverbankActive] = useState(false);
    const [bankActive, setBankActive] = useState(false);
    const [toeActive, setToeActive] = useState(false);

    const [transectLength, setTransectLength] = useState('');

    const [noRoom, setNoRoom] = useState(false);
    const [plot1Location, setPlot1Location] = useState('');
    const [plot1Length, setPlot1Length] = useState(20);
    const [plot1Width, setPlot1Width] = useState(20);
    const [plot2Location, setPlot2Location] = useState('');
    const [plot2Length, setPlot2Length] = useState(20);
    const [plot2Width, setPlot2Width] = useState(20);

    const [hasChanges, setHasChanges] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [invalidHydrozones, setInvalidHydrozones] = useState([]);
    const [invalidHydrozonePlots, setInvalidHydrozonePlots] = useState([]);
    const [invalidCanopy, setInvalidCanopy] = useState(false);
    
    const [showDeactivation, setShowDeactivation] = useState(false);

    const allSpecies = useSelector(state => selectAllSpecies(state, projectId));

    useEffect(() => {
        if (transect) {
            setName(transect.name ?? '');
            setLatitude(transect.latitude ?? '');
            setLongitude(transect.longitude ?? '');
            setAccuracy(transect.accuracy ?? '');
            setAzimuth(transect.azimuth ?? '');

            setNearestUpstreamSpecies(
                getSpeciesById(
                    allSpecies, 
                    transect.nearestUpstreamSpeciesId ?? getSpeciesIdByUserFacingName(allSpecies, transect.nearestUpstreamSpecies)
                )
            );
            setNearestDownstreamSpecies(
                getSpeciesById(
                    allSpecies,
                    transect.nearestDownstreamSpeciesId ?? getSpeciesIdByUserFacingName(allSpecies, transect.nearestDownstreamSpecies)
                )
            );
            
            setOriginNotes(transect.originNotes ?? '');
            setSafetyNotes(transect.safetyNotes ?? '');
            setIsActive(transect.isActive ?? true);
            setCreatedBy(transect.createdBy ?? user.userId);
            setTransectNotes(transect.notes ?? '');
            setTransectLength(transect.length ?? '');
            setNoRoom(transect.hasNoPlot2 ?? false);
            setHydrozones(transect);
            setPlots(transect);
        }
    }, []);

    useUpdateEffect(() => {
        if (initialized) {
            setHasChanges(true);
        }

        // Ignore first iteration because data is being set by other useEffect
        setInitialized(true);
    }, [
        name, latitude, longitude, accuracy, azimuth, nearestDownstreamSpecies, nearestUpstreamSpecies,
        originNotes, safetyNotes, transectNotes, isActive, createdBy, uplandStart, transitionStart,
        overbankStart, bankStart, toeStart, uplandActive, transitionActive, overbankActive, bankActive, toeActive,
        transectLength, noRoom, plot1Location, plot1Length, plot1Width, plot2Location, plot2Length, plot2Width
    ]);
    const [lastNotedPlot1, setLastNotedPlot1] = useState(null);
    const [lastNotedPlot2, setLastNotedPlot2] = useState(null);
    
    const addTransectNote = (message) => {
        setTransectNotes(`${transectNotes}\n${message}`);
        transectNotesTextArea.current?.setSelectionRange(transectNotes.length, transectNotes.length);
        transectNotesTextArea.current?.focus();
    }

    const setHydrozones = (transect) => {
        transect.Hydrozones.forEach(hydrozone => {
            switch (hydrozone.hydrozoneTypeId) {
                case HYDROZONE_TYPE_UPLAND:
                    setUplandStart(hydrozone.start);
                    setUplandActive(true);
                    break;
                case HYDROZONE_TYPE_TRANSITION:
                    setTransitionStart(hydrozone.start);
                    setTransitionActive(true);
                    break;
                case HYDROZONE_TYPE_OVERBANK:
                    setOverbankStart(hydrozone.start);
                    setOverbankActive(true);
                    break;
                case HYDROZONE_TYPE_BANK:
                    setBankStart(hydrozone.start);
                    setBankActive(true);
                    break;
                case HYDROZONE_TYPE_TOE:
                    setToeStart(hydrozone.start);
                    setToeActive(true);
                    break;
            }
        })
    };

    const setPlots = (transect) => {
        transect.TransectPlots.forEach(plot => {
            switch (plot.plotTypeId) {
                case PLOT_TYPE_1:
                    setPlot1Location(plot.start ?? '');
                    setPlot1Length(plot.length ?? '');
                    setPlot1Width(plot.width ?? '');
                    break;
                case PLOT_TYPE_2:
                    setPlot2Location(plot.start ?? '');
                    setPlot2Length(plot.length ?? '');
                    setPlot2Width(plot.width ?? '');
                    break;
            }
        })
    };

    const randomize = () => {
        const actualNoRoom = (Number(plot1Length) + Number(plot2Length)) > Number(transectLength);
        const calculatedPlot2Length = noRoom || actualNoRoom ? 0 : Number(plot2Length);
        const plot1Difference = Number(transectLength) - (Number(plot1Length) + calculatedPlot2Length);
        const randomPlot1Number = Math.round(Math.random() * plot1Difference);

        setPlot1Location(randomPlot1Number.toFixed(0));

        if (!noRoom && actualNoRoom) {
            setNoRoom(true);
        }

        if (!noRoom || !actualNoRoom) {
            const plot1EndLocation = (randomPlot1Number + Number(plot1Length));
            const plot2Difference = (Number(transectLength) - (plot1EndLocation + Number(plot2Length)));
            const randomPlot2Number = Math.round(Math.random() * plot2Difference) + plot1EndLocation;
            setPlot2Location(randomPlot2Number.toFixed(0));
        }
    };

    const save = (saveAnyways = false) => {
        if (transect) {
            const updatedTransect = {
                ...transect,
                name: name,
                latitude,
                longitude,
                accuracy,
                azimuth,
                nearestUpstreamSpecies: nearestUpstreamSpeciesName,
                nearestUpstreamSpeciesId: nearestUpstreamSpecies?.speciesId,
                nearestDownstreamSpecies: nearestDownstreamSpeciesName,
                nearestDownstreamSpeciesId: nearestDownstreamSpecies?.speciesId,
                originNotes,
                safetyNotes,
                isActive,
                createdBy,
                notes: transectNotes,
                length: transectLength,
                hasNoPlot2: noRoom,
                Hydrozones: getHydrozonesWithData(transect.transectId, transect.Hydrozones),
                TransectPlots: getAllPlots(transect.transectId, transect.TransectPlots),
            };

            if (hasWarnings(updatedTransect) && !saveAnyways) {
                return;
            } else if (saveAnyways) {
                
                updatedTransect.TransectCanopyClosure = {
                    ...emptyCanopyClosure,
                    measurementLocation: calculateCanopyClosureMeasurementLocationFromTransect(updatedTransect, hasHydrozonePlotWarnings(updatedTransect)),
                };

                updatedTransect.TransectReviews = updatedTransect.TransectReviews.map(review => {
                    if (
                        (hasHydrozonePlotWarnings(updatedTransect) && (review.code === TRANSECT_REVIEW_CODE_STEM_LENGTH || review.code === TRANSECT_REVIEW_CODE_DBH)) ||
                        (hasHydrozoneWarning(updatedTransect) && review.code === TRANSECT_REVIEW_CODE_INVASIVE_COVER) ||
                        (hasCanopyClosureWarnings(updatedTransect) && review.code === TRANSECT_REVIEW_CODE_CANOPY)
                    ) {
                        return {...review, isConfirmed: false};
                    }

                    return review;
                });
            }

            dispatch({type: UPSERT_TRANSECT, transect: updatedTransect});
        } else {
            const transectId = nextId();
            const sampleLocationId = nextId();
            const newPlots = getAllPlots(transectId);

            const newTransect = {
                transectId,
                sampleLocationId,
                sampleEventId: sampleEvent?.sampleEventId,
                projectId: projectId,
                name,
                latitude,
                longitude,
                accuracy,
                azimuth,
                nearestUpstreamSpecies: nearestUpstreamSpeciesName,
                nearestUpstreamSpeciesId: nearestUpstreamSpecies?.speciesId,
                nearestDownstreamSpecies: nearestDownstreamSpeciesName,
                nearestDownstreamSpeciesId: nearestDownstreamSpecies?.speciesId,
                originNotes,
                safetyNotes,
                isActive,
                createdBy,
                notes: transectNotes,
                length: transectLength,
                hasNoPlot2: noRoom,
                Hydrozones: getHydrozonesWithData(transectId),
                TransectPlots: newPlots,
                TransectReviews: emptyTransectReview,
                TransectCanopyClosure: {
                    ...emptyCanopyClosure,
                    measurementLocation: calculateCanopyClosureMeasurementLocation(newPlots, noRoom),
                },
                PhotoPoints: emptyPhotoPoints(sampleLocationId, sampleEvent.startDate),
            };

            dispatch({type: UPSERT_TRANSECT, transect: newTransect});
        }

        setHasChanges(false);
        history.goBack();
    };

    const getAllHydrozones = () => {
        return [
            {hydrozoneTypeId: HYDROZONE_TYPE_UPLAND, start: uplandStart, active: uplandActive},
            {hydrozoneTypeId: HYDROZONE_TYPE_TRANSITION, start: transitionStart, active: transitionActive},
            {hydrozoneTypeId: HYDROZONE_TYPE_OVERBANK, start: overbankStart, active: overbankActive},
            {hydrozoneTypeId: HYDROZONE_TYPE_BANK, start: bankStart, active: bankActive},
            {hydrozoneTypeId: HYDROZONE_TYPE_TOE, start: toeStart, active: toeActive}
        ];
    };

    const getActiveHydrozones = () => {
        return getAllHydrozones().filter(hydrozone => hydrozone.active);
    };

    const hasHydrozonePlotWarnings = (updatedTransect) => {
        const existingHydrozonePlots = transect.HydrozonePlots;
        const updatedHydrozonePlots = HydrozoneUtils.ComputeHydrozonePlots(updatedTransect);

        const changedHydrozonePlots = existingHydrozonePlots.filter(
            (existingPlot) => {
                
                // don't warn the user if there is nothing to remove
                if(!existingPlot.HydrozonePlotSpecies?.length) {
                    return false;
                }
                
                const updatedPlot = updatedHydrozonePlots.find( updatedPlot =>
                    existingPlot.hydrozoneTypeId === updatedPlot.hydrozoneTypeId &&
                    existingPlot.plotTypeId === updatedPlot.plotTypeId
                );
                
                // hydrozonePlots that don't exist anymore because the plot
                // has moved outside of the hydrozone it used to be in
                // should be considered 'invalid' so we delete their data
                if(!updatedPlot) {
                    return true;
                }
                
                return (
                    existingPlot.start !== updatedPlot.start ||
                    existingPlot.end !== updatedPlot.end ||
                    existingPlot.width !== updatedPlot.width
                );
            }
        );

        setInvalidHydrozonePlots(changedHydrozonePlots);
        return !!changedHydrozonePlots.length;
    };

    const hasCanopyClosureWarnings = (updatedTransect) => {
        const updatedMeasurement = calculateCanopyClosureMeasurementLocation(getAllPlots(), noRoom);
        const existingMeasurement = transect.TransectCanopyClosure?.measurementLocation;

        const measurementWarning = hasHydrozonePlotWarnings(updatedTransect) && (existingMeasurement != null && updatedMeasurement !== existingMeasurement);
        setInvalidCanopy(measurementWarning);
        return measurementWarning;
    };

    const hasHydrozoneWarning = (updatedTransect) => {
        const existingHydrozones = transect.Hydrozones;
        const updatedHydrozones = HydrozoneUtils.ComputeHydrozoneEnds(updatedTransect);

        const changedHydrozones = existingHydrozones.filter(existing => {
            // don't warn the user if there is nothing to remove
            if(!existing.HydrozoneGroundCoverSpecies?.length) {
                return false;
            }
            
            const updated = updatedHydrozones.find(updated =>
                updated.hydrozoneTypeId === existing.hydrozoneTypeId
            );
            return (
                !updated ||
                existing.start !== updated.start ||
                existing.end !== updated.end
            );
        });

        setInvalidHydrozones(changedHydrozones.map(x => x.hydrozoneTypeId));

        return !!changedHydrozones.length;
    };

    const hasWarnings = (updatedTransect) => {
        // Declare these so they run and set state
        const plotWarning = hasHydrozonePlotWarnings(updatedTransect);
        const hydrozoneWarning = hasHydrozoneWarning(updatedTransect);
        const canopyWarning = hasCanopyClosureWarnings(updatedTransect);
        return plotWarning || hydrozoneWarning || canopyWarning;
    };

    const getHydrozonesWithData = (transectId = null, hydrozones = [], omitHydrozoneId = false) => {
        const invalidHydrozonePlotIds = invalidHydrozonePlots.map(hp => Number(hp.plotTypeId));
        return getActiveHydrozones()
            .map(hydrozone => {
                const existingHydrozone = hydrozones.find(existing => existing.hydrozoneTypeId === hydrozone.hydrozoneTypeId);

                if (existingHydrozone) {
                    let updatedHydrozone = {
                        ...existingHydrozone,
                        start: hydrozone.start,
                    };

                    // Clear previously entered H+I data for invalidated Hydrozones
                    if (invalidHydrozones.includes(hydrozone.hydrozoneTypeId)) {
                        updatedHydrozone.HydrozoneGroundCoverSpecies = [];
                    }

                    // Clear previously entered Plot 1 / Plot 2 data for invalidated HydrozonePlots
                    updatedHydrozone.HydrozonePlotSpecies = existingHydrozone.HydrozonePlotSpecies.map(species => {

                        // If one of the hydrozonePlots for a given plotTypeId on this
                        // transect has been invalidated we should remove the data for
                        // all of them. The only case where I can imagine this case is
                        // when a plot crosses three+ hydrozones, and the start and end
                        // are changed in a way that the middle hydrozone(s) don't 
                        // change in size. But even in this case the user should be
                        // collecting data for the entire plot again.
                        if (invalidHydrozonePlotIds.includes(Number(species.plotTypeId))) {
                            const dataKeys = [...stemTallyCategories, ...lengthOfCoverCategories, ...treeDBHCategories];
                            const speciesData = {
                                hydrozoneId: existingHydrozone.hydrozoneId,
                                plotTypeId: species.plotTypeId,
                                speciesId: species.speciesId,
                                speciesName: species.speciesName,
                            };

                            dataKeys.forEach(data => {
                                speciesData[data.code] = 0;
                            });

                            return speciesData;
                        }

                        return species;
                    });

                    return updatedHydrozone;
                }

                return {
                    ...hydrozone,
                    hydrozoneId: omitHydrozoneId ? null : nextId(),
                    transectId,
                    leftQuadratStart: null,
                    rightQuadratStart: null,
                    hasNoStemsPlot1: false,
                    hasNoStemsPlot2: false,
                    hasNoLengthOfCoverPlot1: false,
                    hasNoLengthOfCoverPlot2: false,
                    hasNoDBHPlot1: null,
                    hasNoDBHPlot2: null,
                    hasNoRoomForQuadrat: null,
                    HydrozonePlotSpecies: [],
                    HydrozoneGroundCoverSpecies: [],
                }
            });
    };

    const getAllPlots = (transectId = null, plots = []) => {
        return [
            {plotTypeId: PLOT_TYPE_1, start: Number(plot1Location), length: Number(plot1Length), width: Number(plot1Width)},
            {plotTypeId: PLOT_TYPE_2, start: Number(plot2Location), length: Number(plot2Length), width: Number(plot2Width)},
        ].filter(plot => !(plot.plotTypeId === PLOT_TYPE_2 && noRoom))
            .map(plot => {
                const existingPlot = plots.find(existing => existing.plotTypeId === plot.plotTypeId);

                if (existingPlot) {
                    return {
                        ...existingPlot,
                        start: Number(plot.start),
                        length: Number(plot.length),
                        width: Number(plot.width)
                    }
                }

                return {
                    ...plot,
                    transectPlotId: !!transectId ? nextId() : null,
                    transectId,
                }
            });
    };

    const getEndLength = (type) => {
        if (Number(transitionStart) && type === HYDROZONE_TYPE_UPLAND) {
            return transitionStart;
        }

        if (Number(overbankStart) && (
            type === HYDROZONE_TYPE_UPLAND ||
            type === HYDROZONE_TYPE_TRANSITION
        )) {
            return overbankStart;
        }

        if (Number(bankStart) && (
            type === HYDROZONE_TYPE_UPLAND ||
            type === HYDROZONE_TYPE_TRANSITION ||
            type === HYDROZONE_TYPE_OVERBANK
        )) {
            return bankStart;
        }

        if (Number(toeStart) && (
            type === HYDROZONE_TYPE_UPLAND ||
            type === HYDROZONE_TYPE_TRANSITION ||
            type === HYDROZONE_TYPE_OVERBANK ||
            type === HYDROZONE_TYPE_BANK
        )) {
            return toeStart;
        }

        return transectLength;
    };

    const getHydrozoneValidationMessage = (type) => {

        // if length isn't filled in we won't validate a maximum
        const length = transectLength !== '' ? Number(transectLength) : null;

        // compute only which hydrozones are active
        const hydrozones = getHydrozonesWithData(transect?.transectId, transect?.Hydrozones, true);

        for(let i = 0; i < hydrozones.length; i++) {
            const previousHydrozone = hydrozones?.[i-1];
            const hydrozone = hydrozones[i];

            if(type === undefined || type === hydrozone.hydrozoneTypeId) {
                // validate this hydrozone
                const message = validateGreaterThanOrEquals(hydrozone.start, 0)
                             ?? (!previousHydrozone ? validateEqualTo(hydrozone.start, 0, 'First Hydrozone Start') : null)
                             ?? (previousHydrozone ? validateGreaterThan(hydrozone.start, previousHydrozone.start) : null)
                             ?? (length ? validateLessThan(hydrozone.start, Math.min(length, 255)) : null)
                             ?? validateInteger(hydrozone.start);

                if (message) {
                    return message;
                }
            }
        }

        return null;
    };
    
    const validateTransectPlot2Start = () => {
        
        if(noRoom) { 
            return null;
        }
        
        const plot2LocationValidation =
            (validateGreaterThanOrEquals(plot2Location, Number(plot1Location) + plot1Length) ||
                validateLessThanOrEquals(plot2Location, Number(transectLength)-plot2Length))
            
        if(plot2LocationValidation) {
            if(transect && Number(transect.transectLength) !== Number(transectLength)) {
                return plot2LocationValidation + ' Please randomly choose a new plot location and make note of previous and new plot location in Notes of Transect Notes.';
            }
        }
        
        return plot2LocationValidation;
    }

    const disableSave = () => {
        const requiredFields = [
            name,
            nearestDownstreamSpecies,
            nearestUpstreamSpecies,
            latitude,
            longitude,
            azimuth,
            transectLength,
            plot1Location,
            plot1Length,
            plot1Width,
            getActiveHydrozones()
        ];
        const validatedFields = [
            getHydrozoneValidationMessage(),
            validateLatitude(latitude),
            validateLongitude(longitude),
            validateBetween(azimuth, 0, 360),
            validateTransectPlot2Start(),
        ];

        return hasInvalidField(requiredFields, validatedFields);
    };

    const resetInvalidWarnings = () => {
        setInvalidHydrozonePlots([]);
        setInvalidHydrozones([]);
        setInvalidCanopy(false);
    };

    const onSpeciesSelect = (species) => {
        switch (speciesPickerAction) {
            case 'upstream':
                setNearestUpstreamSpecies(species);
                break;
            case 'downstream':
                setNearestDownstreamSpecies(species);
                break;
        }
    };

    return (
        <Fragment>
            <RouteLeavingGuard when={hasChanges} />
            <ConfirmDeleteModal
                title={"Confirm Transect Metadata Change"}
                show={!!(invalidHydrozonePlots.length || invalidHydrozones.length || invalidCanopy)}
                onHide={() => resetInvalidWarnings()}
                delete={() => {
                    save(true);
                    resetInvalidWarnings();
                }}
                discard
                verb={"Change and Discard Data"}
            >
                <div>
                    <p>                        
                        Are you sure you want to change the metadata of <i>Transect {transect?.name}</i> for this monitoring event?
                    </p>
                    <ol>
                        <li key={`general-warning-1`}>
                            Metadata (transect length and plot locations, as applicable) for this monitoring event will be changed.
                        </li>
                        <li key={`general-warning-2`}>
                            Changes will be carried forward to future events (events that have not yet been created).
                        </li>
                        <li key={`general-warning-3`}>
                            Changes will not affect historical events or metadata.
                        </li>
                        <li key={`general-warning-4`}>
                            <b>If any data has been collected at this transect during this monitoring event, it may be deleted.</b>
                        </li>
                    </ol>
                </div>
            </ConfirmDeleteModal>
            <DeactivateTransectModal
                transect={transect ?? null}
                show={showDeactivation}
                onHide={(cancelled) => {
                    setShowDeactivation(false);
                    if(!cancelled) {
                        setHasChanges(false);
                        history.goBack();
                    }
                }}
            />
            <PageContainer className="transect-detail-page">
                <SpeciesPickerModal
                    projectId={projectId}
                    listType={LIST_NON_INVASIVE_WOODY}
                    onSelect={onSpeciesSelect}
                />
                <PageHeader>
                    <Title title={transect ? `Transect ${transect.name} - Details` : 'New Transect'} readOnly={finalized} readOnlyReason={!isActive ? `This transect is deactivated. Transect ${transect.name} data will not be collected.` : null} />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col xs={12}>
                            <h2>General</h2>
                        </Col>
                        <Col xs={9}>
                            <Row xs={12}>
                                <Col xs={4}>
                                    <FormInput
                                        label="Transect Name"
                                        value={name}
                                        onChange={value => setName(value)}
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Latitude (Datum: WGS84)"
                                        type="text"
                                        inputmode="numeric"
                                        pattern="-?\d{0,3}\.\d{5}"
                                        value={latitude}
                                        onChange={value => setLatitude(value)}
                                        suffix="&#176;"
                                        validationMessage={validateLatitude(latitude)}
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Longitude (Datum: WGS84)"
                                        type="text"
                                        inputmode="numeric"
                                        pattern="-?\d{0,3}\.\d{5}"
                                        value={longitude}
                                        onChange={value => setLongitude(value)}
                                        suffix="&#176;"
                                        validationMessage={validateLongitude(longitude)}
                                        disabled={!isActive}
                                        required
                                    />
                                    <GeoLocationButton
                                        setLatitude={setLatitude}
                                        setLongitude={setLongitude}
                                        setAccuracy={setAccuracy}
                                        disabled={!isActive}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="GPS Accuracy (meters)"
                                        type="text"
                                        inputmode="decimal"
                                        pattern="\d+(\.\d+)?"
                                        value={accuracy}
                                        onChange={value => setAccuracy(value)}
                                        disabled={!isActive}
                                        suffix="m"
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Azimuth"
                                        type="text"
                                        inputmode="decimal"
                                        pattern="\d+"
                                        value={azimuth}
                                        onChange={value => setAzimuth(value)}
                                        validationMessage={validateBetween(azimuth, 0, 360)}
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <FormInput
                                className="species-input"
                                label="Nearest Upstream"
                                value={nearestUpstreamSpeciesName}
                                onClick={() => {
                                    if (finalized) return null;
                                    dispatch({type: OPEN_SPECIES_PICKER});
                                    setSpeciesPickerAction('upstream');
                                }}
                                disabled={!isActive}
                                required
                                readOnly
                            />
                        </Col>
                        <Col xs={6}>
                            <FormInput
                                className="species-input"
                                label="Nearest Downstream"
                                value={nearestDownstreamSpeciesName}
                                onClick={() => {
                                    if (finalized) return null;
                                    dispatch({type: OPEN_SPECIES_PICKER});
                                    setSpeciesPickerAction('downstream');
                                }}
                                disabled={!isActive}
                                required
                                readOnly
                            />
                        </Col>
                        <Col xs={6}>
                            <FormInput
                                label="Origin Location Notes"
                                type="textarea"
                                value={originNotes}
                                className="notes-field"
                                rows={4}
                                onChange={value => setOriginNotes(value)}
                                disabled={!isActive}
                            />
                        </Col>
                        <Col xs={6}>
                            <FormInput
                                label="Safety Notes"
                                type="textarea"
                                value={safetyNotes}
                                className="notes-field"
                                rows={4}
                                onChange={value => setSafetyNotes(value)}
                                disabled={!isActive}
                            />
                        </Col>
                        <Col xs={6}>
                            <FormInput
                                label="Transect Notes"
                                type="textarea"
                                value={transectNotes}
                                className="notes-field"
                                forwardedRef={transectNotesTextArea}
                                rows={4}
                                onChange={value => setTransectNotes(value)}
                                overlayIcon={
                                    <OverlayTrigger
                                        trigger="click"
                                        key="right"
                                        placement="right"
                                        overlay={
                                            <Popover>
                                                <Popover.Content>
                                                    Include year note was taken.
                                                </Popover.Content>
                                            </Popover>
                                        }
                                        rootClose
                                    >
                                        <FontAwesomeIcon icon={['fas', 'info-circle']} className="info-popover" />
                                    </OverlayTrigger>
                                }
                            />
                        </Col>
                        <Col xs={12} className="mt-3">
                            <h2>Hydrozones</h2>
                        </Col>
                        <Col xs={12}>
                            <Row xs={4}>
                                <Col>
                                    <FormInput
                                        label="Transect Length (ft)"
                                        type="text"
                                        inputmode="decimal"
                                        pattern="\d+"
                                        value={transectLength}
                                        onChange={value => setTransectLength(value)}
                                        suffix="ft"
                                        validationMessage={
                                            validateGreaterThan(transectLength, 0) ||
                                            validateLessThanOrEquals(transectLength, 255) ||
                                            validateInteger(transectLength)
                                        }
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5}>
                            <HydrozoneRow
                                title="Upland"
                                length={uplandStart}
                                setLength={value => setUplandStart(value)}
                                active={uplandActive}
                                setActive={value => setUplandActive(value)}
                                endLength={getEndLength(HYDROZONE_TYPE_UPLAND)}
                                validationMessage={getHydrozoneValidationMessage(HYDROZONE_TYPE_UPLAND)}
                                disabled={!isActive}
                                readOnly={finalized}
                            />
                            <HydrozoneRow
                                title="Transition"
                                length={transitionStart}
                                setLength={value => setTransitionStart(value)}
                                active={transitionActive}
                                setActive={value => setTransitionActive(value)}
                                endLength={getEndLength(HYDROZONE_TYPE_TRANSITION)}
                                validationMessage={getHydrozoneValidationMessage(HYDROZONE_TYPE_TRANSITION)}
                                disabled={!isActive}
                                readOnly={finalized}
                            />
                            <HydrozoneRow
                                title="Overbank"
                                length={overbankStart}
                                setLength={value => setOverbankStart(value)}
                                active={overbankActive}
                                setActive={value => setOverbankActive(value)}
                                endLength={getEndLength(HYDROZONE_TYPE_OVERBANK)}
                                validationMessage={getHydrozoneValidationMessage(HYDROZONE_TYPE_OVERBANK)}
                                disabled={!isActive}
                                readOnly={finalized}
                            />
                            <HydrozoneRow
                                title="Bank"
                                length={bankStart}
                                setLength={value => setBankStart(value)}
                                active={bankActive}
                                setActive={value => setBankActive(value)}
                                endLength={getEndLength(HYDROZONE_TYPE_BANK)}
                                validationMessage={getHydrozoneValidationMessage(HYDROZONE_TYPE_BANK)}
                                disabled={!isActive}
                                readOnly={finalized}
                            />
                            <HydrozoneRow
                                title="Toe"
                                length={toeStart}
                                setLength={value => setToeStart(value)}
                                active={toeActive}
                                setActive={value => setToeActive(value)}
                                endLength={getEndLength(HYDROZONE_TYPE_TOE)}
                                validationMessage={getHydrozoneValidationMessage(HYDROZONE_TYPE_TOE)}
                                disabled={!isActive}
                                readOnly={finalized}
                            />
                        </Col>
                        <Col xs={12} className="mt-3">
                            <h2>Plot Details</h2>
                        </Col>
                        <Col xs={9}>
                            <Row xs={12}>
                                <Col xs={4}>
                                    <Button
                                        variant="primary"
                                        className="w-100 mt-3 mb-3"
                                        onClick={() => randomize()}
                                        disabled={!transectLength || finalized || !isActive}
                                    >
                                        <FontAwesomeIcon icon={['fal', 'dice']} />
                                        Randomize Plot Selection
                                    </Button>
                                </Col>
                                <Col xs={4} className="d-flex align-items-center">
                                    <FormCheck
                                        id="no-room-checkbox"
                                        label="No Room for 2 Plots"
                                        type="checkbox"
                                        value={noRoom}
                                        checked={noRoom}
                                        onChange={() => (!finalized) ? setNoRoom(!noRoom) : null}
                                        disabled={!isActive}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <span className="font-weight-bold">Plot 1</span>
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Location Plot 1 (ft)"
                                        type="number"
                                        value={plot1Location}
                                        onChange={value => setPlot1Location(value)}
                                        suffix="ft"
                                        validationMessage={
                                            validateGreaterThanOrEquals(plot1Location, 0) ||
                                            validateLessThanOrEquals(plot1Location, Number(transectLength) - plot1Length)
                                        }
                                        disabled={!isActive}
                                        required
                                    />
                                    <SuggestedNoteButton
                                        value={plot1Location}
                                        previousValue={lastNotedPlot1 ?? transect?.TransectPlots?.find(x => x.plotTypeId === PLOT_TYPE_1)?.start}
                                        fieldName="Plot 1 Location"
                                        onNote={(suggestedNote, value) => {
                                            setLastNotedPlot1(value);
                                            addTransectNote(suggestedNote);
                                        }}
                                    >Record Plot 1 Change Note</SuggestedNoteButton>
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Plot 1 Length (ft)"
                                        type="text"
                                        inputmode="decimal"
                                        pattern="\d+"
                                        value={plot1Length}
                                        onChange={value => setPlot1Length(value)}
                                        suffix="ft"
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                                <Col xs={4}>
                                    <FormInput
                                        label="Plot 1 Width (ft)"
                                        type="text"
                                        inputmode="decimal"
                                        pattern="\d+"
                                        value={plot1Width}
                                        onChange={value => setPlot1Width(value)}
                                        suffix="ft"
                                        disabled={!isActive}
                                        required
                                    />
                                </Col>
                            </Row>
                            {
                                !noRoom &&
                                <Row xs={12}>
                                    <Col xs={12}>
                                        <span className="font-weight-bold">Plot 2</span>
                                    </Col>
                                    <Col xs={4}>
                                        <FormInput
                                            label="Location Plot 2 (ft)"
                                            type="text"
                                            inputmode="decimal"
                                            pattern="\d+"
                                            value={plot2Location}
                                            onChange={value => setPlot2Location(value)}
                                            suffix="ft"
                                            validationMessage={
                                                validateTransectPlot2Start()
                                            }
                                            disabled={!isActive}
                                            required
                                        />
                                        <SuggestedNoteButton
                                            value={(noRoom ? "'No Plot 2'" : plot2Location)}
                                            previousValue={lastNotedPlot2 ?? (transect?.hasNoPlot2 ? "'No Plot 2'" : transect?.TransectPlots?.find(x => x.plotTypeId === PLOT_TYPE_2)?.start)}
                                            fieldName="Plot 2 Location"
                                            onNote={(suggestedNote, value) => {
                                                setLastNotedPlot2(value);
                                                addTransectNote(suggestedNote);
                                            }}
                                        >Record Plot 2 Change Note</SuggestedNoteButton>
                                    </Col>
                                    <Col xs={4}>
                                        <FormInput
                                            label="Plot 2 Length (ft)"
                                            type="text"
                                            inputmode="decimal"
                                            pattern="\d+"
                                            value={plot2Length}
                                            onChange={value => setPlot2Length(value)}
                                            suffix="ft"
                                            disabled={!isActive}
                                            required
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <FormInput
                                            label="Plot 2 Width (ft)"
                                            type="text"
                                            inputmode="decimal"
                                            pattern="\d+"
                                            value={plot2Width}
                                            onChange={value => setPlot2Width(value)}
                                            suffix="ft"
                                            disabled={!isActive}
                                            required
                                        />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </PageContent>
                <PageFooter>
                    <div className="d-flex justify-content-between">
                        <div>
                            {
                                finalized ?
                                    <Button variant="secondary" onClick={() => history.goBack()}>
                                        Close
                                    </Button> :
                                    <SaveButtonWithHelpIcon
                                        type="Transect"
                                        hasData={!!transect}
                                        save={save}
                                        disable={disableSave()}
                                    />
                            }
                            <Button variant="link" className="ml-3" onClick={() => history.goBack()}>
                                <FontAwesomeIcon icon={['fal', 'times']} />
                                Cancel
                            </Button>
                        </div>
                        {
                            (!finalized && transect) &&
                                <Fragment>
                                    {
                                        isActive && transect.isActive ?
                                            <Button variant="danger" onClick={() => setShowDeactivation(true)}>
                                                <FontAwesomeIcon icon={['fal', 'times-circle']} />
                                                Deactivate
                                            </Button> :
                                        !isActive ?
                                            <Button className="btn-activate" onClick={() => setIsActive(true)}>
                                                <ReactivateIcon />
                                                Activate!
                                            </Button> : null
                                    }
                                </Fragment>
                        }
                    </div>
                </PageFooter>
            </PageContainer>
        </Fragment>
    );
};

export default TransectDetailPage;
