import storage from "localforage";

import ApiUtils from "../../Utils/ApiUtils";
import DateUtils from "../../Utils/DateUtils";
import Base64Utils from "../../Utils/Base64Utils";
import FileUtils from "../../Utils/FileUtils";
import EventUtils from "../../Utils/EventUtils";

import {DELETE_NODES, SET_HIERARCHY_NODES, SET_LOADING} from "./Types/hierarchyActionTypes";
import {
    OFFLINE_STATUS_DO_NOT_SYNC,
    OFFLINE_STATUS_OUT_OF_SYNC,
    OFFLINE_STATUS_READY_FOR_UPLOAD,
    OFFLINE_STATUS_SYNC
} from "../../Constants/status";
import {
    DELETE_CAMERA_POINTS,
    DELETE_PHOTO,
    DELETE_PROGRAMS,
    DELETE_PROJECTS,
    DELETE_QUAL_VEG_MONITORING,
    DELETE_SAMPLE_EVENT_PHASES,
    DELETE_SAMPLE_EVENT_PHOTOS,
    DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS,
    DELETE_SAMPLE_EVENT_TRANSECTS,
    DELETE_SAMPLE_EVENTS,
    DELETE_TRANSECTS,
    UPSERT_CAMERA_POINT,
    UPSERT_PHOTO,
    UPSERT_PROGRAM,
    UPSERT_PROJECT,
    UPSERT_SAMPLE_EVENT,
    UPSERT_SURVEY,
    ADD_SYNC_CONFLICT,
} from "./Types/offlineDataActionTypes";
import {
    ABORT_SYNC,
    ADD_FAILED_PHOTO_DELETE,
    ADD_FAILED_PHOTO_UPLOAD,
    ADD_SYNC_ERRORS,
    DECREASE_TOTAL_PROGRESS,
    HIDE_SYNC_DIALOG,
    INCREMENT_PROGRESS,
    RESET_PROGRESS,
    SET_CHECKING_FOR_DATA,
    SET_DOWNLOAD_PROGRESS,
    SET_PROCESS,
    SET_STEP,
    SET_UPLOAD_PROGRESS,
    SHOW_SYNC_DIALOG
} from "./Types/syncStatusTypes";
import {
    deleteOrphanedPhotoContent,
    deletePhotoContentById,
    movePhotoContent,
    removePhotoDependenciesForHierarchyIds,
    updatePhotoDependenciesForHierarchyIds
} from "./photoActions";
import {
    HIERARCHY_TYPE_PROGRAM,
    HIERARCHY_TYPE_PROJECT,
    HIERARCHY_TYPE_SAMPLE_EVENT,
    HIERARCHY_TYPE_SAMPLE_EVENT_PHASE,
    HIERARCHY_TYPE_SUBPROGRAM
} from "../../Constants/hierarchy";
import {selectAvailableSampleEvents, selectModifiedSampleEventHierarchyIds} from "../Selectors/nodeSelectors";
import {upsertTransects} from "./transectActions";
import {upsertQualitativeVegetationMonitorings} from "./qualitativeActions";
import PhotoUtils from "../../Utils/PhotoUtils";
import {selectImaginarySampleEventPhases} from "../Selectors/hierarchySelectors";

export const deletePrograms = (hierarchyIds) => (dispatch, getState) => {
    const {projects, programs} = getState().offlineDataState;

    const projectHierarchyIds = projects.filter(project => hierarchyIds.includes(project.parentHierarchyId))
        .map(project => project.hierarchyId);

    const subProgramIds = programs.filter(program => program.parentHierarchyId !== null && hierarchyIds.includes(program.parentHierarchyId))
        .map(program => program.hierarchyId);

    // delete children
    if(projectHierarchyIds.length) {
        dispatch(deleteProjects(projectHierarchyIds));
    }

    if(subProgramIds.length) {
        dispatch(deletePrograms(subProgramIds));
    }

    // clean up photo dependencies
    dispatch(removePhotoDependenciesForHierarchyIds(hierarchyIds));

    // TODO: SB-2377 - delete surveys and procedure document downloads

    // delete program data
    dispatch({type: DELETE_PROGRAMS, hierarchyIds});

    // Set Nodes to DO_NOT_SYNC
    dispatch({type: DELETE_NODES, hierarchyIds});
};

export const deleteProjects = (projectHierarchyIds) => (dispatch, getState) => {
    const {projects} = getState().offlineDataState;
    const sampleEventPhases = selectImaginarySampleEventPhases(getState());

    const sampleEventPhaseIds = sampleEventPhases.filter(sampleEventPhase => projectHierarchyIds.includes(sampleEventPhase.parentHierarchyId))
        .map(sampleEvent => sampleEvent.hierarchyId);

    // in this case we always delete because we don't
    // need to retain the most recent events
    dispatch(deleteSampleEventPhases(sampleEventPhaseIds))

    // clean up photo dependencies
    dispatch(removePhotoDependenciesForHierarchyIds(projectHierarchyIds));

    const projectIds = projects
        .filter(project => projectHierarchyIds.includes(project.hierarchyId))
        .map(project => project.projectId);
    // NOTE: We shouldn't need to delete photos or transects here
    // because they should be removed when we delete the sampleEvents

    // TODO: SB-2377 - delete project map downloads from localStorage

    // delete project data
    dispatch({type: DELETE_PROJECTS, projectHierarchyIds});
    dispatch({type: DELETE_CAMERA_POINTS, projectIds});

    // Set Nodes to DO_NOT_SYNC
    dispatch({type: DELETE_NODES, hierarchyIds: projectHierarchyIds});
};

export const deleteSampleEventPhases = (sampleEventPhaseHierarchyIds) => (dispatch, getState) => {
    const {sampleEvents} = getState().offlineDataState;

    const sampleEventHierarchyIds = sampleEvents.filter(sampleEvent => sampleEventPhaseHierarchyIds.includes(sampleEvent.parentHierarchyId))
        .map(sampleEvent => sampleEvent.hierarchyId);

    // delete children
    dispatch(deleteSampleEvents(sampleEventHierarchyIds));

    // sampleEventPhases shouldn't ever have photo dependencies, but this is consistent
    dispatch(removePhotoDependenciesForHierarchyIds(sampleEventPhaseHierarchyIds));

    // delete sample event phase data
    // note: sample event phases are virtual now, so they don't need to be deleted from redux
    // dispatch({type: DELETE_SAMPLE_EVENT_PHASES, sampleEventPhaseIds: sampleEventPhaseHierarchyIds});

    // Set Nodes to DO_NOT_SYNC
    // they do still need to be removed from the hierarchy
    dispatch({type: DELETE_NODES, hierarchyIds: sampleEventPhaseHierarchyIds});
};

export const deleteSampleEvents = (sampleEventHierarchyIds) => (dispatch, getState) => {
    // clean up photo dependencies
    dispatch(removePhotoDependenciesForHierarchyIds(sampleEventHierarchyIds));

    // delete sample event phase data
    // TODO: DELETE_SAMPLE_EVENT_PHOTOS and DELETE_SAMPLE_EVENT_TRANSECTS here

    const sampleEventIds = selectAvailableSampleEvents(getState())
        .filter(sampleEvent => sampleEventHierarchyIds.includes(sampleEvent.hierarchyId))
        .map(sampleEvent => sampleEvent.sampleEventId );

    dispatch({type: DELETE_SAMPLE_EVENT_PHOTOS, sampleEventIds });
    dispatch({type: DELETE_SAMPLE_EVENT_TRANSECTS, sampleEventIds });
    dispatch({type: DELETE_SAMPLE_EVENT_QUALITATIVE_VEGETATION_MONITORINGS, sampleEventIds });

    dispatch({type: DELETE_SAMPLE_EVENTS, hierarchyIds: sampleEventHierarchyIds});

    // Set Nodes to DO_NOT_SYNC
    dispatch({type: DELETE_NODES, hierarchyIds: sampleEventHierarchyIds});
};

export const deleteOrHideSampleEventPhases = (sampleEventPhaseHierarchyIds) => (dispatch, getState) => {
    const {sampleEvents} = getState().offlineDataState;

    const sampleEventHierarchyIds = sampleEvents.filter(sampleEvent => sampleEventPhaseHierarchyIds.includes(sampleEvent.parentHierarchyId))
        .map(sampleEvent => sampleEvent.hierarchyId);

    // delete children
    dispatch(deleteOrHideSampleEvents(sampleEventHierarchyIds));

    // delete sample event phase data
    dispatch({type: DELETE_SAMPLE_EVENT_PHASES, sampleEventPhaseIds: sampleEventPhaseHierarchyIds});

    // Set Nodes to DO_NOT_SYNC
    dispatch({type: DELETE_NODES, hierarchyIds: sampleEventPhaseHierarchyIds});
};

export const deleteOrHideSampleEvents = (sampleEventHierarchyIds) => (dispatch, getState) => {
    const {sampleEvents} = getState().offlineDataState;

    let sampleEventHierarchyIdsToDelete = [];
    for(const sampleEventHierarchyId of sampleEventHierarchyIds) {

        // don't delete sampleEventIds for most recent
        // sample events, just hide them instead
        if(EventUtils.isMostRecentSampleEvent(sampleEvents, sampleEventHierarchyId)) {
            dispatch({
                type: UPSERT_SAMPLE_EVENT,
                sampleEvent: {...sampleEventHierarchyId, readOnly: true},
                download: true, // don't set the offlineModifiedDate
            });

            dispatch({type: DELETE_NODES, hierarchyIds: [sampleEventHierarchyId]});

            continue;
        }

        sampleEventHierarchyIdsToDelete.push(sampleEventHierarchyId);
    }

    dispatch(deleteSampleEvents(sampleEventHierarchyIdsToDelete));

    // note that deleteSampleEvents above dispatches DELETE_NODES for the sampleEventHierarchyIdsToDelete
    // and we manually dispatch DELETE_NODES for the hidden hierarchyIds in the loop above
    // so we don't need to dispatch DELETE_NODES here like this:
    // dispatch({type: DELETE_NODES, hierarchyIds: sampleEventHierarchyIds});
};

export const setHierarchyNodes = (organizationId) => async (dispatch, getState) => {
    let allNodes;

    const {sampleEvents, projects, programs, photos, surveys} = getState().offlineDataState;
    const sampleEventPhases = selectImaginarySampleEventPhases(getState());
    const {online, offlineMode} = getState().appStatusState;
    const {hierarchyNodes} = getState().hierarchyNodeState;

    let stillLoading = false;

    if (!offlineMode && online) {
        dispatch({type: SET_LOADING, loading: true});
        try {
            const data = await ApiUtils.SBCancellableAxios().get('monitoringHierarchy', {params: {organizationId}});
            allNodes = data.data.result;
        } catch (e) {
            allNodes = hierarchyNodes;
            // if our await is cancelled we are still loading
            stillLoading = true;
        }
    } else {
        allNodes = hierarchyNodes;
    }

    const downloadedNodes = [...sampleEvents, ...sampleEventPhases, ...projects, ...programs];
    const hierarchyNodesWithState = allNodes
        .filter(node => {
            // Only show procedures that the app supports
            return node.procedureCode === null || ['quant-veg', 'qual-veg', 'photo'].includes(node.procedureCode)
        })
        .map(node => {
            const downloadedNode = downloadedNodes.find(item => item.hierarchyId === node.hierarchyId && !item.readOnly);

            if (downloadedNode && !!downloadedNode.version) {
                // TODO: find a way to calculated changed hierarchyNodes
                // note to self: make a selector that has an array of changed hierarchyNodes

                if (DateUtils.IsAfter(node.version, downloadedNode.version)) {
                    return {
                        ...node,
                        offlineState: OFFLINE_STATUS_OUT_OF_SYNC,
                    }
                }

                if (DateUtils.IsEqual(node.version, downloadedNode.version)) {

                    // verify photos are synced
                    if (node.sampleEventId) {
                        // check if we have any photo/photo-changes that need to be uploaded
                        if(photos.some(photo => String(photo.sampleEventId) === String(node.sampleEventId) && PhotoUtils.photoHasChanges(photo))) {
                            return {
                                ...node,
                                offlineState: OFFLINE_STATUS_READY_FOR_UPLOAD
                            }
                        }

                        // verify that SampleEvents have all the expected photos
                        const expectedPhotos = photos.filter(photo => String(photo.sampleEventId) === String(node.sampleEventId))
                        for(const photo of expectedPhotos) {
                            if(photo?.downloadError) {
                                console.log('missing expected photos', node);
                                return {
                                    ...node,
                                    offlineState: OFFLINE_STATUS_OUT_OF_SYNC
                                }
                            }
                        }
                    }

                    // verify that Sub Programs have at least one Qual survey
                    // NOTE: is there a case where a program might not have these
                    // defined? in which case this will always appear out of sync...
                    if (String(node.hierarchyTypeId) === String(HIERARCHY_TYPE_SUBPROGRAM)) {
                        const subProgram = programs.find(program => String(program.contextNodeId) === String(node.subProgramContextNodeId));

                        if(!subProgram) {
                            console.log('missing subProgram', node.subProgramContextNodeId)
                        }

                        if(!subProgram || surveys.filter(survey => String(survey.programId) === String(subProgram.programId)).length < 1) {
                            console.log('missing surveys', surveys, node);
                            return {
                                ...node,
                                offlineState: OFFLINE_STATUS_OUT_OF_SYNC
                            }
                        }
                    }

                    return {
                        ...node,
                        offlineState: OFFLINE_STATUS_SYNC,
                    }
                }
            } else if(downloadedNode && (downloadedNode?.version ?? null) === null) {
                console.log('downloaded node version was null', downloadedNode);
            }

            return {
                ...node,
                offlineState: OFFLINE_STATUS_DO_NOT_SYNC,
            }
        });

    dispatch({type: SET_HIERARCHY_NODES, hierarchyNodes: hierarchyNodesWithState});
    dispatch({type: SET_LOADING, loading: stillLoading});
};

export const syncData = (uploadHierarchyIds, downloadHierarchyIds, organizationId, reloadHierarchy = true) => async (dispatch, getState) => {
    dispatch({type: RESET_PROGRESS});
    dispatch({type: SHOW_SYNC_DIALOG});

    // Upload Projects
    [uploadHierarchyIds, downloadHierarchyIds] = await dispatch(uploadProject(uploadHierarchyIds, downloadHierarchyIds));

    // Upload Events
    [uploadHierarchyIds, downloadHierarchyIds] = await dispatch(uploadSampleEvent(uploadHierarchyIds, downloadHierarchyIds));

    // Download Projects and Events
    let photosToDownload = await dispatch(downloadHierarchyNodes(downloadHierarchyIds));

    // Download Photos
    await dispatch(downloadPhotos(photosToDownload));

    // Check for New Data
    await dispatch(checkForNewData(reloadHierarchy, organizationId));

    dispatch({type: SET_PROCESS, process: 'Finished'});
}

export const checkForNewData = (reloadHierarchy, organizationId) => async (dispatch, getState) => {
    const {abort} = getState().syncState;

    if (!abort && reloadHierarchy) {
        dispatch({type: SET_CHECKING_FOR_DATA, checkingForData: true});
        try {
            await dispatch(setHierarchyNodes(organizationId));
        } finally {
            dispatch({type: SET_CHECKING_FOR_DATA, checkingForData: false});
        }
    }
};

export const uploadProject = (uploadHierarchyIds, downloadHierarchyIds) => async (dispatch, getState) => {
    const {projects, sampleEvents, cameraPoints, photos} = getState().offlineDataState;
    const projectDataToUpload = getProjectDataToUpload(uploadHierarchyIds, projects, cameraPoints, photos);

    if (projectDataToUpload.length) {
        dispatch({type: SET_STEP, step: 'Uploading'});
        dispatch({type: SET_PROCESS, process: 'Projects'});
        dispatch({type: SET_UPLOAD_PROGRESS, field: 'totalProjects', value: projectDataToUpload.length});

        for (const projectData of projectDataToUpload) {
            const {uploadProgress, abort} = getState().syncState;
            const {Project: project = null} = projectData;

            if (abort) { break; }

            try {
                const {data} = await ApiUtils.SBAxios().post('offlineData/upload/project', projectData);
                const {
                    CameraPoints,
                    Photos,
                    MonitoringHierarchyNodes
                } = data.result;
                const node = MonitoringHierarchyNodes[0];

                downloadHierarchyIds = [...downloadHierarchyIds, node.hierarchyId];

                dispatch({type: DELETE_CAMERA_POINTS, projectIds: [project.projectId]});
                CameraPoints.forEach(cameraPoint => {
                    dispatch({type: UPSERT_CAMERA_POINT, cameraPoint, download: true});
                });

                // remap project Photos in case their PhotoPoint tempIds changed
                // no need to delete these anymore because the photoId shouldn't have changed
                // and the UPSERT method will replace the matching photoId
                // project.Photos.forEach(photo => dispatch({type: DELETE_PHOTO, photoId: photo.photoId}));
                Photos.forEach(photo => {
                    dispatch({type: UPSERT_PHOTO, photo, download: true});
                });

                // these photos get added to photosToSync later
                // during the SampleEvent upload, since they all have sampleEventIds

            } catch (error) {
                // if the project fails to sync, then abort syncing it and any child nodes
                const projectHierarchyId = String(project?.hierarchyId);
                uploadHierarchyIds = uploadHierarchyIds.filter((hierarchyId) => EventUtils.getParentProjectHierarchyIdByHierarchyId(sampleEvents, hierarchyId) !== projectHierarchyId);
                downloadHierarchyIds = downloadHierarchyIds.filter((hierarchyId) => EventUtils.getParentProjectHierarchyIdByHierarchyId(sampleEvents, hierarchyId) !== projectHierarchyId);
                dispatch(handleError(error));
            }

            dispatch({type: SET_UPLOAD_PROGRESS, field: 'completedProjects', value: uploadProgress.completedProjects + 1});
        }
        dispatch({type: INCREMENT_PROGRESS});
    } else {
        dispatch({type: DECREASE_TOTAL_PROGRESS});
    }

    return [uploadHierarchyIds, downloadHierarchyIds];
};

export const uploadSampleEvent = (uploadHierarchyIds, downloadHierarchyIds) => async (dispatch, getState) => {
    const {sampleEvents, transects, photos, qualitativeVegetationMonitorings} = getState().offlineDataState;

    const sampleEventDataToUpload = getSampleEventDataToUpload(uploadHierarchyIds, sampleEvents, photos, transects, qualitativeVegetationMonitorings);

    if (sampleEventDataToUpload.length) {
        let photosToSync = [];

        dispatch({type: SET_STEP, step: 'Uploading'});
        dispatch({type: SET_PROCESS, process: 'Monitoring Events'});
        dispatch({type: SET_UPLOAD_PROGRESS, field: 'totalEvents', value: sampleEventDataToUpload.length});

        for (const sampleEventData of sampleEventDataToUpload) {
            const {uploadProgress, abort} = getState().syncState;
            const {SampleEvent: sampleEvent = null} = sampleEventData;

            if (abort) { break; }

            try {
                const {data} = await ApiUtils.SBAxios().post('offlineData/upload/sampleEvent', sampleEventData);
                
                // TODO: investigate what would happen if a sync was interrupted/canceled at this point
                const {SampleEvents, Photos, MonitoringHierarchyNodes, Transects, QualitativeVegetationMonitoring} = data?.result;
                const node = MonitoringHierarchyNodes[0];

                dispatch({type: DELETE_SAMPLE_EVENTS, hierarchyIds: [sampleEvent.hierarchyId]});
                dispatch({type: DELETE_TRANSECTS, transectIds: sampleEventData.Transects.map(transect => transect.transectId)});
                dispatch({type: DELETE_QUAL_VEG_MONITORING, qualitativeVegetationMonitoringIds: sampleEventData.QualitativeVegetationMonitoring.map(q => q.qualitativeVegetationMonitoringId)});
                dispatch({
                    type: UPSERT_SAMPLE_EVENT,
                    sampleEvent: {
                        ...SampleEvents[0],
                        hierarchyId: node.hierarchyId,
                        parentHierarchyId: node.parentHierarchyId,
                    },
                    download: true
                });

                dispatch(upsertTransects(Transects, true));
                dispatch(upsertQualitativeVegetationMonitorings(QualitativeVegetationMonitoring, true));

                // when uploading a new SampleEvent it
                // affects the modified date of the parent
                // hierarchy nodes as well, so we should
                // download all of them here.
                downloadHierarchyIds = [
                    ...downloadHierarchyIds,
                    node.hierarchyId,
                    node.projectContextNodeId,
                    node.subProgramContextNodeId,
                ];

                // photoId doesn't get assigned until POST /api/photos is called, so we don't
                // need to delete these, just upsert them
                // sampleEventData.Photos.forEach(photo => dispatch({type: DELETE_PHOTO, photoId: photo.photoId}));

                // Update the CameraPoint or SampleEvent temp ids incase they changed
                Photos.map(async photo => {
                    dispatch({type: UPSERT_PHOTO, photo, download: true});
                    return photo;
                });

                dispatch(updatePhotoDependenciesForHierarchyIds(Photos ?? [], [node.hierarchyId]));

                photosToSync = [...photosToSync, ...Photos];
            } catch (error) {
                dispatch(handleError(error));
            }
            dispatch({type: SET_UPLOAD_PROGRESS, field: 'completedEvents', value: uploadProgress.completedEvents + 1});
        }
        dispatch({type: INCREMENT_PROGRESS});

        if (photosToSync.length) {
            dispatch({type: SET_PROCESS, process: 'Photos'});
            dispatch({type: SET_UPLOAD_PROGRESS, field: 'totalPhotos', value: photosToSync.length});

            for (const photo of photosToSync) {
                const {uploadProgress, abort} = getState().syncState;

                if (abort) { break; }

                // don't update photos that haven't been modified
                if(PhotoUtils.photoShouldBeUploaded(photo)) {
                    if (!photo?.deleted) {
                        // upsert the photo
                        const isNewPhoto = PhotoUtils.photoIsNew(photo) || PhotoUtils.photoIsMissingThumbnail(photo);
                        try {
                            const blob = await storage.getItem(PhotoUtils.getPhotoApiPath(photo.photoId));
                            const arrayBuffer = await (Blob.prototype.arrayBuffer ? blob?.arrayBuffer?.() : new Response(blob).arrayBuffer());

                            if(blob) {

                                // if the hash or byte size differ, it is probably because the user has downloaded
                                // a lower resolution version of an image, and it was 'copied' into a new revision
                                // of the Qualitative Vegetation Survey
                                photo.hash ??= arrayBuffer ? (await Base64Utils.ArrayBufferToHash(arrayBuffer)) : null;
                                photo.fileSize ??= arrayBuffer?.byteLength;

                                try {
                                    // Use SBAxios().post for new photo content and SBAxios().put for unchanged photo content
                                    const method = isNewPhoto ? 'post' : 'put';
                                    const {data} = await ApiUtils.SBAxios()[method]('photos', photo);
                                    const photoId = Number(data?.result?.photo?.photoId);

                                    // this can be used to test the race condition in SB-2432 on Safari
                                    // await new Promise(r => setTimeout(r, 5000));

                                    try {
                                        if (isNewPhoto && data?.result?.url) {
                                            const response = await _sendChunksToSignedUrl(data.result.url, blob);

                                            try {
                                                const {data} = await ApiUtils.SBAxios().post('photos/resize/' + photoId);
                                                const newResizedPhoto = data?.result?.photo;
                                                // these get updated in the "finally" below
                                                photo.fileSizeLarge = newResizedPhoto?.fileSizeLarge;
                                                photo.fileSizeMedium = newResizedPhoto?.fileSizeMedium;
                                                photo.fileSizeSmall = newResizedPhoto?.fileSizeSmall;

                                                // we used to delete this photo content here because we want to force
                                                // a re-download (to get the smaller photo size)
                                                // but out of caution I'm going to leave this in place instead for now
                                                // await deletePhotoContentById(photo.photoId);
                                                // also leaving the large size photo on the device instead
                                                // of downloading a new one should improve sync times
                                                // at the (small?) expense of device storage
                                            } catch (e) {
                                                dispatch(handleError(e, 'Failed to create thumbnail for photo ' + photoId + ': ' + (e?.data?.message ?? e?.message ?? e)));
                                                dispatch(handleError(null, 'Please try syncing again.'));
                                                dispatch({type: ADD_FAILED_PHOTO_UPLOAD});
                                            }
                                        }
                                    } catch (e) {
                                        dispatch(handleError(e, 'Failed to upload photo ' + photoId + ': ' + (e?.data?.message ?? e?.message ?? e)));
                                        dispatch(handleError(null, 'Please try syncing again.'));
                                        dispatch({type: ADD_FAILED_PHOTO_UPLOAD});
                                    } finally {
                                        // update the photoId and move the photo content in indexedDB
                                        // setting download true and sending "photo" instead of "data.result.photo"
                                        // here ensures we keep the existing offline dates (since the photo may not be successfully uploaded yet)
                                        // and that we don't update the sampleEvent's offline date (so it remains looking unsynced)
                                        //
                                        // note: we have to move the photo content after we upload it
                                        // because Safari has some concurrency issues with adding/removing
                                        // items from IndexedDB while holding a Blob reference to that data
                                        // but this finally block allows us to ensure that regardless
                                        // of what happens we always attempt to move the photoContent
                                        // after we have successfully created a new photoId on the server
                                        await dispatch(movePhotoContent(photo, photoId, true));
                                    }
                                } catch (e) {
                                    dispatch(handleError(e, 'Failed to create photo ' + photo.photoId + ': ' + (e?.data?.message ?? e?.message ?? e)));
                                    dispatch({type: ADD_FAILED_PHOTO_UPLOAD});
                                }
                            } else {
                                dispatch(handleError(null, 'Content for photo ' + photo.photoId + ' does not exist in indexedDB. Try syncing again from the device that collected this photo, or delete the photo.'));
                                dispatch({type: ADD_FAILED_PHOTO_UPLOAD});
                            }
                        } catch (e) {
                            dispatch(handleError(e, 'Failed to read photo ' + photo.photoId + ' from indexedDB: ' + (e?.message ?? e)));
                            dispatch(handleError(null, 'Please try restarting the app.'));
                            dispatch({type: ADD_FAILED_PHOTO_UPLOAD});
                        }
                    } else {
                        // delete the photo
                        try {
                            const {data} = await ApiUtils.SBAxios().delete('photos/' + photo.photoId);
                            dispatch({type: DELETE_PHOTO, photoId: photo.photoId});
                            await deletePhotoContentById(photo.photoId)
                        } catch (e) {
                            dispatch({type: ADD_FAILED_PHOTO_DELETE});
                        }
                    }
                }

                dispatch({
                    type: SET_UPLOAD_PROGRESS,
                    field: 'completedPhotos',
                    value: uploadProgress.completedPhotos + 1
                });
            }

            dispatch({type: INCREMENT_PROGRESS});
        } else {
            dispatch({type: DECREASE_TOTAL_PROGRESS});
        }
    } else {
        dispatch({type: DECREASE_TOTAL_PROGRESS, num: 2});
    }

    return [uploadHierarchyIds, downloadHierarchyIds];
};

const downloadHierarchyNodes = (downloadHierarchyIds) => async (dispatch, getState) =>  {
    const {abort} = getState().syncState;

    // Download Nodes
    let photosToDownload = [];

    if (!abort && downloadHierarchyIds.length) {
        dispatch({type: SET_STEP, step: 'Downloading'});
        dispatch({type: SET_PROCESS, process: 'Projects and Events'});
        dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'totalNodes', value: downloadHierarchyIds.length});

        for (const nodeId of downloadHierarchyIds) {
            [downloadHierarchyIds, photosToDownload] = await dispatch(downloadHierarchyNode(nodeId, downloadHierarchyIds, photosToDownload));
        }

        // this should be done here after all our downloads
        // have updated the dependency tree
        dispatch(deleteOrphanedPhotoContent());

        dispatch({type: INCREMENT_PROGRESS});
    } else {
        dispatch({type: DECREASE_TOTAL_PROGRESS});
    }
    return photosToDownload;
}

export const downloadHierarchyNode = (nodeId, downloadHierarchyIds, photosToDownload, readOnly = false) => async (dispatch, getState) => {
    const {downloadProgress, abort} = getState().syncState;

    // guard against allowing the user to download and overwrite data
    // for a node which has offline changes. this case actually happens
    // sometimes if the user attempts to sync and gets a failure during
    // uploading. That event's project then downloads and it attempts to
    // download the "most recent events" which could be the very events
    // for which we were trying to upload changes.
    // we shouldn't need to guard against projects getting downloaded
    // because if they fail to upload they abort downloading.
    const modifiedSampleEventHierarchyIds = selectModifiedSampleEventHierarchyIds(getState());
    if(modifiedSampleEventHierarchyIds.includes(nodeId)) {
        console.log(`skipped download of ${nodeId} since we have changes for it currently`);
        return [downloadHierarchyIds, photosToDownload];
    }

    const sampleEventPhases = selectImaginarySampleEventPhases(getState());
    const samplePhaseHierarchyIds = sampleEventPhases.map(phase => phase.hierarchyId);
    const skipDownload = samplePhaseHierarchyIds.includes(nodeId);

    if (!abort) {
        try {
            let data = {}, hierarchyNode = null;
            if(!skipDownload) {
                ( {data} = await ApiUtils.SBAxios().post('offlineData/download', {hierarchyId: nodeId}) );
                hierarchyNode = data.MonitoringHierarchyNodes?.[0] ?? null;
            }

            if (!readOnly) {
                dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'completedNodes', value: downloadProgress.completedNodes + 1});
            }

            if (hierarchyNode) {

                switch (hierarchyNode.hierarchyTypeId) {
                    case HIERARCHY_TYPE_PROGRAM:
                    case HIERARCHY_TYPE_SUBPROGRAM:
                        await dispatch(downloadProgram(data, hierarchyNode));
                        break;
                    case HIERARCHY_TYPE_PROJECT:
                        const projectResult = await dispatch(downloadProject(data, hierarchyNode, downloadHierarchyIds, photosToDownload));
                        if (projectResult.abort) {
                            return {abort: true};
                        }
                        photosToDownload = projectResult.photosToDownload;
                        break;
                    case HIERARCHY_TYPE_SAMPLE_EVENT_PHASE:
                        // these are virtual now
                        // dispatch(downloadSampleEventPhase(data));
                        break;
                    case HIERARCHY_TYPE_SAMPLE_EVENT:
                        dispatch(downloadEvent(data, hierarchyNode, readOnly));
                        break;
                }

                dispatch(upsertTransects(data?.Transects, true));
                dispatch(upsertQualitativeVegetationMonitorings(data?.QualitativeVegetationMonitoring, true));

                dispatch(updatePhotoDependenciesForHierarchyIds(data.Photos ?? [], [hierarchyNode.hierarchyId]));

                if (data.Photos?.length) {
                    photosToDownload = [...photosToDownload, ...data.Photos];
                }
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    }

    return [downloadHierarchyIds, photosToDownload];
};

const downloadProgram = (data, hierarchyNode) => async (dispatch) => {
    const {hierarchyId, parentHierarchyId, hierarchyTypeId, version} = hierarchyNode;

    const program = data.Programs[0];
    const programNode = {
        ...program,
        hierarchyId,
        parentHierarchyId,
        hierarchyTypeId,
        version
    };

    const programId = program.programId;

    // Store Program / Sub-Program node
    dispatch({type: UPSERT_PROGRAM, program: programNode, download: true});

    // Store Surveys
    if (data.Surveys?.length) {
        data.Surveys.forEach(survey => {
            dispatch({type: UPSERT_SURVEY, survey: {...survey, programId}});
        })
    }

    if (program.Procedures?.length) {
        for (const procedure of program.Procedures) {
            if (procedure.documentURL) {
                const path = procedure.documentURL.split('/api/').pop();

                if (await storage.getItem(`/api/${path}`) === null) {
                    try {
                        await ApiUtils.SBAxios().get(path);
                    } catch (e) {
                        console.log("Failed to download one or more procedures. Your On-Device data may be incomplete.");
                    }
                }
            }
        }
    }
};

export const downloadProject = (data, hierarchyNode, downloadHierarchyIds, photosToDownload) => async (dispatch) => {
    const {hierarchyId, parentHierarchyId, programContextNodeId, subProgramContextNodeId, version} = hierarchyNode;

    const project = {
        ...data.Projects[0],
        hierarchyId,
        parentHierarchyId,
        programContextNodeId,
        subProgramContextNodeId,
        Species: [
            {
                speciesId: null,
                commonName: 'Not Applicable',
                speciesTypeId: -1,
            },
            ...data.Projects[0].Species
        ],
        version
    };

    dispatch({type: UPSERT_PROJECT, project, download: true});

    // Recent monitoring event ids
    if (data.RecentMonitoringEventHierarchyIds?.length) {
        data.RecentMonitoringEventHierarchyIds
            .filter(hierarchyId => !downloadHierarchyIds.includes(hierarchyId))
            .forEach(async (hierarchyId) => {
                // Download recent sampleEvent and mark them readOnly if necessary
                const eventResult = await dispatch(downloadHierarchyNode(hierarchyId, downloadHierarchyIds, photosToDownload, true));
                if (eventResult.abort) {
                    return {abort: true};
                }
                photosToDownload = eventResult.photosToDownload;
            })
    }

    // Store Camera Points
    if (data.CameraPoints?.length) {
        data.CameraPoints.forEach(cameraPoint => {
            const cameraPointWithProjectId = {
                ...cameraPoint,
                projectId: data.Projects[0].projectId,
            };

            dispatch({type: UPSERT_CAMERA_POINT, cameraPoint: cameraPointWithProjectId, download: true});
        });
    }

    // Cache Project Maps
    if (project.projectMapFileUploadId) {
        const path = `fileUploads/${project.projectMapFileUploadId}/download`;

        if (await storage.getItem(`/api/${path}`) === null) {
            try {
                await ApiUtils.SBAxios().get(path);
            } catch (e) {
                console.log("Failed to download one or more project maps. Your On-Device data may be incomplete.");
            }
        }
    }

    return {photosToDownload, abort: false};
};

export const downloadEvent = (data, hierarchyNode, readOnly = false) => (dispatch, getState) => {
    const {sampleEvents} = getState().offlineDataState;
    const {hierarchyId, parentHierarchyId, projectContextNodeId, version} = hierarchyNode;

    const sampleEvent = {
        ...data.SampleEvents[0],
        hierarchyId,
        parentHierarchyId,
        projectContextNodeId,
        version
    };

    sampleEvent.readOnly = readOnly;

    // If readOnly is false, then set to false
    // If readOnly is true but sampleEvent already exists
    // use already existing readOnly value
    const existingSampleEvent = sampleEvents.find(item => item.sampleEventId === sampleEvent.sampleEventId);
    if (existingSampleEvent && readOnly) {
        sampleEvent.readOnly = existingSampleEvent.readOnly;
    }

    dispatch({type: UPSERT_SAMPLE_EVENT, sampleEvent, download: true});
};

export const downloadPhotos = (photosToDownload) => async (dispatch, getState) => {
    const photoQuality = getState().syncState.photoQuality;
    let photosWithErrors = 0;

    // filter photosToDownload to only contain unique values
    photosToDownload = photosToDownload.filter((a, i) => photosToDownload.findIndex((s) => a.photoId === s.photoId) === i)

    if (photosToDownload.length) {
        const totalSize = photosToDownload.reduce((acc, curr) => acc + curr.fileSize, 0);
        dispatch({type: SET_PROCESS, process: 'Photos'});
        dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'totalPhotos', value: photosToDownload.length});
        dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'totalPhotosSize', value: totalSize});

        for (const photo of photosToDownload) {
            const {downloadProgress, abort} = getState().syncState;

            if (abort || photoQuality === 'none') { break; }

            const photoId = photo.photoId;

            // don't bother downloading assets that we already have
            // because the service-worker response is actually pretty slow

            let photoChanges = {};
            let downloadedOneOrMorePhotos = false;

            try {

                // Attempts to cache the photoUrl if it
                // is not already cached. Throws an error
                // if we are unable to cache it.
                // Returns the photoUrl if successful.
                const ensurePhotoUrlIsCached = async (photoUrl) => {
                    const key = FileUtils.IndexedDBKeyFromPhotoURL(photoUrl);
                    if(await storage.getItem(key) === null) {
                        downloadedOneOrMorePhotos = true;
                        await ApiUtils.SBAxios().get(photoUrl);
                    }
                    return photoUrl;
                }

                // set photoChanges[key] for a specific photoUrl
                // if it is successfully cached
                const cacheAndUpdatePhotoUrl = async (key, photoUrl) => {
                    try {
                        photoChanges[key] = await ensurePhotoUrlIsCached(photoUrl);
                    } catch (e) {
                        // if we fail to download a photo
                        if(e?.message === 'Network Error') {
                            dispatch(handleNetworkError(e));
                        }

                        // count our errors
                        photosWithErrors++;
                        photoChanges.downloadError = e || true;
                    }
                    return photoUrl
                }

                await Promise.allSettled([
                    cacheAndUpdatePhotoUrl('src', PhotoUtils.getPhotoApiUrl(photoId, false, photoQuality)),
                    cacheAndUpdatePhotoUrl('thumbnailSrc', PhotoUtils.getPhotoApiUrl(photoId, true))
                ]);

                // this counter only counts photos for which we actually fetched data
                if(downloadedOneOrMorePhotos) {
                    dispatch({
                        type: SET_DOWNLOAD_PROGRESS,
                        field: 'downloadedPhotos',
                        value: downloadProgress.downloadedPhotos + 1
                    });
                }

            } catch (e) {
                // TODO: how should we handle this case? If the photos fail to download
                // the node appears to have completed downloading, but it is not complete
                console.log(e)
                dispatch(handleError(e, "Unexpected error while downloading photos: " + e?.data?.message ?? e?.statusText ?? e?.status ?? 'Check Internet Connection'));
                // photoWithSrc.downloadError = e?.status || true;
            }

            // only update the redux store if we had actual changes
            // if the user has already downloaded the given photo
            // then the src urls will not have changed?
            dispatch({type: UPSERT_PHOTO, photo: {...photo, ...photoChanges}, download: true});
            dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'completedPhotos', value: downloadProgress.completedPhotos + 1});
            dispatch({type: SET_DOWNLOAD_PROGRESS, field: 'completedPhotosSize', value: downloadProgress.completedPhotosSize + photo.fileSize});
        }

        dispatch({type: INCREMENT_PROGRESS});
    } else {
        dispatch({type: DECREASE_TOTAL_PROGRESS});
    }

    if(photosWithErrors > 0) {
        dispatch(handleError('photo', `${photosWithErrors} photo(s) failed to download.`));
    }
};

const handleSyncConflicts = (error) => (dispatch) => {
    const {syncConflicts = []} = error?.data ?? {};
    dispatch({
        type: ADD_SYNC_CONFLICT,
        syncConflicts
    });
};

const handleError = (error, customMessage) => (dispatch) => {
    console.log(error);
    switch (error?.status) {
        case 500:
            let title = customMessage ? `Unable to ${customMessage} due to ${error.data.message}` : error.data.message;
            title += ' Please try again later or contact an administrator.';
            dispatch({type: ADD_SYNC_ERRORS, syncErrors: [{title}]});
            dispatch({type: ABORT_SYNC});
            break;
        case 409:
            dispatch(handleSyncConflicts(error));
            dispatch({type: ADD_SYNC_ERRORS, syncErrors: [{title: error.data.message, children: error.data.syncErrors}]});
            break;
        default:
            if(error?.message === 'Network Error') {
                dispatch(handleNetworkError(error));
            } else {
                dispatch({
                    type: ADD_SYNC_ERRORS,
                    syncErrors: [{title: customMessage ?? (error?.data?.message) ?? error?.statusText ?? error?.message ?? 'Unknown Error'}]
                });
            }
    }
};

export const handleNetworkError = (error) => (dispatch, getStore) => {
    dispatch({type: ABORT_SYNC});
    dispatch({type: ADD_SYNC_ERRORS, syncErrors: [{title: 'Network Error. Check your connection and try again.'}]});
}

/**
 * Some versions of the API didn't include 'hierarchyTypeId',
 * so this does our best guess based on the hierarchyId.
 * 
 * This should be safe to use in the deleteHierarchyNode action
 * because the user does not manually delete programs or
 * sub-programs from the DeleteNodeModal (which is the only
 * place this code is used currently). If you need to use this
 * action for deleting programs or sub-programs, make sure to
 * explicitly include the appropriate hierarchyTypeId in the
 * hierarchyNode argument.
 * 
 * @param hierarchyNode
 */
const deduceHierarchyNodeTypeId = (hierarchyNode) => 
    hierarchyNode.hierarchyTypeId ?? (String(hierarchyNode.hierarchyId).split('/').length + 2)

export const deleteHierarchyNode = (hierarchyNode) => (dispatch, getState) => {
    switch(deduceHierarchyNodeTypeId(hierarchyNode)) {
        case HIERARCHY_TYPE_PROGRAM:
        case HIERARCHY_TYPE_SUBPROGRAM:
            dispatch(deletePrograms([hierarchyNode.hierarchyId]));
            break;
        case HIERARCHY_TYPE_PROJECT:
            dispatch(deleteProjects([hierarchyNode.hierarchyId]));
            break;
        case HIERARCHY_TYPE_SAMPLE_EVENT_PHASE:
            // in these cases we hide or delete
            dispatch(deleteOrHideSampleEventPhases([hierarchyNode.hierarchyId]));
            break;
        case HIERARCHY_TYPE_SAMPLE_EVENT:
            // in these cases we hide or delete
            dispatch(deleteOrHideSampleEvents([hierarchyNode.hierarchyId]));
            break;
        default:
            console.log('hierarchyNode has no hierarchyTypeId!');
    }

    dispatch(deleteOrphanedPhotoContent());
};

const findOutOfDateParentHierarchyNodeIds = (initialHierarchyNodeId, hierarchyNodes) => {
    let currentNode = initialHierarchyNodeId;
    let outOfDateNodeIds = [];
    do {
        if (currentNode?.offlineState !== OFFLINE_STATUS_SYNC) {
            outOfDateNodeIds.push(currentNode.hierarchyId);
        }
    } while (currentNode = hierarchyNodes.find(node => node.hierarchyId === currentNode.parentHierarchyId))
    return outOfDateNodeIds;
};

export const createMonitoringEvent = (projectHierarchyId, history) => async (dispatch, getState) => {
    const organizationId = getState().hierarchyNodeState.organizationId;

    // If project exists, check if it is up to date, otherwise just download without checking
    if (getState().offlineDataState.projects.find(project => project.hierarchyId === projectHierarchyId)) {
        dispatch({type: SHOW_SYNC_DIALOG});
        dispatch({type: SET_CHECKING_FOR_DATA, checkingForData: true});
        await dispatch(setHierarchyNodes(organizationId));
        if (getState().syncState.abort) return;
        dispatch({type: SET_CHECKING_FOR_DATA, checkingForData: false});
    }

    const hierarchyNodes = getState().hierarchyNodeState.hierarchyNodes;
    const projectNode = hierarchyNodes.find(node => node.hierarchyId === projectHierarchyId);

    let downloadedHierarchyIds = findOutOfDateParentHierarchyNodeIds(projectNode, hierarchyNodes);

    if (downloadedHierarchyIds.length > 0) {
        // we can't do
        // dispatch({type: ENABLE_NO_SLEEP});
        // here because we are in an async
        // function (not user initiated)
        // but we shouldn't need to since it
        // should already be enabled
        await dispatch(syncData([], downloadedHierarchyIds, organizationId, false));
        if (getState().syncState.abort) return;
    }

    const project = getState().offlineDataState.projects.find(project => project.hierarchyId === projectHierarchyId);
    history.push({
        pathname: '/event/create',
        search: `?projectId=${project.projectId}&autoUpload=1`,
    });

    dispatch({type: HIDE_SYNC_DIALOG});
};

const getProjectDataToUpload = (hierarchyIds, projects, cameraPoints, photos) => {
    return projects.filter(project => hierarchyIds.includes(project.hierarchyId))
        .map(project => {
            let photoPointIds = [];
            const projectCameraPoints = cameraPoints.filter(point => {
                const isModified = !!point.offlineModifiedDate || !!point.PhotoPoints.find(photoPoint => !!photoPoint.offlineModifiedDate);

                return point.projectId === project.projectId && isModified;
            }).map(point => ({
                ...point,
                PhotoPoints: point.PhotoPoints.filter(photoPoint => !!photoPoint.offlineModifiedDate)
            })) || [];
            cameraPoints.forEach(point => {
                if(point.projectId === project.projectId) {
                    point.PhotoPoints.forEach(photoPoint => photoPointIds.push(photoPoint.photoPointId));
                }
            });
            const projectPhotos = photos.filter(photo => {
                return photoPointIds.includes(photo.photoPointId) && PhotoUtils.photoHasChanges(photo);
            }) || [];

            return {
                Project: {
                    ...project,
                    Users: undefined,
                    Species: undefined,
                },
                CameraPoints: projectCameraPoints,
                Photos: projectPhotos,
            }
        });
};

const getSampleEventDataToUpload = (hierarchyIds, sampleEvents, photos, transects, qualitativeVegetationMonitorings) => {
    return sampleEvents.filter(sampleEvent => hierarchyIds.includes(sampleEvent.hierarchyId))
        .map(sampleEvent => {
            const sampleEventPhotos = photos.filter(photo => {
                return photo.sampleEventId === sampleEvent.sampleEventId && PhotoUtils.photoShouldBeUploaded(photo);
            }).map(photo => ({
                ...photo,
            })) || [];

            const sampleEventTransects = transects
                .filter(transect => String(transect.sampleEventId) === String(sampleEvent.sampleEventId));

            const sampleEventQualitativeVegetationMonitorings = qualitativeVegetationMonitorings
                .filter(qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.sampleEventId) === String(sampleEvent.sampleEventId));

            return {
                SampleEvent: sampleEvent,
                Photos: sampleEventPhotos,
                Transects: sampleEventTransects,
                QualitativeVegetationMonitoring: sampleEventQualitativeVegetationMonitorings,
            }
        });
};

const _sendChunksToSignedUrl = (url, file) => {
    //If we want to speed things up, it sounds like gcloud would support multiple chunks being uploaded at the same time. This would require this to be redone a bit, and
    // we'd need to play around with how many uploads vs chunksize, but we can really improve things if needed.
    return new Promise((resolve, reject) => {
        const chunkSize = 2097152; // Read in chunks of 2MB
        const chunks = Math.ceil(file.size / chunkSize); //get total # of chunks in file
        const fileReader = new FileReader();
        let currentChunk = 0, start = 0, end = 0; // current position

        const loadNext = (startRange = null) => {
            start = startRange ?? (currentChunk * chunkSize);
            end = Math.min(file.size, (start + chunkSize));

            //read a slice of the file content for this chunk.
            fileReader.readAsArrayBuffer(file.slice(start, end));
        };

        //on single chunk load callback.
        fileReader.onload = e => {
            currentChunk++;

            //open against the signed url with a put.
            ApiUtils.Axios().put(url, e.target.result, {
                headers: {
                    "Content-Range": "bytes " + start + "-" + (end - 1) + "/" + file.size,
                }
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    if(currentChunk < chunks) {
                        // We should in theory be done according to the server, so stop uploading
                        console.log("signed url upload thinks we're done, but we still have chunks?", currentChunk, chunks);
                    }
                    resolve();
                } else {
                    //TODO: There are some 500 cases and stuff we can retry and make things less fragile.
                    // Jeff note: I'm pretty sure Axios won't call this "then" handler
                    // if we are outside of the 200 status code range
                    reject();
                }
            }).catch(e => {
                if (e?.response?.status === 308) {
                    const { start, end } = FileUtils.ParseHTTPRangeHeader(e.response.headers?.['range']);
                    loadNext(end ?? null);
                } else {
                    console.log(e);
                    reject({message: "could not upload file in chunks: " + (e?.response?.status ? `Status ${e?.response?.status}` : '') + (e?.message ?? ''), file: file, err: e})
                }
            });
        };
        //on error reading any chunk
        fileReader.onerror = e => reject({message: "could not read file chunks for upload: " + fileReader?.error, file: file, err: e});

        //lets get this rolling.
        loadNext();
    });
};
