import React from 'react';
import {Col, Row} from "react-bootstrap";
import HydrozoneSpeciesRow from "./HydrozoneSpeciesRow";
import {TYPE_INVASIVE_COVER} from "../../../Constants/lengthCategories";
import {HydrozoneCoverPercentLabel} from "./HydrozoneCoverPercentLabel";

const HydrozoneSpeciesList = props => {
    const { type, disabled, plotLength, hydrozoneSpecies, species, categories, noPlants } = props;
    const typeClass = `type-${type}`;
    const isInvasiveCover = type === TYPE_INVASIVE_COVER;

    return <div className={`hydrozone-species-list ${typeClass}`}>
        <Row xs={12} className="species-labels">
            <Col xs={isInvasiveCover ? 6 : 3}>
                <span>Species Name</span>
            </Col>
            <Col>
                <Row xs={12}>
                    {
                        categories?.map(category => (
                            <Col key={category.id} className="hydrozone-species-list-header">
                                <span>{category.name}</span>
                                { isInvasiveCover ? <HydrozoneCoverPercentLabel
                                    hydrozoneSpecies={hydrozoneSpecies}
                                    categoryCode={category.code}
                                    noPlants={noPlants}
                                /> : null }
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Row>
        {
            hydrozoneSpecies.map(((item, index) => (
                <HydrozoneSpeciesRow
                    key={index}
                    item={item}                    
                    species={species.find(species => species.speciesId === item.speciesId)}
                    type={type}
                    categories={categories}
                    plotLength={plotLength}
                    disabled={disabled}
                />
            )))
        }
    </div>;
};

export default HydrozoneSpeciesList;
