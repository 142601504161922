import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useReturnLink} from "../../Hooks/RouteHooks";

const ReturnLink = (props) => {
    const {
        children,
        isTransectReview,
        onClick,
        path,
        returnTitle
    } = props;
    
    const returnLink = useReturnLink();

    const getReturnTitle = () => {
        if (returnTitle) {
            return returnTitle;
        }

        if (isTransectReview) {
            return 'Transect Review';
        }

        return 'Review & Finalize';
    };

    const action = () => {
        if (onClick) {
            onClick();
        } else {
            returnLink(path, getReturnTitle());
        }
    };
    
    if(!onClick && !path) {
        return children;
    }

    return (
        <span className="return-link" onClick={() => action()}>
            {children}
            <FontAwesomeIcon icon={['fas', 'external-link']} />
        </span>
    )
};

export default ReturnLink;
