/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import DateUtils from "../../Utils/DateUtils";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef} from "react";

const SuggestedNoteButton = (props) => {
	
	const { 
		children= "Note it!",
		icon= ['fal', 'sticky-note'],
		value,
		previousValue,
		fieldName,
		onNote = (suggestedNoteContent, value) => {},
		condition = (value) => ((previousValue ?? '' !== '') && (value ?? '' !== '') && String(previousValue) !== String(value))
	} = props
	
	const ref = useRef(null);
	const show = !!condition(value)
	
	useEffect(() => {
		if (show && ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [show]);
	
	const message = `${fieldName} was changed from ${previousValue} to ${value}.`;
	const plotChangeNote = `${DateUtils.GetCurrentDate()}: ${fieldName} was changed from ${previousValue} to ${value}.`;
	
	return show ? <div className="suggested-note" ref={ref}>
		<div className="message" >{message}</div>
		<Button variant="warning" onClick={() => onNote(plotChangeNote, value)}>
			{
				icon ? <FontAwesomeIcon icon={icon} /> : null
			}
			{children}
		</Button>
	</div> : null;
}

export default SuggestedNoteButton;
