import React from 'react';
import { createSelector } from 'reselect';
import PhotoUtils from "../../Utils/PhotoUtils";

export const selectPhotos = state => state.offlineDataState.photos;
export const selectCameraPoints = state => state.offlineDataState.cameraPoints;

export const selectVisiblePhotos = createSelector(
    [selectPhotos],
    (photos) => {
        return photos.filter(photo => ((photo?.deleted ?? null) === null));
    }
);

export const selectNotYetUploadedPhotos = createSelector(
    [selectVisiblePhotos],
    (photos) => {
        return photos.filter(photo => (
            PhotoUtils.photoHasChanges(photo) || PhotoUtils.photoIsMissingThumbnail(photo))
        );
    }
);

export const selectVisiblePhotoById = createSelector(
    [
        state => selectVisiblePhotos(state),
        (_, photoId) => photoId
    ],
    (visiblePhotos, photoId) => {
        return visiblePhotos.find(photo => String(photo.photoId) === String(photoId));
    }
);

export const selectPhotoIdsThatMusntBePruned = createSelector(
    [selectNotYetUploadedPhotos, ],
    (notYetUploadedPhotos) => notYetUploadedPhotos.map(({ photoId }) => String(photoId))
)

export const selectVisiblePhotoByQualitativeRepeaterId = (state, sampleEventId, qualitativeEventId) => {
    return selectVisiblePhotos(state).find(photo => String(photo.photoId) === String(photoId));
};

export const selectSurveyPhotos = createSelector(
    [
        state => selectVisiblePhotos(state),
        (state, sampleEventId) => sampleEventId,
        (state, sampleEventId, repeaterId = null) => repeaterId
    ],
    (photos, sampleEventId, repeaterId) => {
        return photos.filter(photo => (
            String(photo.sampleEventId) === String(sampleEventId) &&
            (repeaterId === null || String(photo.qualitativeVegetationMonitoringRepeaterId) === String(repeaterId))
        ));
    }
);

export const selectCameraPointsByProjectId = (state, id) => {
    return selectCameraPointsWithPhotoPointHistory(state).filter(point => String(point.projectId) === String(id));
};

export const selectCameraPointAndPhotoPointByPhotoPointId = (state, photoPointId) => {
    let photoPoint = null;
    const {cameraPoints} = state.offlineDataState;
    
    // const photoPointId = currentPhoto?.photoPointId;
    const cameraPoint = cameraPoints.find(cameraPoint => (
        photoPoint = cameraPoint.PhotoPoints.find(photoPoint => String(photoPoint.photoPointId) === String(photoPointId))
    ));
    
    return {cameraPoint, photoPoint};
}

export const selectTransectAndPhotoPointByPhotoPointId = (state, photoPointId, sampleEventId) => {
    let photoPoint;
    const {transects} = state.offlineDataState;
    
    const transect = transects.find(transect => {
        // make sure we choose the appropriate transect instance
        // for the current photo
        if (sampleEventId && String(sampleEventId) !== String(transect.sampleEventId)) {
            return false;
        }

        // we don't care if we get the wrong transect
        // when photoPoint is already set because we
        // won't use that transect since we set
        //  photoPointType to 'cameraPoint' below
        return photoPoint = photoPoint ?? transect.PhotoPoints?.find(photoPoint => String(photoPoint.photoPointId) === String(photoPointId))
    });
    return {transect, photoPoint};
}

export const selectSampleLocationAndPhotoPointByPhotoPointId = (state, photoPointId, sampleEventId) => {
    let cameraPoint = null, transect = null, photoPoint = null;
    
    // first look in camera points
    ({ cameraPoint, photoPoint } = selectCameraPointAndPhotoPointByPhotoPointId(state, photoPointId));
    
    // if no match, look in transects
    if(!photoPoint) {
        ({ transect, photoPoint } = selectTransectAndPhotoPointByPhotoPointId(state, photoPointId, sampleEventId));
    }
    
    return {
        cameraPoint,
        transect,
        photoPoint
    }
}

export const selectCameraPointsWithPhotoPointHistory = createSelector(
    [selectCameraPoints],
    (cameraPoints) => {
        return cameraPoints.map(cameraPoint => {
            return {
                ...cameraPoint,
                PhotoPoints: cameraPoint?.PhotoPoints?.map(photoPoint => {
                    const photoPointHistory = photoPoint?.PhotoPointHistory;

                    if (photoPointHistory?.length > 0) {
                        const present = 'Present';
                        const history = photoPointHistory.map(history => {
                            const activeFrom = new Date(history.activeFrom).getFullYear();
                            const activeTo = history.activeTo ? new Date(history.activeTo).getFullYear() : present;

                            return `${activeFrom} - ${activeTo}`;
                        });

                        // If the last history item doesn't end with Present, then it is discontinued
                        if (!history[history.length - 1].endsWith(present)) {
                            return {
                                ...photoPoint,
                                activity: 'Discontinued',
                                discontinued: true,
                            };
                        } else {
                            return {
                                ...photoPoint,
                                activity: `Active ${history.join(', ')}`,
                                discontinued: false,
                            };
                        }
                    }

                    return {
                        ...photoPoint,
                        activity: '',
                        discontinued: false,
                    }
                })
            };
        });
    }
);
