import React from 'react';
import {Table} from "react-bootstrap";

import {
    getHydrozonePlotTitle,
    getHydrozoneSpeciesFieldName,
    useGetHydrozonePlotEmptyMessage,
    useGetHydrozonePlotLink,
    useGetTransectEmptyMessage,
    useGetUserFacingSpeciesName,
    useHydrozoneName,
} from "../../../Hooks/LabelHooks";

import {categoriesByType, TYPE_INVASIVE_COVER, TYPE_STEM_TALLY,} from "../../../Constants/lengthCategories";
import ReturnLink from "../../Common/ReturnLink";
import SpeciesTableDataCell from "./SpeciesTableDataCell";
import {useSelector} from "react-redux";
import {
    selectSpeciesIdsForTransectHydrozonesWithFilteredPlotSpecies,
    selectTransectHydrozonesWithFilteredPlotSpecies
} from "../../../Redux/Selectors/transectSelectors";

const SpeciesTable = props => {
    
    const {
        show = true,
        type = TYPE_STEM_TALLY,
        group = type === TYPE_INVASIVE_COVER,
        transectId,
        plotTypeId = null,
        species,
    } = props;
    
    const getHydrozoneName = useHydrozoneName();
    const getHydrozonePlotLink = useGetHydrozonePlotLink(transectId, type, plotTypeId);
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    const getHydrozonePlotEmptyMessage = useGetHydrozonePlotEmptyMessage(transectId, type, plotTypeId);
    const getTransectEmptyMessage = useGetTransectEmptyMessage(transectId, type, plotTypeId);

    const title = getHydrozonePlotTitle(type, plotTypeId);
    const categories = categoriesByType?.[type] ?? [];
    
    const hydrozoneSpeciesType = getHydrozoneSpeciesFieldName(type);
    const hydrozones = useSelector(state => selectTransectHydrozonesWithFilteredPlotSpecies(state, transectId, plotTypeId, type));
    const speciesIds = useSelector(state => selectSpeciesIdsForTransectHydrozonesWithFilteredPlotSpecies(state, transectId, plotTypeId, type));

    const transectEmptyMessage = getTransectEmptyMessage(hydrozones);
    
    function renderGroupedSpeciesRows(speciesId, rowIndex) {
        const emptyMessage = getTransectEmptyMessage(hydrozones);
        
        if (transectEmptyMessage) {
            let rowSpan = speciesIds.length;
            return rowIndex ? null : <SpeciesTableDataCell
                className="data-cell type-normal no-data"
                colSpan={categories.length}
                rowSpan={rowSpan}
                key={`non-existent-hydrozone`}
            >{emptyMessage}</SpeciesTableDataCell>;
        }
        
        return categories.map((category, i) => {
            // Group hydrozone data by category
            const value = hydrozones.reduce((acc, curr) => {
                return acc + curr[hydrozoneSpeciesType].filter(species => speciesId === species.speciesId)
                    .reduce((acc, curr) => acc + Number(curr[category.code]) || 0, 0)
            }, 0);
            
            return <SpeciesTableDataCell
                className="data-cell type-normal"
                key={category.id}
                leftBorderClass={i === 0}
                rightBorderClass={i === categories.length - 1}
            >{value}</SpeciesTableDataCell>;
        });
    }
    
    const renderSpeciesData = () => {
        return speciesIds
            .map((speciesId, rowIndex) => {
                const currentSpecies = species.find(species => species.speciesId === speciesId);

                return (
                    <tr key={String(speciesId)}>
                        <td className="species-cell rounded-cell-container fixed-column">
                            <span className="species-name">{getUserFacingSpeciesName(currentSpecies) ?? 'None'}</span>
                        </td>
                        {
                            group ?
                                renderGroupedSpeciesRows(speciesId, rowIndex) :
                                
                                hydrozones.map((datum) => {
                                    
                                    const hydrozoneSpecies = datum[hydrozoneSpeciesType]
                                        .find(species => speciesId === species.speciesId);
                                    const emptyMessage = getHydrozonePlotEmptyMessage(datum);
                                    
                                    if (emptyMessage) {
                                        return rowIndex ? null : <SpeciesTableDataCell
                                            colSpan={categories.length}
                                            rowSpan={speciesIds.length}
                                            className={`data-cell type-${getHydrozoneName(datum.hydrozoneTypeId).toLowerCase()} no-data`}
                                            key={`non-existent-hydrozone-${datum.hydrozoneId}`}
                                        >{emptyMessage}</SpeciesTableDataCell>;
                                    }
                                    
                                    return (
                                        categories.map((category, i) => {
                                            const value = hydrozoneSpecies?.[category.code];
                                            
                                            return <SpeciesTableDataCell
                                                className={`data-cell type-${getHydrozoneName(datum.hydrozoneTypeId).toLowerCase()}`}
                                                key={category.id}
                                                leftBorderClass={i === 0}
                                                rightBorderClass={i === categories.length-1}
                                            >{value}</SpeciesTableDataCell>;
                                        })
                                    )
                                })
                        }
                    </tr>
                )
            });
    };

    if (!show) {
        return null;
    }
    
    if (!speciesIds.length) {
        speciesIds.push(-1);
    }

    return (
        <Table borderless className={`species-table type-${type}`}>
            <tbody>
                <tr key="title">
                    <th className="table-title">
                        {
                            group && title ?
                                <ReturnLink path={getHydrozonePlotLink(hydrozones?.[0]?.hydrozoneTypeId)} isTransectReview>
                                    {title}
                                </ReturnLink> :
                                title
                        }
                    </th>
                </tr>
                {
                    !group &&
                        <tr key="group-header">
                            <th className="type-blank">
                                <div className="rounded-cell-container" />
                            </th>
                            {
                                hydrozones.map(hydrozone => (
                                    <th
                                        key={hydrozone.hydrozoneId}
                                        colSpan={categories.length}
                                        className={`type-${getHydrozoneName(hydrozone.hydrozoneTypeId).toLowerCase()}`}
                                    >
                                        <ReturnLink path={getHydrozonePlotLink(hydrozone?.hydrozoneTypeId)} isTransectReview>
                                            {getHydrozoneName(hydrozone.hydrozoneTypeId)}
                                        </ReturnLink>
                                    </th>
                                ))
                            }
                        </tr>
                }
                <tr className="sub-header" key="sub-header">
                    <td className="fixed-column">
                        <span>Species Name</span>
                    </td>
                    {
                        group ?
                            categories.map(category => (
                                <td key={category.id}>{category.shortName || category.name}</td>
                            )) :
                            hydrozones.map((hydrozone, i) => (
                                categories.map(category => (
                                    <td key={category.id + "." + i}>{category.shortName || category.name}</td>
                                ))
                            ))
                    }
                </tr>
                {renderSpeciesData()}
            </tbody>
        </Table>
    )
};

export default SpeciesTable;
