/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {Col} from "react-bootstrap";
import FormInput from "../../Common/FormInput";
import React from "react";

const HydrozoneSpeciesCell = props => {
	const {
		onBlur,
		validationMessage,
		value = null,
		disabled,
		onFocus
	} = props;
	
	return <Col>
		<FormInput
			type="text"
			inputmode="decimal"
			pattern="\d+(\.\d+)?"
			value={value}
			onFocus={onFocus}
			onBlur={onBlur}
			disabled={disabled}
			validationMessage={validationMessage(value)}
			readOnly
		/>
	</Col>;
};

export default HydrozoneSpeciesCell;
