import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Spinner} from "react-bootstrap";

const CircledIcon = props => {
    const {
        iconTransform,
        icon,
        circleSize,
        loading,
        disabled = false,
        className,
        onClick = (event) => {}
    } = props;
    return (
        <span className={`circled-icon fa-layers fa-fw ${className || ''} ${(disabled || loading) ? 'disabled' : ''}`} onClick={onClick}>
            <FontAwesomeIcon icon={['fas', 'circle']} transform={`grow-${circleSize}`} />
            {
                loading ?
                    <Spinner animation="border" size="sm" /> :
                    <FontAwesomeIcon icon={icon} transform={iconTransform} inverse />
            }
        </span>
    );
};

export default CircledIcon;
