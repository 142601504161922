import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from 'react-router-dom'
import {Button, Col, Row} from 'react-bootstrap';

import {useContextTools, useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';
import {CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_PHOTO} from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import PageFooter from '../../Layout/Page/PageFooter';
import Title from '../../Layout/TitleComponent';
import TileList from '../../Common/TileList';
import PageContainer from '../../Layout/Page/PageContainer';
import Sidebar from './QuantVegToolSidebar';

import {useHydrozoneTypesForPlot, useInvasiveHydrozoneTypes} from "../../../Hooks/QuickNavHooks";
import {PLOT_TYPE_1, PLOT_TYPE_2} from "../../../Constants/hydrozones";
import PageHeader from "../../Layout/Page/PageHeader";
import {TYPE_STEM_TALLY} from "../../../Constants/lengthCategories";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectTransectWithProgressByTransectId} from "../../../Redux/Selectors/transectProgressSelectors";
import {ConflictResolutionDialog} from "./ConflictResolutionDialog";
import {resolveConflict} from "../../../Redux/Actions/conflictActions";

const QuantVegToolPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);

    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const getHydrozoneTypes = useHydrozoneTypesForPlot();
    const getInvasiveHydrozoneTypes = useInvasiveHydrozoneTypes();

    const transect = useSelector(state => selectTransectWithProgressByTransectId(state, params.transectId));
    
    const [showResolve, setShowResolve] = useState(false);

    useBreadcrumbs(`Transect ${transect.name}`, transect.projectId, transect.sampleEventId);
    useContextToolsBySampleEventId(transect.sampleEventId);

    const filteredTools = transect?.toolProgress
        ?.filter(tool => {
            // TODO: should this be based on the hasNoPlot2 ? or the metadata for the plot2?
            return !tool.hide && !(transect.hasNoPlot2 && tool.code === 'plot2')
        }) ?? []
    const allToolsCompleted = filteredTools.every(tool => tool.isCompleted);
    const hasConflict = !!transect.syncConflict;

    const onToolClick = (item) => {
        history.push(`/transect/${params.transectId}/${item.path || item.code}`);
    };

    const getTileRoute = (item) => {
        const plot1HydrozoneType = getHydrozoneTypes(params.transectId, PLOT_TYPE_1)[0];
        const plot2HydrozoneType = getHydrozoneTypes(params.transectId, PLOT_TYPE_2)[0];
        const invasiveHydrozoneType = getInvasiveHydrozoneTypes(params.transectId)[0];

        switch (item.code) {
            case 'plot1':
                if (!plot1HydrozoneType) {
                    return null;
                }

                return `/transect/${params.transectId}/${item.code}/${TYPE_STEM_TALLY}/${plot1HydrozoneType.hydrozoneTypeId}`;
            case 'plot2':
                if (!plot2HydrozoneType) {
                    return null;
                }

                return `/transect/${params.transectId}/${item.code}/${TYPE_STEM_TALLY}/${plot2HydrozoneType.hydrozoneTypeId}`;
            case 'invasive-cover':
                if (!invasiveHydrozoneType) {
                    return null;
                }

                return `/transect/${params.transectId}/${item.code}/${invasiveHydrozoneType.hydrozoneTypeId}`;
            default:
                return `/transect/${params.transectId}/${item.code}`;
        }
    };

    return (
        <PageContainer className="event-details-page" sidebar={<Sidebar transect={transect}/>}>
            <PageHeader>
                <Title title={`Transect ${transect.name}`} subtitle="Quantitative Vegetation"/>
            </PageHeader>
            <PageContent>
                <Row xs={12}>
                    <Col>
                        <TileList
                            className="tile-list-quant"
                            data={filteredTools}
                            tileUrl={getTileRoute}
                            showComplete
                            xs={12}
                            sm={6}
                            lg={4}
                        />
                    </Col>
                </Row>
                <ConflictResolutionDialog
                    show={showResolve}
                    onHide={() => setShowResolve(false)}
                    onDiscard={() => {
                        dispatch(resolveConflict(transect.syncConflict, 'Discard'));
                        setShowResolve(false);
                    }}
                    onKeep={() => {
                        dispatch(resolveConflict(transect.syncConflict, 'Keep'));
                        setShowResolve(false);
                    }}
                    transect={transect}
                />
            </PageContent>
            <PageFooter>
                <Row xs={3}>
                    <Col>
                        <Link to={`/transect-review/${transect.transectId}/transect-narrative`}>
                            <Button className="w-100" variant={allToolsCompleted ? 'complete' : 'primary'}>
                                {
                                    allToolsCompleted ?
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} /> :
                                        <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                }
                                Transect Review
                            </Button>
                        </Link>
                    </Col>
                    {
                        hasConflict ?
                            <Col>
                                <Button className="w-100" variant={'warning'} onClick={() => setShowResolve(true)}>
                                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="mr-1"/>
                                    Resolve Conflict
                                </Button>
                            </Col> : null
                    }
                </Row>
            </PageFooter>
        </PageContainer>
    );
};

export default QuantVegToolPage;
