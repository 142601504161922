import React, {Fragment, useState} from 'react';
import {useDispatch} from "react-redux";
import {Col, Row} from "react-bootstrap";
import FormInput from "../../Common/FormInput";
import {useGetUserFacingSpeciesName} from "../../../Hooks/LabelHooks";
import {
    CLEAR_SELECTED_INPUT,
    OPEN_SPECIES_PICKER,
    SET_REPLACE,
    SET_SELECTED_INPUT,
    SET_SELECTED_SPECIES
} from "../../../Redux/Actions/Types/transectActionTypes";
import {TYPE_INVASIVE_COVER, TYPE_LENGTH_OF_COVER, TYPE_TREE_DBH} from "../../../Constants/lengthCategories";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import {validateBetween, validateLessThan, validateLessThanOrEquals} from "../../../Hooks/FormHooks";
import NotesModal from "../../Common/NotesModal";
import {updateSpecies} from "../../../Redux/Actions/transectActions";
import SpeciesTypeIcon from "../../CustomIcons/SpeciesTypeIcon";
import HydrozoneSpeciesCell from "./HydrozoneSpeciesCell";

const HydrozoneSpeciesRow = props => {
    
    const { 
        type,
        item,
        disabled,
        plotLength,
        species,
        categories
    } = props;
    
    const dispatch = useDispatch();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();
    
    const [showNotes, setShowNotes] = useState(false);

    const speciesId = item.speciesId;
    const speciesTypeId = species?.speciesTypeId ?? 5;
    // TODO: SB-2599 we could maybe show these unknown species by adding speciesName as a virtual field to the HydrozoneSpecies model? 
    const speciesName = getUserFacingSpeciesName(species) ?? item.speciesName ?? `Species ID ${speciesId}`;
    const isInvasiveCover = type === TYPE_INVASIVE_COVER;

    const selectItem = (item, code) => {
        dispatch({
            type: SET_SELECTED_INPUT,
            selectedInput: item,
            selectedField: code,
        });
        dispatch({type: SET_REPLACE, setReplace: true});
    };

    const getValidationMessage = (value) => {
        const number = value ? Number(value) : 0;

        if (isInvasiveCover) {
            return validateBetween(number, 0, 100);
        }

        if (type === TYPE_TREE_DBH) {
            return validateLessThan(number, 500)
        }

        return validateLessThan(number, 3000);
    };

    const getRowValidationMessage = () => {
        if(species?.isHidden) {
            return "Species not available in custom species list.";
        }
        
        if (type === TYPE_LENGTH_OF_COVER) {
            const rowValue = categories.reduce((acc, curr) => {
                return acc + (item[curr.code] ?? 0);
            }, 0);

            return validateLessThanOrEquals(rowValue, plotLength);
        }

        return null;
    };
    

    return (
        <Fragment>
            <NotesModal
                title="Notes"
                notes={[
                    {
                        label: 'Species Notes',
                        value: item.notes,
                        save: (value) => dispatch(updateSpecies(type, item.speciesId, speciesName, value))
                    },
                ]}
                show={showNotes}
                onHide={() => setShowNotes(false)}
                readOnly={disabled}
            />
            <Row xs={12}>
                <Col xs={isInvasiveCover ? 6 : 3}>
                    <FormInput
                        type="text"
                        value={speciesName ?? ""}
                        className={isInvasiveCover ? "" : ("species-type-id-" + (speciesTypeId))}
                        prefix={<SpeciesTypeIcon speciesTypeId={speciesTypeId} />}
                        onClick={() => {
                            selectItem(item, 'species');
                            dispatch({type: SET_SELECTED_SPECIES, speciesId: speciesId});
                            dispatch({type: OPEN_SPECIES_PICKER});
                        }}
                        validationMessage={getRowValidationMessage()}
                        disabled={disabled}
                        readOnly
                    />
                    {
                        isInvasiveCover &&
                        <CircledIcon
                            icon={item.notes ? ['fas', 'clipboard-list'] : ['fal', 'clipboard']}
                            className="btn-notes"
                            circleSize="12"
                            iconTransform="shrink-2 right-1"
                            onClick={() => {
                                selectItem(item, 'species');
                                setShowNotes(true);
                            }}
                        />
                    }
                </Col>
                <Col>
                    <Row xs={12}>
                        {
                            categories?.map(category =>
                                (
                                    <HydrozoneSpeciesCell
                                        key={category.id}
                                        value={item[category.code]}
                                        category={category}
                                        onFocus={() => selectItem(item, category.code)}
                                        onBlur={() => dispatch({ type: CLEAR_SELECTED_INPUT })}
                                        validationMessage={value => getValidationMessage(value)}
                                        disabled={disabled}
                                    />
                                ))
                        }
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default HydrozoneSpeciesRow;
