/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import DateUtils from "./DateUtils";
import {HIERARCHY_TYPE_PROGRAM, HIERARCHY_TYPE_SAMPLE_EVENT} from "../Constants/hierarchy";
import ArrayUtils from "./ArrayUtils";

export const sortByProperty = (propertyName, defaultValue = 0) =>
	(a, b) => (a?.[propertyName] ?? defaultValue) - (b?.[propertyName] ?? defaultValue);

export const sortByPropertyDesc = (propertyName, defaultValue = 0) =>
	(a, b) => (b?.[propertyName] ?? defaultValue) - (a?.[propertyName] ?? defaultValue);

// these compute to 0 if the hierarchyNode doesn't have
// a sampleEventPhaseId, startDate or procedureId
export const sortByStartDateDesc = (a, b) => {
	if(a?.hierarchyTypeId !== HIERARCHY_TYPE_SAMPLE_EVENT) {
		return 0;
	}
	return DateUtils.CompareDesc(a?.sampleEventStartDate, b?.sampleEventStartDate);
}

export const sortByStartDateAsc = (a, b) => {
	if(a?.hierarchyTypeId !== HIERARCHY_TYPE_SAMPLE_EVENT) {
		return 0;
	}
	return DateUtils.CompareAsc(a?.sampleEventStartDate, b?.sampleEventStartDate);
}

export const sortByProcedureId = sortByProperty('procedureId');

export const sortByHierarchyNodeName = (a, b) => {
	let userFacingNameA = a?.shortName, userFacingNameB = b?.shortName;

	// Programs are sorted by name, everything under programs sorted by shortName
	// This is corresponding to how they are displayed on the offline data page
	if (a.hierarchyTypeId === HIERARCHY_TYPE_PROGRAM) {
		userFacingNameA = a?.name ?? '';
		userFacingNameB = b?.name ?? '';
	}
	return userFacingNameA?.localeCompare(userFacingNameB, 'en', {numeric: true});
}

const sampleEventSortCriteria = [
	sortByPropertyDesc('sampleEventPhaseId'),
	sortByStartDateAsc,
	sortByPropertyDesc('procedureId'),
];

export const sortSampleEvents = (sampleEvents) =>
	ArrayUtils.SortByMultipleCriteria(
		sampleEvents,
		[
			sortByProperty('sampleEventPhaseId'),
			sortByStartDateDesc,
			sortByProperty('procedureId'),
		]);

export const sortSampleEventsDesc = (sampleEvents) =>
	ArrayUtils.SortByMultipleCriteria(
		sampleEvents,
		[
			sortByPropertyDesc('sampleEventPhaseId'),
			sortByStartDateAsc,
			sortByPropertyDesc('procedureId'),
		]);

export const sortHierarchyNodes = (hierarchyNodes) =>
	ArrayUtils.SortByMultipleCriteria(
		hierarchyNodes ?? [],
		[
			sortByProperty('hierarchyTypeId'),
			sortByProperty('sampleEventPhaseId'),
			sortByStartDateAsc,
			sortByProperty('procedureId'),
			sortByHierarchyNodeName
		]);

export const sortSampleEventsByProgress = (a, b) => {
	
	if (a.progress === b.progress) {
		return 0;
	}
	
	if (isNaN(a.progress)) {
		return 1;
	}
	
	if (a.progress === 0 || b.progress === 100) {
		return -1;
	}
	
	if (a.progress === 100 || b.progress === 0) {
		return 1;
	}
	
	// this means they are both in progress
	return 0;
};
