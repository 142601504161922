import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer} from 'redux-persist';
import storage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import ajaxReducer from './Reducers/ajaxReducer';
import contextReducer from './Reducers/contextReducer';
import errorReducer from './Reducers/errorReducer';
import loginReducer from './Reducers/loginReducer';
import userReducer from './Reducers/userReducer';
import hierarchyNodeReducer from './Reducers/hierarchyReducer';
import toolReducer from './Reducers/toolReducer';
import appStatusReducer from "./Reducers/appStatusReducer";
import offlineDataReducer from "./Reducers/offlineDataReducer";
import transectReducer from "./Reducers/transectReducer";
import syncReducer from "./Reducers/syncReducer";
import {RESTORE_BACKUP} from "./Actions/Types/offlineDataActionTypes";
import {storageErrorHandler} from "../Utils/storageErrorHandler";

const reducer = combineReducers({
    userState: userReducer,
    loginState: loginReducer,
    contextState: contextReducer,
    ajaxState: ajaxReducer,
    errorState: errorReducer,
    toolState: toolReducer,
    hierarchyNodeState: hierarchyNodeReducer,
    appStatusState: appStatusReducer,
    offlineDataState: offlineDataReducer,
    transectState: transectReducer,
    syncState: syncReducer,
});

const persistedReducer = persistReducer({
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2, // https://github.com/rt2zz/redux-persist#state-reconciler
    writeFailHandler: storageErrorHandler,
}, reducer);

const actionSanitizer = (action) => (
    action.type === RESTORE_BACKUP ? { type: action.type } : action
);

const stateSanitizer = (state) => state;

// Redux devtools extension: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    trace: true,
    traceLimit: 25,
    actionSanitizer,
    stateSanitizer
}) || compose;

export default createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
));
