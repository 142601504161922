import {
    TYPE_STEM_TALLY,
    TYPE_LENGTH_OF_COVER,
    TYPE_TREE_DBH,
    TYPE_INVASIVE_COVER,
    hasNoPlantsFieldNames,
} from '../Constants/lengthCategories';
import {LIST_INVASIVE_GROUND_COVER, LIST_NON_INVASIVE_TREES, LIST_NON_INVASIVE_WOODY} from "../Constants/species";

export default class LengthCategoryUtils {
    
    static GetSpeciesListTypeByType(type) {
        switch (type) {
            case TYPE_STEM_TALLY:
                return LIST_NON_INVASIVE_WOODY;
            case TYPE_LENGTH_OF_COVER:
                return LIST_NON_INVASIVE_WOODY;
            case TYPE_TREE_DBH:
                return LIST_NON_INVASIVE_TREES;
            case TYPE_INVASIVE_COVER:
                return LIST_INVASIVE_GROUND_COVER;
        }
    }
    
    static GetNoPlantsFieldName(type, plotTypeId) {
        const fieldNamePrefix = hasNoPlantsFieldNames[type] ?? null;
        if(fieldNamePrefix === null) { return null; }
        return fieldNamePrefix + String(plotTypeId ?? '');
    }
    
    static GetNoPlantsValue(hydrozone, type, plotTypeId) {
        const noPlantsFieldName = this.GetNoPlantsFieldName(type, plotTypeId)
        return hydrozone[noPlantsFieldName] ?? hydrozone[this.GetLegacyNoPlantsFieldName(plotTypeId)] ?? false;
    }

    static GetLegacyNoPlantsFieldName(plotNumber) {
        return 'hasNoPlantsPlot' + (Number(plotNumber) === 2 ? '2' : '1');
    }
}
