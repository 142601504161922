export const SHOW_SYNC_DIALOG = 'sync/SHOW_SYNC_DIALOG';
export const HIDE_SYNC_DIALOG = 'sync/HIDE_SYNC_DIALOG';
export const ENABLE_NO_SLEEP = 'sync/ENABLE_NO_SLEEP';
export const DISABLE_NO_SLEEP = 'sync/DISABLE_NO_SLEEP';
export const SET_PHOTO_QUALITY = 'sync/SET_PHOTO_QUALITY';
export const SET_AUTO_UPLOAD_ID = 'sync/SET_AUTO_UPLOAD_ID';
export const UNSET_AUTO_UPLOAD_ID = 'sync/UNSET_AUTO_UPLOAD_ID';
export const SET_CHECKING_FOR_DATA = 'sync/SET_CHECKING_FOR_DATA';
export const SET_STEP = 'sync/SET_STEP';
export const SET_PROCESS = 'sync/SET_PROCESS';
export const SET_UPLOAD_PROGRESS = 'sync/SET_UPLOAD_PROGRESS';
export const SET_DOWNLOAD_PROGRESS = 'sync/SET_DOWNLOAD_PROGRESS';
export const INCREMENT_PROGRESS = 'sync/INCREMENT_PROGRESS';
export const DECREASE_TOTAL_PROGRESS = 'sync/DECREASE_TOTAL_PROGRESS';
export const RESET_PROGRESS = 'sync/RESET_PROGRESS';
export const ADD_SYNC_ERRORS = 'sync/ADD_SYNC_ERRORS';
export const ADD_FAILED_PHOTO_UPLOAD = 'sync/ADD_FAILED_PHOTO_UPLOAD';
export const ADD_FAILED_PHOTO_DELETE = 'sync/ADD_FAILED_PHOTO_DELETE';
export const ABORT_SYNC = 'sync/ABORT_SYNC';
