import React from 'react';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import DateUtils from "../../../Utils/DateUtils";

import TileList from '../../Common/TileList';
import Title from "../../Layout/TitleComponent";
import SidebarContent from "../../Layout/Sidebar/SidebarContent";
import CollapsibleSidebar from "../../Layout/Sidebar/CollapsibleSidebar";
import ArrayUtils from "../../../Utils/ArrayUtils";
import {useShortenProjectName} from "../../../Hooks/TextHooks";
import {selectSampleEventsWithProgress} from "../../../Redux/Selectors/sampleEventProgressSelectors";
import {sortSampleEventsByProgress} from "../../../Utils/SortUtils";

const Sidebar = props => {
    const sampleEventsWithProgress = useSelector(selectSampleEventsWithProgress);
    const shortenProjectName = useShortenProjectName();

    let sampleEventTiles = sampleEventsWithProgress
        .filter(event => !(event.isComplete && !event.offlineModifiedDate))
        .map(event => ({
            ...event,
            title: shortenProjectName(event.projectName),
            subtitle: event.sampleEventPhaseName,
            caption: DateUtils.FormatToLocalDate(event.startDate),
            leftIcon: <FontAwesomeIcon icon={event.procedureIcon} />,
        }));
    
    sampleEventTiles = ArrayUtils.SortByMultipleCriteria(sampleEventTiles, [
            sortSampleEventsByProgress,
            // (a,b) => (a.progress === b.progress ? 0 : a.priority === b.priority ? 0 : (a.priority > b.priority ? 1 : -1)),
            (a,b) => (a.sampleEventPhaseId === b.sampleEventPhaseId ? 0 : (a.sampleEventPhaseId > b.sampleEventPhaseId ? -1 : 1)),
            (a,b) => DateUtils.CompareAsc(a.startDate, b.startDate),
        ]);
    
    const hideDashboardSidebar = useSelector(state => state.appStatusState.hideDashboardSidebar);
    const hide = !!(hideDashboardSidebar || !sampleEventTiles.length);

    return (
        <CollapsibleSidebar className="dashboard-sidebar" hide={hide}>
            <Title title="Scheduled Events" />
            <SidebarContent>
                <TileList
                    className="tile-list-dashboard-sidebar"
                    colSize="12"
                    data={sampleEventTiles}
                    primaryKey="sampleEventId"
                    tileUrl={(item) => `/event/${item.sampleEventId}/${item.procedureCode}`}
                    ellipsis
                />
            </SidebarContent>
        </CollapsibleSidebar>
    )
};

export default Sidebar;
