import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, FormCheck} from "react-bootstrap";

import FormInput from "../../Common/FormInput";

const HydrozoneRow = props => {
    const inputRef = useRef(null);

    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (!props.active) {
            props.setLength(0);
        } else if (changed && !props.readOnly) {
            // Select text in input if active
            inputRef.current.select();
        }
    }, [props.active]);

    const onCheckboxChange = () => {
        setChanged(true);
        props.setActive(!props.active);
    };

    return (
        <Row xs={12} className="hydrozone-row">
            <Col xs={4}>
                <FormCheck
                    id={`${props.title}-length`}
                    label={props.title}
                    type="checkbox"
                    value={props.active}
                    checked={props.active}
                    onChange={() => !props.readOnly ? onCheckboxChange() : null}
                    disabled={props.disabled}
                />
            </Col>
            <Col xs={8}>
                <FormInput
                    forwardedRef={inputRef}
                    label="Start (ft)"
                    type="text"
                    inputmode="decimal"
                    pattern="\d+(\.\d+)?"
                    value={props.length}
                    onChange={value => props.setLength(value)}
                    onClick={() => !props.readOnly ? inputRef.current.select() : null}
                    suffix="ft"
                    validationMessage={props.validationMessage}
                    disabled={!props.active || props.disabled}
                    required
                />
                <span className="end-length-desc">
                    {
                        props.endLength && props.active &&
                            `End ${props.endLength} ft.`
                    }
                </span>
            </Col>
        </Row>
    );
};

export default HydrozoneRow;
