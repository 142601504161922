/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {createSelector} from "reselect";
import {answersAreValidForFields, repeaterParameters} from "../../Hooks/SurveyHooks";
import {selectSampleEventSurveys} from "./surveySelectors";
import {qualitativeVegetationMonitoringsSelector} from "./qualitativeSelectors";

/**
 * Takes an array of surveys (that are relevant for a given set of answers)
 * and the related set of answers, and mixes in the progress fields
 *      progress,
 * 		progressNumerator,
 * 		progressDenominator,
 * 		progressComplete (bool)
 * @param surveys
 * @param answers
 * @returns {*}
 */
const getSurveysWithProgress = (surveys, answers) => {
	return surveys?.map(survey => {
		const hasValue = (answer) => answer !== '' && answer !== null;
		let progressNumerator = 0, progressDenominator = 0, progress = 0;
		
		if (answers) {
			survey.SurveySections.forEach(section => {
				section.SurveyQuestions.forEach(question => {
					if (question?.isRequired ?? true) {
						const answer = answers[question.code];
						const values = question.options?.values;
						const valueObject = values && values.length ? values.find(({ value }) => String(value) === String(answer)) : null;
						
						switch (question.surveyQuestionDataTypeId) {
							// Checkbox list
							case 15:
								progressDenominator++;
								let isChecked = false;
								
								values.forEach(value => {
									const checkboxAnswer = !!answers[value.code];
									if (hasValue(checkboxAnswer) && checkboxAnswer === true) {
										isChecked = true;
									}
								});
								
								if (isChecked) {
									progressNumerator++;
								}
								break;
							// Repeater
							case 17:
								progressDenominator++;
								const repeaterAnswers = answers.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === question.code);
								const repeaterParams = repeaterParameters(question.code);
								
								if (answersAreValidForFields(repeaterAnswers, repeaterParams.fields)) {
									progressNumerator++;
								}
								break;
							// Radio
							case 13:
								if (valueObject?.secondaryFields) {
									valueObject.secondaryFields.forEach(field => {
										progressDenominator++;
										
										switch (field.type) {
											case 'monitoring-repeater':
												const repeaterAnswers = answers.QualitativeVegetationMonitoringRepeaters.filter(repeater => repeater.code === field.code);
												const repeaterParams = repeaterParameters(field.code);
												
												if (answersAreValidForFields(repeaterAnswers, repeaterParams.fields)) {
													progressNumerator++;
												}
												break;
											case 'monitoring-recommendations':
												if (answers.QualitativeVegetationMonitoringRecommendations.find(recommendation => recommendation.code === field.code)) {
													progressNumerator++;
												}
												break;
											default:
												const secondaryAnswer = answers[field.code];
												if (hasValue(secondaryAnswer)) {
													progressNumerator++;
												}
										}
									});
									break;
								}
							//fallthrough
							default:
								progressDenominator++;
								
								if (hasValue(answer)) {
									progressNumerator++;
								}
						}
					}
				})
			});
			
			progress = progressNumerator / progressDenominator * 100;
		}
		
		return {
			...survey,
			progress,
			progressNumerator,
			progressDenominator,
			progressComplete: progress === 100,
		}
	})
		.sort((a, b) => a.order > b.order ? 1 : -1);
}

/**
 * Same as getSurveysWithProgress, but returns the surveys
 * sorted appropriately.
 * @param surveys
 * @param answers
 * @returns {*}
 */
const getSortedSurveysWithProgress = (surveys, answers) =>
	getSurveysWithProgress(surveys, answers)
		.sort((a, b) => a.order > b.order ? 1 : -1);

/**
 * Returns an Object keyed by sampleEventId, for which
 * each value contains an array of surveys with progress.
 */
export const selectSurveysWithProgress = createSelector(
	[selectSampleEventSurveys, qualitativeVegetationMonitoringsSelector],
	(sampleEventSurveys, qualitativeVegetationMonitorings) =>
		Object.fromEntries(
			Object.entries(sampleEventSurveys)?.map(([sampleEventId, surveys]) => {
				const answersForSampleEvent = qualitativeVegetationMonitorings?.find(
					qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.sampleEventId) === String(sampleEventId)
				);
				return [
					sampleEventId,
					getSortedSurveysWithProgress(surveys, answersForSampleEvent)
				];
			}) ?? []
		)
);

/**
 * Returns an array of surveys with progress for the specified sampleEventId
 */
export const selectSurveysWithProgressBySampleEventId = createSelector(
	[selectSurveysWithProgress, (state, sampleEventId) => sampleEventId],
	(surveysWithProgress, sampleEventId) =>
		surveysWithProgress[sampleEventId] ?? []
)
