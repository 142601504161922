/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {photoPointWasActiveDuringSampleEvent} from "../../Hooks/PhotoHooks";
import {createSelector} from "reselect";
import {selectCameraPointsWithPhotoPointHistory, selectPhotos} from "./photoSelectors";
import {selectAvailableSampleEvents} from "./nodeSelectors";

const isPhotoPointComplete = (photoPoint, sampleEvent, photos) => {
	// all deactivated photo points count as complete
	if (!photoPointWasActiveDuringSampleEvent(photoPoint, sampleEvent)) {
		return true;
	}
	
	// otherwise the photoPoint is complete if it has atleast one photo
	return photos.some(photo => (
		String(photo.photoPointId) === String(photoPoint.photoPointId) &&
		String(sampleEvent.sampleEventId) === String(photo.sampleEventId) &&
		!(photo.deleted ?? null)
	));
}

const cameraPointsWithProgress = (sampleEvent, cameraPoints, photos) => {
	return cameraPoints?.map(point => {
		const photoPointIds = (point?.PhotoPoints ?? [])
			.map(photoPoint => photoPoint.photoPointId);
		
		const completedPhotoPointIds = (point?.PhotoPoints ?? [])
			.filter(photoPoint => isPhotoPointComplete(photoPoint, sampleEvent, photos))
			.map(photoPoint => photoPoint.photoPointId);
		
		return {
			...point,
			title: `Point ${point.name}`,
			progress: photoPointIds.length === 0 ? 0 : (completedPhotoPointIds.length / photoPointIds.length * 100),
			progressNumerator: completedPhotoPointIds.length,
			progressDenominator: photoPointIds.length,
		}
	});
}

export const selectCameraPointsWithProgress = createSelector(
	[selectAvailableSampleEvents, selectCameraPointsWithPhotoPointHistory, selectPhotos],
	(sampleEvents, cameraPoints, photos) => {
		return Object.fromEntries(
			sampleEvents?.map((sampleEvent) => {
					const cameraPointsForSampleEvent = cameraPoints?.filter(cameraPoint => String(cameraPoint.projectId) === String(sampleEvent.projectId)) ?? [];
					return [
						sampleEvent.sampleEventId,
						cameraPointsWithProgress(sampleEvent, cameraPointsForSampleEvent, photos)
					];
				}
			) ?? []
		);
	}
);

/**
 * Returns an array of surveys with progress for the specified sampleEventId
 */
export const selectCameraPointsWithProgressBySampleEventId = createSelector(
	[selectCameraPointsWithProgress, (state, sampleEventId) => sampleEventId],
	(cameraPointsWithProgress, sampleEventId) =>
		cameraPointsWithProgress[sampleEventId] ?? []
)
