import * as sourceStackTrace from 'sourcemapped-stacktrace';
import {SET_ERROR} from "../Redux/Actions/Types/errorActionTypes";
import {useDispatch} from "react-redux";

export const useShowPermissionDenied = () => {
    const dispatch = useDispatch();

    return () => {
        dispatch({
            type: SET_ERROR,
            error: {
                Code: 403,
                Message: 'Permission Denied',
                Title: 'Forbidden',
            }
        });
    };
};

export const useDefaultErrorHandler = () => {
    const dispatch = useDispatch();

    return (error) => {
        if (error?.xhr) {
            dispatch({
                type: SET_ERROR,
                error: {
                    Code: error?.xhr?.status,
                    Message: error?.message,
                    Title: error?.xhr?.statusText,
                    StackTrace: error?.data?.stackTrace
                }
            });
        } else if (error instanceof Error) {
            throw error;
        }
    }
};

/**
 * @returns {Promise<string[]>}
 * @param stack
 * @param options
 */
export const getMinifiedStackTrace = async (stack, options = undefined) => {
    return new Promise((resolve, reject) => {
        const onDone = (mappedStack) => {
            if (mappedStack) {
                resolve(mappedStack);
            } else {
                reject(new Error('Failed to map stack trace'));
            }
        }
        sourceStackTrace.mapStackTrace(stack, onDone, options);
    });
};
