import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import './icons';
import MonitoringApp from './MonitoringApp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store from './Redux/store';
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Config from "./Config";
import { BrowserRouter } from 'react-router-dom';
import {DOMAIN, ENVIRONMENT} from "./Constants";
import ApiUtils from "./Utils/ApiUtils";
import {OPEN_UPDATE_MODAL} from "./Redux/Actions/Types/appStatusTypes";
import {useForceReload, useSkipWaiting} from "./Hooks/ServiceWorkerHooks";
import {storageErrorHandler} from "./Utils/storageErrorHandler";

// temporary shim to support iOS12
Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
    promises.map(p => p
        .then(value => ({
            status: "fulfilled",
            value
        }))
        .catch(reason => ({
            status: "rejected",
            reason
        }))
    )
));

Config.setValues({
    store,
    domain: DOMAIN,
    environment: ENVIRONMENT,
});

// This needs to be set after we set the domain and environment
// So that we can set the baseURL for axios instance
Config.setAxiosInstances({
    default: ApiUtils.CreateSBInstance(),
    cancellable: ApiUtils.CreateSBCancellableInstance(),
    external: ApiUtils.CreateInstance(),
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <PersistGate loading={null} persistor={persistStore(store)}>
                <MonitoringApp />
            </PersistGate>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: () => {
        if (ENVIRONMENT === 'local') {
            const skipWaiting = useSkipWaiting();
            const forceReload = useForceReload();
            console.log('reloading...');
            skipWaiting().then(forceReload);
            return;
        }

        store.dispatch({type: OPEN_UPDATE_MODAL});
    }
});

// event handler to handle
// Connection to Indexed Database server lost. Refresh the page to try again
// error
window.addEventListener('error', error => {
    console.log(error);
    storageErrorHandler(error, true);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
