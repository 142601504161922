import React, {Fragment, useEffect, useRef, useState} from 'react';
import {ModalBody, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Modal from './Modal';
import FormInput from "./FormInput";
import {useGetUserFacingSpeciesName} from "../../Hooks/LabelHooks";
import {useDispatch, useSelector} from "react-redux";
import {addSpecies, updateSpecies} from "../../Redux/Actions/transectActions";
import {
    CLOSE_SPECIES_PICKER, OPEN_SPECIES_PICKER,
    SET_SELECTED_SPECIES
} from "../../Redux/Actions/Types/transectActionTypes";
import DuplicateSpeciesModal from "../Pages/QuantVegTools/DuplicateSpeciesModal";
import {updateRepeaterItem} from "../../Redux/Actions/surveyActions";
import SpeciesTypeIcon from "../CustomIcons/SpeciesTypeIcon";
import {useNextTempId} from "../../Hooks/TempIdHooks";
import {ADD_PROJECT_SPECIES} from "../../Redux/Actions/Types/offlineDataActionTypes";
import {selectAllSpecies, selectSpeciesList} from "../../Redux/Selectors/speciesSelectors";

const SpeciesPickerModal = props => {
    const dispatch = useDispatch();
    const nextId = useNextTempId();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();

    const allSpecies = useSelector(state => selectAllSpecies(state, props.projectId));
    
    const {showSpeciesPicker, selectedSpecies, answerId, repeaterId, idColumn, nameColumn, species: reduxSpeciesListType } = useSelector(state => state.transectState);
    
    const speciesListType = reduxSpeciesListType ?? props.listType;
    const speciesList = useSelector(state => selectSpeciesList(state, props.projectId, speciesListType, selectedSpecies));

    const [search, setSearch] = useState('');
    const [filteredSpecies, setFilteredSpecies] = useState([]);
    const [otherSpecies, setOtherSpecies] = useState('');
    const [otherDuplicate, setOtherDuplicate] = useState(null);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    useEffect(() => {
        setSearch('');
        setOtherSpecies('');
        setOtherDuplicate(null);
        setShowDuplicateModal(false);
        if(!showSpeciesPicker) {
            dispatch({type: SET_SELECTED_SPECIES, speciesId: undefined});
        }
    }, [showSpeciesPicker]);

    useEffect(() => {
        if (search) {
            setFilteredSpecies(speciesList.filter(item => {
                const term = search.toLowerCase();
                const commonName = item.commonName?.toLowerCase() ?? '';
                const latinName = item.latinName?.toLowerCase() ?? '';
                const alternativeCommonName = item.alternativeCommonName?.toLowerCase() ?? '';
                const alternativeLatinName = item.alternativeLatinName?.toLowerCase() ?? '';
                const otherSearchTerms = item.otherSearchTerms?.toLowerCase() ?? '';

                return commonName.includes(term) || latinName.includes(term)
                    || alternativeLatinName.includes(term) || alternativeCommonName.includes(term)
                    || otherSearchTerms.includes(term);
            }));
        } else {
            setFilteredSpecies(speciesList);
        }
    }, [search, speciesList]);

    useEffect(() => {
        const duplicateSpecies = allSpecies.find(item => 
            (item.latinName?.toLowerCase() ?? '') === (otherSpecies?.toLowerCase() ?? '') ||
            (item.commonName?.toLowerCase() ?? '') === (otherSpecies?.toLowerCase() ?? '') ||
            (item?.alternativeLatinName?.toLowerCase() ?? '') === (otherSpecies?.toLowerCase() ?? '') ||
            (item?.alternativeCommonName?.toLowerCase() ?? '') === (otherSpecies?.toLowerCase() ?? '')
        );
        if (otherSpecies && duplicateSpecies) {
            setOtherDuplicate(duplicateSpecies);
        } else {
            setOtherDuplicate(null);
        }
    }, [otherSpecies]);

    const pickSpecies = (chosenSpecies) => {

        // qualitative vegetation repeaters
        if (answerId && repeaterId) {
            if (idColumn) {
                dispatch(updateRepeaterItem(answerId, repeaterId, idColumn, chosenSpecies.speciesId));
            }
            if (nameColumn) {
                dispatch(updateRepeaterItem(answerId, repeaterId, nameColumn, getUserFacingSpeciesName(chosenSpecies)));
            }
        }

        // todo: refactor this to state
        if (props.hydrozoneSpecies) {
            upsertHydrozoneSpecies(chosenSpecies);
        } else {
            props?.onSelect?.(chosenSpecies);
            onHide();
        }
    };

    const upsertHydrozoneSpecies = (chosenSpecies) => {
        const speciesInUse = props.hydrozoneSpecies
            .filter(species => String(species.speciesId) !== String(selectedSpecies))
            .map(species => String(species.speciesId));
        if (!speciesInUse.includes(String(chosenSpecies.speciesId))) {
            if (selectedSpecies) {
                dispatch(updateSpecies(props.type, chosenSpecies.speciesId, getUserFacingSpeciesName(chosenSpecies)));
            } else {
                dispatch(addSpecies(chosenSpecies.speciesId, getUserFacingSpeciesName(chosenSpecies), props.type));
            }
            onHide();
        } else {
            setShowDuplicateModal(true);
        }
    };

    const addOtherSpecies = () => {
        if (otherDuplicate) {
            pickSpecies(otherDuplicate);
        } else {
            const newSpecies = {
                speciesId: nextId(),
                commonName: otherSpecies,
                latinName: otherSpecies,
                speciesName: otherSpecies,
                speciesInvasivityId: 0,
                speciesTypeId: 5,
            };

            dispatch({type: ADD_PROJECT_SPECIES, projectId: props.projectId, newSpecies});
            pickSpecies(newSpecies);
        }
    };

    const onHide = () => {
        dispatch({type: CLOSE_SPECIES_PICKER});
    };

    return (
        <Fragment>
            <DuplicateSpeciesModal
                show={showDuplicateModal}
                onHide={() => {
                    setShowDuplicateModal(false);
                    dispatch({type: OPEN_SPECIES_PICKER});
                }}
            />
            <Modal
                title={`Choose ${speciesListType} Species`}
                size="md"
                show={!showDuplicateModal && showSpeciesPicker}
                className="species-picker"
                onHide={() => onHide()}
                centered={false}
            >
                <ModalBody>
                    <Row xs={12}>
                        <Col xs={12}>
                            <FormInput
                                label="Search"
                                value={search}
                                onChange={value => setSearch(value)}
                                suffix={<FontAwesomeIcon icon={['fal', 'times']} onClick={() => {
                                    setSearch('');
                                    setOtherSpecies('');
                                }} />}
                                placeholder="Search Species"
                            />
                        </Col>
                        <Col xs={12} className="species-list">
                            {
                                !!filteredSpecies.length ?
                                    filteredSpecies.map(item => (
                                        <div className="species-item" key={item.speciesId} onClick={() => pickSpecies(item)}>
                                        <span>
                                            <SpeciesTypeIcon className="species-icon" speciesTypeId={item.speciesTypeId} />
                                            {getUserFacingSpeciesName(item)}
                                        </span>
                                            {
                                                item.speciesId === selectedSpecies &&
                                                <span>Selected</span>
                                            }
                                        </div>
                                    )) :
                                    <div className="no-species-container">
                                        <span className="no-species-message pb-2">
                                            No {speciesListType} species found matching '{search}'.
                                        </span>
                                        <FormInput
                                            value={otherSpecies}
                                            onChange={value => setOtherSpecies(value)}
                                            placeholder="Other Species"
                                        />
                                        {
                                            otherDuplicate &&
                                                <div className="duplicate-warning">
                                                    This species already exists, but is not { otherDuplicate?.isHidden ? "visible in this project's assigned custom species list." : "listed as the appropriate species type for this field."} Use it anyway?
                                                </div>
                                        }
                                        <div>
                                            <Button variant="complete" onClick={() => addOtherSpecies()} disabled={!otherSpecies}>
                                                
                                                { otherDuplicate ? 
                                                    <Fragment>
                                                        <FontAwesomeIcon icon={['fal', 'check']} />
                                                        Confirm Species
                                                    </Fragment> :
                                                    <Fragment>
                                                        <FontAwesomeIcon icon={['fal', 'plus']} />    
                                                        Confirm New Species
                                                    </Fragment>
                                                }
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
};

export default SpeciesPickerModal;
