import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Tile from './Tile';

const TileList = props => {
    
    const {
        xs, sm, md, lg, className,
        ellipsis,
        caption,
        noIcon,
        showComplete,
        actions: actionsCallback,
        subtitle,
        colSize = 4,
        tileUrl,
        onClick,
        tileDetailComponent: TileDetailComponent,
        primaryKey,
        data = []
    } = props;

    const renderTile = (item) => {
        const url = tileUrl ? tileUrl(item) : null;
        const actions = actionsCallback?.(item);

        if (url) {
            return (
                <Link to={url}>
                    <Tile
                        item={item}
                        actions={actions}
                        ellipsis={ellipsis}
                        noIcon={noIcon}
                        showComplete={showComplete}
                    />
                </Link>
            );
        }

        return <Tile
            item={item}
            subtitle={subtitle}
            caption={caption}
            actions={actions}
            ellipsis={ellipsis}
            onClick={() => onClick ? onClick(item) : null}
            noIcon={noIcon}
            showComplete={showComplete}
        />;
    };

    return (
        <Row xs={12} className={"tile-list" + (className ? ` ${className}` : '')}>
            {
                data.map(item => (
                    <Fragment key={item[primaryKey] || item.id}>
                        <Col className={TileDetailComponent ? 'pr-0 tile-list-column' : "tile-list-column"} xs={xs ?? colSize} sm={sm ?? xs ?? colSize} md={md ?? sm ?? colSize} lg={lg ?? md ?? colSize}>
                            {renderTile(item)}
                        </Col>
                        {
                            TileDetailComponent &&
                            <Col xs={12 - colSize} className="tile-details">
                                <TileDetailComponent item={item} />
                            </Col>
                        }
                    </Fragment>
                ))
            }
        </Row>
    )
};

export default TileList;
