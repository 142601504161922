import React, {Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Button, Spinner, FormGroup, FormLabel} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    selectHierarchyNodesAsTree,
    selectHierarchyNodesWithStatus,
    selectTotalDownloadSize
} from '../../../Redux/Selectors/hierarchySelectors';
import {
    ADD_SELECTED_HIERARCHY_NODES,
    CLEAR_SELECTED_HIERARCHY_NODES,
    DELETE_SELECTED_HIERARCHY_NODES,
    SET_HIERARCHY_NODES,
    SET_ORGANIZATION_ID
} from '../../../Redux/Actions/Types/hierarchyActionTypes';
import {
    setHierarchyNodes,
    syncData,
} from "../../../Redux/Actions/downloadActions";

import { useContextTools } from '../../../Hooks/ToolHooks';
import { CONTEXT_TOOL_ACCOUNT } from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';
import FormInput from '../../Common/FormInput';
import ProjectSyncList from './ProjectSyncList';
import SyncModal from "./SyncModal";

import {
    OFFLINE_STATUS_OUT_OF_SYNC,
    OFFLINE_STATUS_READY_FOR_UPLOAD,
    STATUS_LABEL_QUEUED_DOWNLOAD,
    STATUS_LABEL_QUEUED_UPLOAD,
} from "../../../Constants/status";
import {organizations} from "../../../Constants/organizations";
import DeleteNodeModal from "./DeleteNodeModal";
import SizeUtils from "../../../Utils/SizeUtils";
import PageHeader from "../../Layout/Page/PageHeader";
import {Link} from "react-router-dom";
import {
    ENABLE_NO_SLEEP,
    SET_AUTO_UPLOAD_ID,
    SET_PHOTO_QUALITY,
    UNSET_AUTO_UPLOAD_ID
} from "../../../Redux/Actions/Types/syncStatusTypes";
import ConfirmSyncModal from "../../Common/ConfirmSyncModal";
import {DELETE_EVERYTHING} from "../../../Redux/Actions/Types/offlineDataActionTypes";
import DiscardDeviceDataModal from "../../Layout/Modal/DiscardDeviceDataModal";
import {deleteOrphanedPhotoContent} from "../../../Redux/Actions/photoActions";
import {HIERARCHY_TYPE_SAMPLE_EVENT} from "../../../Constants/hierarchy";


const DownloadPage = props => {
    useContextTools([CONTEXT_TOOL_ACCOUNT]);
    const dispatch = useDispatch();
    const {online, offlineMode} = useSelector(state => state.appStatusState);
    const user = useSelector(state => state.userState.user);
    const {autoUploadId, photoQuality} = useSelector(state => state.syncState);
    const {organizationId, selected, loading} = useSelector(state => state.hierarchyNodeState);
    const hierarchyNodesWithStatus = useSelector(state => selectHierarchyNodesWithStatus(state));
    const hierarchyNodesTree = useSelector(state => selectHierarchyNodesAsTree(state));
    const totalDownloadSize = useSelector(state => selectTotalDownloadSize(state));
    const projects = useSelector(state => state.offlineDataState.projects);

    const [formattedDownloadSize, setFormattedDownloadSize] = useState('');
    const [confirmChangeToOrganizationId, setConfirmChangeToOrganizationId] = useState(null);
    const [nodesToDownload, setNodesToDownload] = useState([]);
    const [nodesToUpload, setNodesToUpload] = useState([]);

    useEffect(() => {
        if (!organizationId) {
            dispatch({type: SET_ORGANIZATION_ID, organizationId: user?.organizationId});
        }
    }, []);

    useEffect(() => {
        if (organizationId && !offlineMode && online) {
            dispatch(setHierarchyNodes(organizationId));
        }
    }, [organizationId]);

    useEffect(() => {
        const sizeString = SizeUtils.GetFileSize(totalDownloadSize, 2, false, '~', 1024);
        setFormattedDownloadSize(sizeString);
    }, [totalDownloadSize]);

    useEffect(() => {
        setNodesToDownload(getHierarchyNodeIdsByStatus(STATUS_LABEL_QUEUED_DOWNLOAD));
        setNodesToUpload(getHierarchyNodeIdsByStatus(STATUS_LABEL_QUEUED_UPLOAD));
    }, [hierarchyNodesWithStatus]);

    const getHierarchyNodeIdsByStatus = (status) => {
        return hierarchyNodesWithStatus
            .filter(node => (node.statusLabel === status))
            .map(node => node.hierarchyId);
    };

    const getHierarchyNodesByOfflineState = (status) => {
        return hierarchyNodesWithStatus.filter(node => (node.offlineState === status));
    };

    const toggleChanges = (nodes, type) => {
        nodes.forEach(node => {
            dispatch({
                type,
                hierarchyId: node.programContextNodeId,
                node: node.hierarchyId,
            });
        });
    };

    const flattenSelected = () => {
        return Object.keys(selected).reduce(function (result, key) {
            return result.concat(key, selected[key]);
        }, []);
    };

    const getMainLabel = () => {
        const nodesToUpload = getHierarchyNodesByOfflineState(OFFLINE_STATUS_READY_FOR_UPLOAD);
        const nodesToSync = getHierarchyNodesByOfflineState(OFFLINE_STATUS_OUT_OF_SYNC);
        const allNodes = [...nodesToUpload, ...nodesToSync];

        // only leaf (sample event) nodes are selectable
        const allSelectableNodes = allNodes.filter(node => node.hierarchyTypeId === HIERARCHY_TYPE_SAMPLE_EVENT);
        const allSelectableNodeIds = allSelectableNodes.map(node => node.hierarchyId);

        const hasAllNodesSelected = allSelectableNodeIds.every(id => flattenSelected().includes(id));
        const toggleType = hasAllNodesSelected ? DELETE_SELECTED_HIERARCHY_NODES : ADD_SELECTED_HIERARCHY_NODES;
        const toggleCaption = hasAllNodesSelected ? 'Unselect' : 'Select';

        if (allNodes.length) {
            const noun = allNodes.length > 1 ? 'items' : 'item';

            return (
                <div>
                    <span>
                        <FontAwesomeIcon
                            icon={['fal', 'cloud']}
                            color="red"
                            className="mr-2"
                        />
                        {allNodes.length} {noun} available to sync
                    </span>
                    <Button variant="action" onClick={() => toggleChanges(allSelectableNodes, toggleType)}>
                        {toggleCaption} Changed Items
                    </Button>
                </div>
            )
        }

        return null;
    };

    const performAutoUpload = () => {
        dispatch({type: UNSET_AUTO_UPLOAD_ID});
        dispatch({type: ENABLE_NO_SLEEP});
        dispatch(syncData([autoUploadId], [], organizationId));
    };

    const disableSync = () => {
        return (!getHierarchyNodeIdsByStatus(STATUS_LABEL_QUEUED_DOWNLOAD).length &&
            !getHierarchyNodeIdsByStatus(STATUS_LABEL_QUEUED_UPLOAD).length) ||
            !online ||
            offlineMode ||
            loading ||
            offlineMode;
    };

    const sync = () => {
        dispatch({type: ENABLE_NO_SLEEP});
        dispatch(syncData(nodesToUpload, nodesToDownload, organizationId));
    };

    const changeOrganization = (organizationId, userHasConfirmed = false) => {
        if(!userHasConfirmed && projects.length) {
            // this will show a warning dialog and store
            // the organizationId to use later
            setConfirmChangeToOrganizationId(organizationId);
            return;
        }
        
        dispatch({type: DELETE_EVERYTHING});
        dispatch(deleteOrphanedPhotoContent());
        dispatch({type: SET_HIERARCHY_NODES, hierarchyNodes: []});
        dispatch({type: CLEAR_SELECTED_HIERARCHY_NODES});
        dispatch({type: SET_ORGANIZATION_ID, organizationId: organizationId});
        setConfirmChangeToOrganizationId(null);
    };

    const renderAction = () => {
        if (offlineMode) {
            return <span>
                Sync is unavailable while your device is Offline Mode. <Link to={'/login'}>Go Online</Link> to Sync.
            </span>
        }

        if (!online) {
            return <span>Sync is not available while you are disconnected from the internet.</span>
        }

        return null;
    };

    const renderTitle = () => {
        return (
            <div className="offline-data-title">
                <span>Manage On-Device Data</span>
                {loading && !offlineMode && <Spinner animation="border" size="sm" />}
            </div>
        )
    };

    const organizationCaveat = () => {
        return user?.organizationId === 1 ? ' to data in this organization' : '';
    }

    return (
        <Fragment>
            <SyncModal />
            <ConfirmSyncModal
                show={!!autoUploadId}
                onConfirm={ performAutoUpload }
                onHide={() => { dispatch({type: UNSET_AUTO_UPLOAD_ID}); }}
            />
            <DeleteNodeModal />
            <DiscardDeviceDataModal
                show={!!confirmChangeToOrganizationId}
                onHide={() => setConfirmChangeToOrganizationId(null)}
                action={() => changeOrganization(confirmChangeToOrganizationId, true)}
                changesMessage="You have un-synced changes. Please sync or discard any on-device data before changing organizations."
                dataMessage="When you change organizations, all on-device data will be removed from the device. Are you sure you want to change organizations?"
            />
            <PageContainer className="offline-data-download-page">
                <PageHeader>
                    <Title title={renderTitle()} />
                    <Row xs={12}>
                        <Col xs={12} className="text-center sync-unavailable">{renderAction()}</Col>
                        <Col xs={6} className="data-page-notice">
                            {getMainLabel()}
                        </Col>
                        {
                            online && !offlineMode ?
                                <Col xs={6} className="data-page-actions">
                                    {
                                        user?.organizationId === 1 &&
                                        <FormInput
                                            className="organization"
                                            label="Organization"
                                            type="select"
                                            options={organizations}
                                            primaryKey="organizationId"
                                            value={organizationId || ''}
                                            onChange={value => changeOrganization(value)}
                                            disabled={offlineMode || !online}
                                        />
                                    }
                                    <FormInput
                                        className="photo-quality"
                                        label="Photo Quality"
                                        type="select"
                                        options={[
                                            {'name': 'Original', 'id': ''},
                                            {'name': 'High', 'id': 'large'},
                                            {'name': 'Medium', 'id': 'medium'},
                                            {'name': 'Low', 'id': 'small'},
                                            {'name': 'None', 'id': 'none'},
                                        ]}
                                        value={photoQuality || ''}
                                        onChange={value => dispatch({type: SET_PHOTO_QUALITY, photoQuality: value})}
                                    />
                                    <FormGroup>
                                        <FormLabel>&nbsp;</FormLabel>
                                        <Button
                                            variant="complete"
                                            onClick={() => sync()}
                                            className="btn-sync"
                                            disabled={disableSync()}
                                        >
                                            <FontAwesomeIcon icon={['fal', 'cloud']} />
                                            Sync
                                        </Button>
                                        <div className="total-download-size">
                                            {
                                                (!!nodesToDownload.length || !!nodesToUpload.length) &&
                                                    <Fragment>
                                                        <span>(</span>
                                                        {
                                                            !!nodesToDownload.length &&
                                                            <span>
                                                                <FontAwesomeIcon icon={['fas', 'arrow-down']} transform="shrink-2" />
                                                                {nodesToDownload.length}
                                                            </span>
                                                        }
                                                        {
                                                            !!nodesToUpload.length &&
                                                            <span>
                                                                <FontAwesomeIcon icon={['fas', 'arrow-up']} transform="shrink-2" />
                                                                {nodesToUpload.length}
                                                            </span>
                                                        }
                                                        <span>/</span>
                                                        <span>{formattedDownloadSize}</span>
                                                        <span>)</span>
                                                    </Fragment>
                                            }
                                        </div>
                                    </FormGroup>
                                </Col> :
                                <Col />
                        }
                    </Row>
                </PageHeader>
                <PageContent>
                    {
                        (hierarchyNodesTree.length === 0) ? (
                            (loading && !offlineMode) ?
                                <div className="loading-spinner">
                                    <h2>Loading</h2>
                                    <Spinner animation="border" />
                                </div> :
                                <div className="loading-spinner">
                                    <h2>No Project Assignments</h2>
                                    <div>Please request access{organizationCaveat()} from your</div>
                                    <div>Program or Project Manager.</div>
                                </div>
                            ) :
                            hierarchyNodesTree.map(node => (
                                <ProjectSyncList
                                    key={node.hierarchyId}
                                    node={node}
                                    showSelectedItems
                                    treeLength={hierarchyNodesTree.length}
                                />
                            ))
                    }
                </PageContent>
            </PageContainer>
        </Fragment>
    )
};

export default DownloadPage;
