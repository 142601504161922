import React, { useState, useEffect } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { checkboxTreeIcons } from '../../../Constants/icons';
import {useDispatch, useSelector} from "react-redux";
import {SET_SELECTED_HIERARCHY_NODES} from "../../../Redux/Actions/Types/hierarchyActionTypes";
import {useExpandedNodes} from "../../../Hooks/NodeHooks";

const ProjectSyncList = props => {
    const dispatch = useDispatch();
    const getExpandedNodes = useExpandedNodes();

    const programContextNodeId = props.node.hierarchyId;

    const checked = useSelector(state => state.hierarchyNodeState.selected[programContextNodeId] || []);
    const [expanded, setExpanded] = useState(getExpandedNodes(checked));
    const [treeExpanded, setTreeExpanded] = useState(null);

    useEffect(() => {
        if(treeExpanded === null) {
            setTreeExpanded(props.treeLength === 1 || !!props?.node?.selected || !!expanded.length);
        }
    }, [props.treeLength, expanded]);

    useEffect(() => {
        if (checked.length) {
            setExpanded([...expanded, ...getExpandedNodes(checked)]);
        }
    }, [checked]);

    const onCheck = (values, node) => {
        dispatch({
            type: SET_SELECTED_HIERARCHY_NODES,
            programContextNodeId,
            selected: values.map(value => String(value)),
        });
    };

    if (!props.node.children.length) {
        return null;
    }

    return (
        <div className="project-list mt-3">
            <div className={`tree-header ${!treeExpanded ? 'collapsed' : ''}`} onClick={() => setTreeExpanded(!treeExpanded)}>
                <div>
                    <FontAwesomeIcon icon={['fal', treeExpanded ? 'minus' : 'plus']} />
                    {props.node.name}
                </div>
            </div>
            <div className={`tree-container ${!treeExpanded ? 'hide' : ''}`}>
                <CheckboxTree
                    nodes={props.node.children}
                    checked={checked}
                    expanded={expanded}
                    onCheck={onCheck}
                    onExpand={values => setExpanded(values)}
                    showNodeIcon={false}
                    icons={checkboxTreeIcons}
                    checkModel={props.checkAll ? 'all' : 'leaf'}
                    showNodeTitle
                    optimisticToggle={false}
                />
            </div>
        </div>
    )
};

export default ProjectSyncList;
