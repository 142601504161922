/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {createSelector} from "reselect";
import {selectAvailableSampleEvents} from "./nodeSelectors";
import {qualTools} from "../../Constants/tools";

/**
 * Returns all the on-device Surveys with the `order` and `title`
 * mixed in from the qualTools constant
 * @param state
 * @returns {(*&{title: *, order: *})[]}
 */
const surveySelector = state => {
	return state.offlineDataState.surveys.map(survey => {
		const tool = qualTools.find(tool => tool.id === survey.surveyTypeId);
		return {
			...survey,
			order: tool.id,
			title: tool.title,
		};
	});
}

/**
 * Takes a sampleEvent, and all of the surveys
 * and returns a subset of the surveys which are
 * relevant to the specified SampleEvent.
 * @param sampleEvent
 * @param surveys
 * @returns {*}
 */
const getSampleEventSurveys = (sampleEvent, surveys) => {
	let result;
	const subProgramSurveys = surveys.filter(survey => String(survey.programId) === String(sampleEvent?.subProgramId));
	if (subProgramSurveys.length) {
		result = subProgramSurveys;
	} else {
		result = surveys.filter(survey => String(survey.programId) === String(sampleEvent?.programId));
	}
	return result.sort((a, b) => a.order > b.order ? 1 : -1);
}

/**
 * Returns an object keyed by sampleEventId, for which
 * each value is an array of surveys used by that SampleEvent
 */
export const selectSampleEventSurveys = createSelector(
	[selectAvailableSampleEvents, surveySelector],
	(sampleEvents, surveys) => {
		return Object.fromEntries(
			sampleEvents?.map(sampleEvent => [
				sampleEvent.sampleEventId,
				getSampleEventSurveys(sampleEvent, surveys)
			]) ?? []
		);
	}
);

/**
 * Returns an array of surveys used by the specified SampleEvent
 */
export const selectSurveysBySampleEventId = createSelector(
	[selectSampleEventSurveys, (state, sampleEventId) => sampleEventId],
	(sampleEventSurveys, sampleEventId) =>
		sampleEventSurveys[sampleEventId]
);
