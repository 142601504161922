import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {Link, useParams} from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {selectCameraPointsWithPhotoPointHistory} from "../../../Redux/Selectors/photoSelectors";
import {selectSampleEventById} from "../../../Redux/Selectors/nodeSelectors";
import {useContextTools, useContextToolsBySampleEventId} from '../../../Hooks/ToolHooks';
import {CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_BOOK, CONTEXT_TOOL_GLOBE} from '../../../Constants/tools';

import PageContent from '../../Layout/Page/PageContent';
import Title from '../../Layout/TitleComponent';
import PageContainer from '../../Layout/Page/PageContainer';

import CameraPointRow from "../CameraPoints/CameraPointRow";
import CircledIcon from "../../CompositeIcons/CircledIcon";
import PhotoPointRow from "../../Common/PhotoPointRow";
import PhotoPointModal from "./Modals/PhotoPointModal";
import DeactivatePhotoPointModal from "./Modals/DeactivatePhotoPointModal";
import PageHeader from "../../Layout/Page/PageHeader";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useReadOnly} from "../../../Hooks/FormHooks";
import {useFinalized} from "../../../Hooks/DataHooks";

const PhotoPointsPage = (props) => {
    useContextTools([CONTEXT_TOOL_PHOTO, CONTEXT_TOOL_GLOBE, CONTEXT_TOOL_BOOK]);

    const params = useParams();

    const cameraPoints = useSelector(state => selectCameraPointsWithPhotoPointHistory(state));
    const event = useSelector(state => selectSampleEventById(state, params.eventId));

    const cameraPoint = cameraPoints.find(point => String(point.sampleLocationId) === String(params.cameraPointId));
    useBreadcrumbs(`Camera Point ${cameraPoint.name}`, event.projectId, event.sampleEventId);
    useContextToolsBySampleEventId(event.sampleEventId);

    const finalized = useFinalized(event.sampleEventId);
    useReadOnly(finalized);

    const [points, setPoints] = useState([]);
    const [showPhotoPoint, setShowPhotoPoint] = useState(false);
    const [showDeactivation, setShowDeactivation] = useState(false);
    const [currentPoint, setCurrentPoint] = useState(null); // For modal

    useEffect(() => {
        setPoints(cameraPoint.PhotoPoints ?? []);
    }, [params.eventId, params.cameraPointId, cameraPoints]);

    useEffect(() => {
        if (!showPhotoPoint && !showDeactivation) {
            // Add delay for modal fade animation
            setTimeout(() => {
                setCurrentPoint(null);
            }, 500);
        }
    }, [showPhotoPoint]);

    const showPoint = (point = null) => {
        const newPhotoPoint = {
            photoDirection: '',
            compassBearing: '',
        }
        setCurrentPoint(point ?? newPhotoPoint);
        setShowPhotoPoint(true);
    };

    const showDeactivationModal = () => {
        setShowPhotoPoint(false);
        setShowDeactivation(true);
    };

    return (
        <Fragment>
            <PhotoPointModal
                sampleEventStartDate={event.startDate}
                sampleLocationId={params.cameraPointId}
                allPoints={points}
                point={currentPoint}
                show={showPhotoPoint}
                deactivate={showDeactivationModal}
                onHide={() => setShowPhotoPoint(false)}
                readOnly={finalized}
            />
            <DeactivatePhotoPointModal
                suggestedDate={event.startDate}
                point={currentPoint}
                show={showDeactivation}
                onHide={() => setShowDeactivation(false)}
            />
            <PageContainer className="photo-points-page">
                <PageHeader>
                    <Title
                        title={`Camera Point ${cameraPoint?.name}`}
                        subtitle={`Photo Points`}
                        action={
                            <Link to={`/event/${event?.sampleEventId}/photo/${cameraPoint?.sampleLocationId}`}>
                                <CircledIcon
                                    icon={['fal', 'pen']}
                                    circleSize="12"
                                    iconTransform="shrink-2"
                                />
                            </Link>
                        }
                        readOnly={finalized}
                    />
                </PageHeader>
                <PageContent>
                    <Row xs={12}>
                        <Col>
                            <div className="d-flex detail-box">
                                <Col xs={9}>
                                    <CameraPointRow item={cameraPoint} thin />
                                </Col>
                            </div>
                            {
                                points.map(point => (
                                    <PhotoPointRow
                                        key={point.photoPointId}
                                        photoPoint={point}
                                        cameraPoint={cameraPoint}
                                        eventId={params.eventId}
                                        showPoint={showPoint}
                                        readOnly={finalized}
                                    />
                                ))
                            }
                            <Button className="w-100 mt-4" variant="secondary" onClick={() => showPoint()}>
                                <FontAwesomeIcon icon={["fal", "plus"]} />
                                Add Photo Point
                            </Button>
                        </Col>
                    </Row>
                </PageContent>
            </PageContainer>
        </Fragment>
    );
};

export default PhotoPointsPage;
