import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom'

import PageHeader from "../../Layout/Page/PageHeader";
import Title from "../../Layout/TitleComponent";
import PageContent from "../../Layout/Page/PageContent";
import {Col, Row} from "react-bootstrap";
import PageFooter from "../../Layout/Page/PageFooter";
import PageContainer from "../../Layout/Page/PageContainer";
import FooterNav from "../../Layout/Page/FooterNav";
import {useTransectReviewNav} from "../../../Hooks/QuickNavHooks";
import {selectTransect} from "../../../Redux/Selectors/transectSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    PHOTO_POINT_DOWNSTREAM,
    PHOTO_POINT_INSTREAM_SHADE,
    PHOTO_POINT_TYPE_END,
    PHOTO_POINT_TYPE_ORIGIN,
    PHOTO_POINT_UPSTREAM
} from "../../../Constants/photos";
import {selectVisiblePhotos} from "../../../Redux/Selectors/photoSelectors";
import {useBreadcrumbs} from "../../../Hooks/BreadcrumbHooks";
import {useContextToolsBySampleEventId} from "../../../Hooks/ToolHooks";
import {useFinalized} from "../../../Hooks/DataHooks";
import ReturnButton from "../../Common/ReturnButton";
import {TRANSECT_REVIEW_CODE_INSTREAM, TRANSECT_REVIEW_CODE_PHOTOS} from "../../../Constants/transectReview";
import {TransectReviewConfirmButton} from "./TransectReviewConfirmButton";


const TransectPhotoReviewPage = props => {
    const params = useParams();

    useTransectReviewNav(params.transectId);

    const transect = useSelector(state => selectTransect(state, params.transectId));
    const photos = useSelector(state => selectVisiblePhotos(state));
    
    
    useBreadcrumbs('Transect Review', transect.projectId, transect.sampleEventId, transect.transectId);
    useContextToolsBySampleEventId(transect.sampleEventId);
    const finalized = useFinalized(transect.sampleEventId);
    const toolCode = location.pathname.split('/').pop(); // last segment of url
    const isInstreamShade = toolCode === 'instream-shade';
    let photoPointTypes, subtitle, reviewCode;

    if (isInstreamShade) {
        subtitle = 'Instream Photos and Shade';
        photoPointTypes = [PHOTO_POINT_INSTREAM_SHADE, PHOTO_POINT_UPSTREAM, PHOTO_POINT_DOWNSTREAM];
        reviewCode = TRANSECT_REVIEW_CODE_INSTREAM;
    } else {
        subtitle = 'Origin & End Photos';
        photoPointTypes = [PHOTO_POINT_TYPE_ORIGIN, PHOTO_POINT_TYPE_END];
        reviewCode = TRANSECT_REVIEW_CODE_PHOTOS;
    }
    
    const photoPoints = transect.PhotoPoints.filter(point => photoPointTypes.includes(point.name));

    return (
        <PageContainer className="transect-photo-review-page">
            <PageHeader>
                <Title
                    title="Transect Review"
                    subtitle={subtitle}
                    action={<ReturnButton path={`/transect/${transect.transectId}/${toolCode}`} isTransectReview
                                          readOnly={finalized}/>}
                />
            </PageHeader>
            <PageContent>
                {
                    (isInstreamShade && transect.hasNoInstreamPhotos) ? <div className="text-center font-weight-bold">No instream photos collected at this transect.</div> : null
                }
                <div className="photo-point-row ">
                    <Row xs={12} className="photo-point-content">
                        {
                            photoPoints?.map(photoPoint => {
                                const photo = photos.find(photo => photo.photoPointId === photoPoint.photoPointId && photo.sampleEventId === transect.sampleEventId);
                                
                                return <Col key={photoPoint.photoPointId} xs={4}>
                                        <div className="photo">
                                            {
                                                photo ?
                                                    <Link to={`/photo/${photo.photoId}`}>
                                                        <img src={photo?.thumbnailSrc ?? photo?.src}/>
                                                        <div className="caption">
                                                            <div className="caption-group">
                                                                <span>{photoPoint.photoDirection}</span>
                                                            </div>
                                                        </div>
                                                    </Link> :
                                                    <div className="no-image">
                                                        <FontAwesomeIcon icon={['fal', 'file-image']}/>
                                                        No Photo
                                                    </div>
                                            }
                                        </div>
                                    </Col>;
                            })
                        }
                    </Row>
                </div>
            </PageContent>
            <PageFooter>
                <FooterNav>
                    <TransectReviewConfirmButton
                        transect={transect}
                        reviewCode={reviewCode}
                        disabled={finalized}
                    />
                </FooterNav>
            </PageFooter>
        </PageContainer>
    )
};

export default TransectPhotoReviewPage;
