/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {useDispatch, useSelector} from "react-redux";
import {updateTransectReview} from "../../../Redux/Actions/transectActions";
import {FormCheck} from "react-bootstrap";
import React from "react";

export function TransectReviewConfirmButton(props) {
	const { transect, reviewCode, disabled } = props;
	const dispatch = useDispatch();
	const { userId = null } = useSelector(state => state.userState.user);
	
	const isConfirmed = transect.TransectReviews.find(review => review.code === reviewCode)?.isConfirmed ?? false;
	
	const onChange = () => {
		void dispatch(updateTransectReview(transect, reviewCode, !isConfirmed, userId));
	}
	
	return <div className="complete-checkbox">
		<FormCheck
			id="complete-checkbox"
			label="Confirm"
			type="checkbox"
			value={isConfirmed}
			checked={isConfirmed}
			onChange={onChange}
			disabled={disabled}
		/>
	</div>;
}
