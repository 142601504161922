import {LIST_NATIVE_WOODY, LIST_PROBLEMATIC} from "./species";

export const competitionLifeStageOptions = [
    {id: 'S', name: 'Seedling'},
    {id: 'FL', name: 'Flowering'},
    {id: 'FR', name: 'Fruiting'},
    {id: 'V', name: 'Vegetative'},
];

export const insectsImpactTypeOptions = [
    {id: 'I', name: 'Insect'},
    {id: 'P', name: 'Pest'},
    {id: 'D', name: 'Disease'},
    {id: 'Unk', name: 'Unknown'}
];

export const insectsImpactCausingOptions = [
    {label: 'Yes', value: 1},
    {label: 'No', value: 0},
]

export const browseFields = [
    {
        label: 'Photograph the Species:',
        code: 'photoId',
        type: 'photo',
        colSize: 12,
    },
    {
        label: 'What is the species?',
        code: 'browseSpeciesId',
        type: 'species',
        listType: LIST_NATIVE_WOODY,
        required: true,
    },
    {
        label: `% of plants impacted`,
        code: 'browseImpactedSpecies',
        type: 'select',
        options: [
            {id: '<1%', name: '<1%'},
            {id: '1-<10%', name: '1-<10%'},
            {id: '10-<30%', name: '10-<30%'},
            {id: '30+%', name: '30+%'},
        ],
        required: true,
    },
    {
        label: 'Note browsing animals',
        code: 'browseAnimalNote',
        type: 'text',
    },
];

export const competitionFields = [
    {
        label: 'Species Name',
        code: 'competitionSpeciesId',
        type: 'species',
        listType: LIST_PROBLEMATIC,
        required: true,
    },
    {
        label: 'Estimated % Cover',
        code: 'competitionEstimatedCover',
        type: 'select',
        options: [
            {id: '<1%', name: '<1%'},
            {id: '1-<10%', name: '1-<10%'},
            {id: '10-<30%', name: '10-<30%'},
            {id: '30+%', name: '30+%'},
        ],
        required: true,
    },
    {
        label: 'Life Stage',
        code: 'competitionLifeStage',
        type: 'select',
        options: competitionLifeStageOptions,
        required: true,
    },
];
