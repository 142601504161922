/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import React, {Fragment} from "react";
import Modal from "../../Common/Modal";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ConflictResolutionDialog = props => {
    const {
        transect = null,
        type = transect ? 'Transect' : null,
        title = `Resolve ${type} Conflict`,
        children,
        footerFromProps = null,
        onDiscard = (() => {
        }),
        onKeep = (() => {
        }),
        onHide = (() => {
        }),
        show
    } = props;
    
    const footer = footerFromProps ??
        <Fragment>
            <Button variant="primary" className="w-33" onClick={() => onHide()}>
                {/*<FontAwesomeIcon icon={['fal', 'times']}/>*/}
                Review Data
            </Button>
            <Button variant="danger" className="w-33" onClick={() => onDiscard()}>
                {
                    <FontAwesomeIcon icon={['fal', 'trash-alt']}/>
                }
                Discard Local Data
            </Button>
            <Button variant="danger" className="w-33" onClick={() => onKeep()}>
                <FontAwesomeIcon icon={['fal', 'times']}/>
                Overwrite Cloud Data
            </Button>
        </Fragment>;
    
    const body = children ?? 
        ( type === 'Transect' ?
                <Fragment>
                    <p>Data for <i>Transect {transect.name}</i> has been changed by another device since you downloaded it.</p>
                    
                    <p>Using another device, compare the data on this device to what has been synced to the cloud. When
                    you have finished reviewing the data, return to this dialog and choose to discard local data or
                    keep the data on this device and overwrite cloud data.</p>
                    
                    <p><b>If you choose to keep the data on this device then all data in the cloud for this transect 
                        will be overwritten by the data on this device during the next sync.</b></p>
                </Fragment> : null
    );
    
    return <Modal
        title={title}
        size="md"
        show={show}
        onHide={onHide}
        body={body}
        footer={footer}
    />;
}
