import React from "react";

import {
    fieldIsDisabled,
    hasInvalidField,
    validateLatitude,
    validateLongitude
} from "./FormHooks";

import {LIST_NATIVE_OR_UNKNOWN_WOODY, LIST_NATIVE_WOODY} from "../Constants/species";
import {
    browseFields, competitionFields,
    insectsImpactCausingOptions,
    insectsImpactTypeOptions
} from "../Constants/survey";


export const repeaterParameters = (code) => {
    
    switch (code) {
        case 'flood':
        case 'fire':
        case 'drift':
            return {
                name: 'Area',
                fields: conditionFields(code),
            };
        case 'insects':
        case 'insectsii':
            return {
                name: 'Species',
                fields: insectFields(code),
                colSize: 6,
            };
        case 'browse':
            return {
                name: 'Species',
                fields: browseFields,
            };
        case 'soils':
            return {
                name: 'Observation',
                fields: soilFields,
            };
        case 'health':
            return {
                name: 'Species',
                fields: healthFields,
                colSize: 6,
            };
        case 'performance':
            return {
                name: 'Species',
                fields: performanceFields,
                colSize: 12,
            };
        case 'competition':
            return {
                name: 'Species',
                fields: competitionFields,
            };
        default:
            return null;
    }
}

export const answersAreValidForFields = (answers, fields) => {
    if (!answers.length) {
        return false;
    }

    for (const answer of answers) {
        for (const field of fields) {
            // don't validate disabled fields
            if(fieldIsDisabled(field, answer)) {
                continue;
            }
            
            const validationMessage = field.validationMessage ? field.validationMessage(answer[field.code]) : null;
            const requiredFields = field.required ? [answer[field.code]] : [];

            // note hasInvalidField doesn't work for 'required' photo fields and
            // I didn't implement it yet because we don't use it anywhere right now
            if (hasInvalidField(requiredFields, [validationMessage])) {
                return false;
            }
        }
    }

    return true;
}

export const conditionFields = (code) => {
    return [
        {
            label: 'Photograph the area:',
            code: 'photoId',
            type: 'photo',
            colSize: 12,
        },
        {
            label: "Area Identified:",
            type: "text",
            code: `${code}AreaDescription`,
            colSize: 12,
            required: false,
        },
        {
            label: <span>Latitude &#176; N</span>,
            code: 'latitude',
            type: 'text',
            inputmode: 'numeric',
            pattern: "-?\d{0,3}\.\d{5}",
            validationMessage: (value) => validateLatitude(value),
            required: false,
        },
        {
            label: <span>Longitude &#176; E</span>,
            code: 'longitude',
            type: 'text',
            inputmode: 'numeric',
            pattern: "-?\d{0,3}\.\d{5}",
            validationMessage: (value) => validateLongitude(value),
            required: false,
        },
        {
            label: `Estimated area impacted`,
            code: `${code}Impacted`,
            type: 'select',
            options: [
                {id: '<1%', name: '<1%'},
                {id: '1-<10%', name: '1-<10%'},
                {id: '10-<25%', name: '10-<25%'},
                {id: '25-<50%', name: '25-<50%'},
                {id: '50-<75%', name: '50-<75%'},
                {id: '75+%', name: '75+%'},
            ],
            required: true,
        },
    ];
};

export const healthFields = [
    {
        label: 'What is the species?',
        code: 'healthSpeciesId',
        type: 'species',
        listType: LIST_NATIVE_WOODY,
        required: true,
    },
    {
        label: 'Describe the suspected cause',
        code: 'healthSpeciesCause',
        type: 'text',
    },
];

export const insectFields = (code) => {
    const insectsII = (code !== 'insects');

    let fields = [
        {
            label: 'Photograph the insect, disease, or evidence thereof:',
            code: 'photoId',
            type: 'photo',
            colSize: 12,
        },
        {
            label: 'Impact type',
            code: 'insectsImpactType',
            type: 'select',
            options: insectsImpactTypeOptions,
            required: true,
        },
        {
            label: 'Was the ' + (insectsII ? 'affected species' : 'species of insect or disease') + ' identified?',
            code: 'insectsImpactCausing',
            type: 'radio',
            options: insectsImpactCausingOptions,
            required: true,
        },
        // insectsII question gets spliced here (see below)
        {
            label: 'Describe the insect or disease',
            code: 'insectsOrDiseasesDescription',
            enabled: (insectsII ? null : 'insectsImpactCausing'),
            type: 'text',
            required: true,
        },
    ];

    if(insectsII) {
        fields[0].label = 'Photograph the impacted plant species:';
        fields.splice(3, 0, {
            label: 'What is the species?',
            code: 'insectsSpeciesId',
            type: 'species',
            listType: LIST_NATIVE_WOODY,
            enabled: 'insectsImpactCausing',
            required: true,
        });
    }

    return fields;
};

export const performanceFields = [
    {
        label: 'What is the species?',
        code: 'performanceSpeciesId',
        type: 'species',
        listType: LIST_NATIVE_OR_UNKNOWN_WOODY,
    },
];

export const soilFields = [
    {
        label: "Area Identified:",
        type: "text",
        code: "soilsAreaDescription",
        colSize: 12,
        required: false,
    },
    {
        label: <span>Latitude &#176; N</span>,
        code: 'latitude',
        type: 'text',
        inputmode: 'numeric',
        pattern: "-?\d{0,3}\.\d{5}",
        validationMessage: (value) => validateLatitude(value),
        required: false,
    },
    {
        label: <span>Longitude &#176; E</span>,
        code: 'longitude',
        type: 'text',
        inputmode: 'numeric',
        pattern: "-?\d{0,3}\.\d{5}",
        validationMessage: (value) => validateLongitude(value),
        required: false,
    },
    {
        label: 'Possible Reasons',
        code: 'soilsReasonsNote',
        type: 'text',
        required: true,
    },
    
];
