/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {UPSERT_TRANSECT} from "./Types/offlineDataActionTypes";
import {selectTransectByTransectId} from "../Selectors/transectSelectors";

export const resolveConflict = (existingSyncConflict, resolution) => (dispatch, getState) => {
	
	const syncConflict = {
		...existingSyncConflict,
		resolution
	}
	
	if (syncConflict.type === 'Transect') {
		
		const transect = selectTransectByTransectId(getState(), syncConflict.id);
		
		if(resolution === 'Keep') {
			dispatch({
				type: UPSERT_TRANSECT,
				transect: {
					...transect,
					modified: syncConflict.modified,
					syncConflict: null,
				},
				download: true, // prevents offlineModifiedDates from changing
			});
		} else {
			dispatch({
				type: UPSERT_TRANSECT,
				transect: {
					...transect,
					offlineModifiedDate: null, 
					syncConflict: null,
				},
				download: true, // prevents offlineModifiedDates from changing
			});
		}
	}
}
